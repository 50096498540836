//import { useContext } from 'react';
import { useState, useEffect } from 'react';
import useDrivePicker from 'react-google-drive-picker';
import { useGoogleLogin } from '@react-oauth/google';
import { Button, TabPane } from 'reactstrap';
import PropTypes from 'prop-types';
//import { AuthContext } from '../../context/authContext';

const Documentos = ({ datosDocumento }) => {
  // const { userOU } = useContext(AuthContext)
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  useEffect(() => {
    if (sessionStorage.getItem('access') !== null) {
      setIsAuthenticated(true);
    }
  }, []);
  const [openPicker] = useDrivePicker();
  const accessToken = sessionStorage.getItem('access');
  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      sessionStorage.setItem('access', tokenResponse.access_token);
      setIsAuthenticated(true);
    },
    scope: 'https://www.googleapis.com/auth/drive.file',
  });
  const handleOpenPicker = () => {
    if (!isAuthenticated) {
      login();
    } else {
      openPicker({
        clientId: process.env.REACT_APP_CLIENT_ID,
        developerKey: process.env.REACT_APP_API_KEY,
        viewId: 'DOCS',
        supportDrives: true,
        locale: 'es',
        token: accessToken,
        showUploadFolders: true,
        showUploadView: true,
        multiselect: true,
        setParentFolder: datosDocumento,
        customScopes: ['https://www.googleapis.com/auth/drive.file'],
        callbackFunction: (data) => {
          if (data.action === 'picked') {
            data.docs.forEach((d) => {
              window.open(d.url, '_blank');
            });
          }
        },
      });
    }
  };

  return (
    <TabPane tabId="4">
      <Button onClick={() => handleOpenPicker()}>
        {' '}
        {!isAuthenticated ? 'Inicie sesión para mostrar documentos' : 'Mostrar documentos'}
      </Button>
    </TabPane>
  );
};

Documentos.propTypes = {
  datosDocumento: PropTypes.any,
};

export default Documentos;
