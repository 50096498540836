import { React, useState, createContext } from 'react';
import PropTypes from 'prop-types';

export const SearchContext = createContext('');

const initialState = {
  nameSearch: '',
  lastnameSearch: '',
  dniSearch: '',
  emailSearch: '',
  headquarterSearch: '',
  courseSearch: '',
  typeSearch: '',
  cicleSearch: '',
  yearSearch: '',
  idUserSearch: '',
  formationCenterSearch: '',
  newSearch: true,
  deudores: false,
};
export const SearchContextProvider = (props) => {
  const [searchContext, setSearchContext] = useState(initialState);

  // Función para guardar datos search
  const saveSearches = (response) => {
    setSearchContext(response);
  };

  const resetSearchContext = () => {
    setSearchContext(initialState);
  };
  return (
    <>
      <SearchContext.Provider value={{ searchContext, saveSearches, resetSearchContext }}>
        {props.children}
      </SearchContext.Provider>
    </>
  );
};

SearchContextProvider.propTypes = {
  children: PropTypes.node,
};
