const dispatchGeneralErrorEvent = (messageError = '', url = '') => {
  const event = new CustomEvent('generalerror', { detail: { messageError, url } });
  document.dispatchEvent(event);
};

const generalCatchError = (error) => {
  const messageError = error.response.data?.displayMessage; // Mensaje personalizado desde la api si se requiere.
  const url = error.config?.url;
  dispatchGeneralErrorEvent(messageError, url);

  throw error;
};

export default generalCatchError;
