import * as yup from 'yup';

export function validarDNI(dni) {
  const dniRegex = /^[0-9]{8}[A-Za-z]$/;

  if (!dniRegex.test(dni)) {
    return false;
  }

  const letras = 'TRWAGMYFPDXBNJZSQVHLCKE';
  const numero = parseInt(dni.slice(0, 8), 10);
  const letraCalculada = letras[numero % 23];

  return dni.charAt(8).toUpperCase() === letraCalculada;
}

export function validarNIE(nie) {
  const nieRegex = /^[XYZ]\d{7}[TRWAGMYFPDXBNJZSQVHLCKE]$/;
  const letras = 'TRWAGMYFPDXBNJZSQVHLCKE';

  // Verificar longitud del NIE
  if (nie.length !== 9) {
    return false;
  }

  // Verificar formato del NIE
  if (!nieRegex.test(nie)) {
    return false;
  }

  // Reemplazar primera letra por número
  let numero = parseInt(nie.slice(1, -1), 10);
  switch (nie.charAt(0).toUpperCase()) {
    case 'X':
      numero += 0;
      break;
    case 'Y':
      numero += 10000000;
      break;
    case 'Z':
      numero += 20000000;
      break;
    default:
      break;
  }

  // Calcular letra esperada
  const resto = numero % 23;
  const letraEsperada = letras.charAt(resto);

  // Comparar letras
  if (nie.charAt(8).toUpperCase() === letraEsperada) {
    return true;
  }

  return false;
}

export function validarCIF(cif) {
  if (!cif || cif.length !== 9) {
    return false;
  }

  const letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
  const digits = cif.substr(1, cif.length - 2);
  const letter = cif.substr(0, 1);
  const control = cif.substr(cif.length - 1);
  let sum = 0;
  let i;
  let digit;

  if (!letter.match(/[A-Z]/)) {
    return false;
  }

  for (i = 0; i < digits.length; ++i) {
    digit = parseInt(digits[i], 10);

    if (Number.isNaN(digit)) {
      return false;
    }

    if (i % 2 === 0) {
      digit *= 2;
      if (digit > 9) {
        digit = parseInt(digit / 10, 10) + (digit % 10);
      }

      sum += digit;
    } else {
      sum += digit;
    }
  }

  sum %= 10;
  if (sum !== 0) {
    digit = 10 - sum;
  } else {
    digit = sum;
  }

  if (letter.match(/[ABEH]/)) {
    return String(digit) === control;
  }
  if (letter.match(/[NPQRSW]/)) {
    return letters[digit] === control;
  }

  return String(digit) === control || letters[digit] === control;
}

export function validarEmail(email) {
  const esquemaEmail = yup
    .string()
    .email('El email no tiene un formato válido')
    .required('El email es obligatorio');

  try {
    esquemaEmail.validateSync(email);
    return true;
  } catch (error) {
    return false;
  }
}
