import { Col } from 'reactstrap';
import ComponentCard from '../../layouts/containers/ComponentCard';
import FormularioMotorCalculo from '../../components/motorcalculo/FormularioMotorCalculo';

const MotorCalculo = () => {
  return (
    <>
      <Col md="12">
        <ComponentCard title="Cálculo de notas" hasButton={false}>
          <FormularioMotorCalculo />
        </ComponentCard>
      </Col>
    </>
  );
};

export default MotorCalculo;
