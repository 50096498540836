import HttpService from './httpService';

class AlumnosApiService extends HttpService {
  constructor(baseUrl, generalCatch) {
    super(baseUrl, generalCatch);
  }

  // Por lo visto, no se usa, pero lo dejamos por si acaso (faltaría configurar bien el input de esta función).
  async buscarAlumno() {
    return this.get('buscarAlumno');
  }

  async buscarAlumnos({
    empresaId,
    nombre,
    apellido,
    email,
    dni,
    curs,
    grau,
    cicle,
    modalitat,
    idccod,
    nombreDelegacion,
    deudor,
    idDelegacio,
    phone,
  }) {
    return this.get('buscarAlumnos', {
      params: {
        empresaId,
        nombre,
        apellido,
        email,
        dni,
        curs,
        grau,
        cicle,
        modalitat,
        idccod,
        nombreDelegacion,
        deudor,
        idDelegacio,
        phone,
      },
    });
  }

  async validarSepa({ iban, headquarter }) {
    return this.get('validarSepa', { params: { iban, headquarter } });
  }

  async verSepa({ id, headquarter }) {
    return this.get('verSepa', { params: { id, headquarter } });
  }

  async actualizarSepa({ headquarter, data }) {
    return this.post('actualizarSepa', data, { params: { headquarter } });
  }

  async obtenerOpcionesNese({ empresaId }) {
    return this.get('obtenerOpcionesNese', { params: { empresaId } });
  }

  async mostrarSeguimiento({ id, empresaId }) {
    return this.get('mostrarSeguimiento', { params: { id, empresaId } });
  }

  async agregarSeguimiento(data) {
    return this.post('agregarSeguimiento', data);
  }

  async borrarSeguimiento(data) {
    return this.post('borrarSeguimiento', data);
  }

  async editarSeguimiento(data) {
    return this.post('editarSeguimiento', data);
  }

  async getAlumnoById(id, params = {}) {
    return this.get(`alumnos/${id}`, { params });
  }

  async buscarUsuarios({ headquarter, search }) {
    return this.get('buscarUsuarios', { params: { headquarter, search } });
  }

  async cargarAlumno({ id, headquarter, idCedesca, idCntec }) {
    return this.get('cargarAlumno', { params: { id, headquarter, idCedesca, idCntec } });
  }

  async comprobarActualizacion({ empresaId, alumno, date }) {
    return this.get('comprobarActualizacion', { params: { empresaId, alumno, date } });
  }

  async cargarPaises({ empresaId }) {
    return this.get('cargarPaises', { params: { empresaId } });
  }

  async cargarProvincia({ empresaId }) {
    return this.get('cargarProvincia', { params: { empresaId } });
  }

  async cargarPoblaciones({ empresaId, municipio }) {
    return this.get('cargarPoblaciones', { params: { empresaId, municipio } });
  }

  async cargarCp({ empresaId, municipio }) {
    return this.get('cargarCp', { params: { empresaId, municipio } });
  }

  async cargarCentrosFormacion({ empresaId }) {
    return this.get('cargarCentrosFormacion', { params: { empresaId } });
  }

  async cargarDocumentos({ empresaId }) {
    return this.get('cargarDocumentos', { params: { empresaId } });
  }

  async cargarAEscolares({ empresaId, headquarter }) {
    return this.get('cargarAEscolares', { params: { empresaId, headquarter } });
  }

  async cargarEstudios({ empresaId, procedence }) {
    return this.get('cargarEstudios', { params: { empresaId, procedence } });
  }

  async cargarUltimosEstudios({ empresaId }) {
    return this.get('cargarUltimosEstudios', { params: { empresaId } });
  }

  async cargarFormacionesAcademicas({ empresaId }) {
    return this.get('cargarFormacionesAcademicas', { params: { empresaId } });
  }

  async cargarSituacionLaboral({ empresaId }) {
    return this.get('cargarSituacionLaboral', { params: { empresaId } });
  }

  async cargarParentescos({ headquarter }) {
    return this.get('cargarParentescos', { params: { headquarter } });
  }

  async crearUsuario(data, { empresaId, headquarter }) {
    return this.post('crearUsuario', data, { params: { empresaId, headquarter } });
  }

  async cargarOpcionesBuscarAlumno({ headquarter }) {
    return this.get('cargarOpcionesBuscarAlumno', { params: { headquarter } });
  }
}

const alumnosApiService = new AlumnosApiService(process.env.REACT_APP_NODE_API);
export default alumnosApiService;
