import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import FormularioGrupos from '../../components/grupos/FormularioGrupos';
import FormularioActividades from '../../components/grupos/FormularioActividades';
import ComponentCard from '../../layouts/containers/ComponentCard';

function DetallesGrupoActividades() {
  const [activeTab, setActiveTab] = useState('1'); // Estado para la pestaña activa

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <ComponentCard title="Detalles de grupos" className="clearfix">
      {/* Navegación de pestañas */}
      <Nav tabs>
        <NavItem>
          <NavLink
            className={`nav-link ${activeTab === '1' ? 'active' : ''}`}
            onClick={() => toggleTab('1')}
          >
            Grupo
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`nav-link ${activeTab === '2' ? 'active' : ''}`}
            onClick={() => toggleTab('2')}
          >
            Actividades
          </NavLink>
        </NavItem>
      </Nav>

      {/* Contenido de las pestañas */}
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <FormularioGrupos />
        </TabPane>
        <TabPane tabId="2">
          <FormularioActividades />
        </TabPane>
      </TabContent>
    </ComponentCard>
  );
}

export default DetallesGrupoActividades;
