import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Collapse, Col, Button, Row, Tooltip } from 'reactstrap';
import * as Icon from 'react-feather';
import ModalProfesores from './ModalProfesores';

const ActivityCard = ({ actividad, empresaId, sv, svf }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const openModal = (e) => {
    e.stopPropagation(); // Detener la propagación del clic
    setIsModalOpen(!isModalOpen);
  };

  return (
    <Card className="mb-3">
      {isModalOpen && (
        <ModalProfesores
          function={setIsModalOpen}
          tercero={actividad.p3indice}
          primero={actividad.p1indice}
          segundo={actividad.p2indice}
          coord={actividad.pcindice}
          tutor={actividad.ptindice}
          empresaId={empresaId}
          idGrupo={actividad.idGrupo}
          sv={sv}
          svf={svf}
        />
      )}
      <div
        className="border-bottom rounded-top mt-2 px-2 py-2 pe-auto"
        onClick={toggle}
        style={{ backgroundColor: '#eef5f9', cursor: 'pointer' }}
      >
        <h4>
          <Row style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Col md="8" style={{ textAlign: 'left' }}>
              {actividad.Modulo} - {actividad.Descripcion}
            </Col>
            <Col md="4" style={{ textAlign: 'right' }}>
              <Button
                color="warning"
                onClick={(e) => openModal(e)}
                name="editButton"
                id="editButton"
              >
                <Tooltip
                  placement="top"
                  isOpen={tooltipOpen}
                  target="editButton"
                  toggle={toggleTooltip}
                >
                  Editar profesores
                </Tooltip>
                <Icon.Edit />
              </Button>
            </Col>
          </Row>
        </h4>
      </div>

      <Collapse isOpen={isOpen}>
        <CardBody>
          <Row>
            {/* Primera columna */}
            <Col md="4">
              <p>
                <strong>Ciclo:</strong> {actividad.Ciclo}
              </p>
              <p>
                <strong>Actividad:</strong> {actividad.Actividad}
              </p>
              <p>
                <strong>Horas:</strong> {actividad.Horas}
              </p>
              <p>
                <strong>Tutor:</strong> {actividad.Tutor === null ? '' : actividad.Tutor}
              </p>
            </Col>

            {/* Segunda columna */}
            <Col md="4">
              <p>
                <strong>Profesor coordinador:</strong> {actividad.Pcordinador}
              </p>
              <p>
                <strong>Profesor adjunto 1:</strong> {actividad.Padjunto1}
              </p>
              <p>
                <strong>Profesor adjunto 2:</strong> {actividad.Padjunto2}
              </p>
              <p>
                <strong>Profesor adjunto 3:</strong> {actividad.Padjunto3}
              </p>
            </Col>

            {/* Tercera columna */}
            <Col md="2">
              <p>
                <strong>Activos:</strong> {actividad.Activos}
              </p>
              <p>
                <strong>Convalidada:</strong> {actividad.Convalidada}
              </p>
              <p>
                <strong>Importada:</strong> {actividad.Importada}
              </p>
              <p>
                <strong>Baja:</strong> {actividad.Baja}
              </p>
            </Col>
            {/* Tercera columna */}
            <Col md="2">
              <p>
                <strong>Total:</strong> {actividad.Total}
              </p>
            </Col>
          </Row>
        </CardBody>
      </Collapse>
    </Card>
  );
};

ActivityCard.propTypes = {
  actividad: PropTypes.any,
  empresaId: PropTypes.any,
  sv: PropTypes.any,
  svf: PropTypes.any,
};

export default ActivityCard;
