import otrosApiService from '../services/http/otrosApiService';

const filtroSolicitudesMatricula = async (userOU) => {
  const params = {
    headquarter: 'CEDETECGROUP',
    idCedesca: userOU.idCedesca,
    idCntec: userOU.idCntec,
  };

  // Verificamos que rol estamos
  if (
    userOU.idCedesca !== 0 &&
    userOU.idCntec !== 0 &&
    userOU.idCedesca !== -1 &&
    userOU.idCntec !== -1 &&
    userOU.idCedesca !== -2 &&
    userOU.idCntec !== -2 &&
    userOU.idCedesca !== -3 &&
    userOU.idCntec !== -3
  ) {
    params.estado = 5;
  } else {
    params.estado = 6;
  }

  // Hacemos la petición a CEDESCA
  try {
    const dataPeticionesPendientes = await otrosApiService.getPeticionesPendientes(params);
    return dataPeticionesPendientes;
  } catch (error) {
    return { badge: 0 };
  }
};

export default filtroSolicitudesMatricula;
