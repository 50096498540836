import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Col, Row, FormGroup, Label, Input, Button } from 'reactstrap';
import PropTypes from 'prop-types'; // Importa PropTypes
import alumnosApiService from '../../services/http/alumnosApiService';
import envioEmailService from '../../services/http/envioEmailService';
import { EMAIL_GROUPS } from '../../helpers/constants';

const FiltrarAlumnos = ({
  setAlumnos,
  setIsLoading,
  emailGroup,
  setEmailGroup,
  isLoading,
  setSelectedEmails,
  filters,
  setFilters,
}) => {
  const { empresaId, idFormationCenter, group } = filters;

  const [formationCenter, setFormationCenter] = useState([]);
  const [groupList, setGroupList] = useState([]);

  useEffect(() => {
    const cargarOpciones = async () => {
      try {
        const dataCentros = await alumnosApiService.cargarCentrosFormacion({
          empresaId,
        });

        // Mapea dataCentros al formato que react-select requiere
        const arrayFiltrado = dataCentros.map((centro) => ({
          value: centro.Id, // o la propiedad que corresponda
          label: centro.Nombre, // o la propiedad que corresponda
        }));

        setFormationCenter(arrayFiltrado);
      } catch (error) {
        setFormationCenter([]);
      }

      try {
        const dataCentros = await envioEmailService.consultaGrupos({
          headquarter: empresaId, // Ensure you're passing the right key here
          // Convert the array to a comma-separated string
        });

        const arrayFiltrado = dataCentros.map((centro) => ({
          value: centro.codigogrupo, // Adjust this based on the actual response structure
          label: centro.descripcion2, // Adjust this based on the actual response structure
        }));

        setGroupList(arrayFiltrado);
      } catch (error) {
        console.error('Error fetching grupos:', error);
        setGroupList([]);
      }
    };

    cargarOpciones();
  }, [empresaId]); // Agregar empresaId como dependencia para actualizar cuando cambie

  const handleEmpresaChange = (e) => {
    setFilters((prev) => ({
      ...prev,
      empresaId: e.target.value,
      idFormationCenter: [],
      group: [],
    }));
  };

  const CustomClearText = () => 'borrar todo';

  const ClearIndicator = (props) => {
    const {
      children = <CustomClearText />,
      getStyles,
      innerProps: { ref, ...restInnerProps },
    } = props;

    return (
      <div {...restInnerProps} ref={ref} style={getStyles('clearIndicator', props)}>
        <div style={{ padding: '0px 5px' }}>{children}</div>
      </div>
    );
  };

  const handleOnClick = async () => {
    setIsLoading(true);
    setSelectedEmails({});

    try {
      const dataAlumnos = await envioEmailService.consultaAlumnosFiltrados({
        headquarter: empresaId,
        listadoCentros: idFormationCenter.map((e) => e.value),
        listadoGrupos: group.map((e) => e.value),
      });

      setAlumnos(dataAlumnos);
    } catch (error) {
      console.error('Error fetching grupos:', error);
      setAlumnos([]);
    } finally {
      setIsLoading(false);
    }
  };

  // Agregar prop-types para ClearIndicator
  ClearIndicator.propTypes = {
    children: PropTypes.node, // children puede ser cualquier tipo de nodo
    getStyles: PropTypes.func.isRequired, // getStyles es una función requerida
    innerProps: PropTypes.shape({
      ref: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    }).isRequired, // innerProps es un objeto que contiene ref
  };

  const handleEmailGroupChange = (selection) => {
    setEmailGroup(selection);
  };

  return (
    <>
      <Row>
        {/* Centro Select */}
        <Col md="4">
          <FormGroup>
            <Label for="centroSelect">Centro</Label>
            <Input type="select" name="centro" id="centroSelect" onChange={handleEmpresaChange}>
              <option value="CEDESCA">Cedesca</option>
              <option value="CNTEC">Cntec</option>
            </Input>
          </FormGroup>
        </Col>

        {/* Delegación Select */}
        <Col md="4">
          <FormGroup>
            <Label for="delegacionSelect">Delegación</Label>
            <Select
              value={idFormationCenter}
              closeMenuOnSelect={false}
              options={formationCenter}
              isMulti
              components={{ ClearIndicator }}
              onChange={(selection) =>
                setFilters((prev) => ({ ...prev, idFormationCenter: selection }))
              }
              placeholder="Seleccionar..."
            />
          </FormGroup>
        </Col>

        {/* Grupo Select */}
        <Col md="4">
          <FormGroup>
            <Label for="delegacionSelect">Grupo</Label>
            <Select
              value={group}
              closeMenuOnSelect={false}
              options={groupList}
              isMulti
              onChange={(selection) => setFilters((prev) => ({ ...prev, group: selection }))}
              components={{ ClearIndicator }}
              placeholder="Seleccionar..."
            />
          </FormGroup>
        </Col>
      </Row>

      <Row className="align-items-center">
        {/* Centro Select */}
        <Col md="4">
          <FormGroup>
            <Label for="emailGroup">Grupo email</Label>
            <Select
              value={emailGroup}
              closeMenuOnSelect={false}
              options={[
                { value: EMAIL_GROUPS.PERSONAL, label: 'Email personal' },
                { value: EMAIL_GROUPS.CENTRO, label: 'Email centro' },
                { value: EMAIL_GROUPS.TUTOR1, label: 'Email tutor 1' },
                { value: EMAIL_GROUPS.TUTOR2, label: 'Email tutor 2' },
              ]}
              isMulti
              onChange={handleEmailGroupChange}
              components={{ ClearIndicator }}
              placeholder="Seleccionar..."
            />
          </FormGroup>
        </Col>
        <Col className="mt-3" md="2">
          <Button onClick={handleOnClick} disabled={isLoading}>
            {!isLoading ? 'Filtrar' : 'Buscando'}
          </Button>
        </Col>
      </Row>
    </>
  );
};

FiltrarAlumnos.propTypes = {
  filters: PropTypes.any,
  setFilters: PropTypes.any,
  setAlumnos: PropTypes.any,
  setIsLoading: PropTypes.any,
  emailGroup: PropTypes.any,
  setEmailGroup: PropTypes.any,
  isLoading: PropTypes.any,
  setSelectedEmails: PropTypes.any,
};
export default FiltrarAlumnos;
