/* eslint-disable import/prefer-default-export */

export const USER_ROLES = {
  SUPERADMIN: 'rol_super_administrador',
  SEDE: 'rol_sede',
  ORIENTACION: 'rol_orientacion',
  SECRETARIA: 'rol_secretaria',
  DELEGACION: 'rol_delegacion',
};

export const EMAIL_GROUPS = {
  PERSONAL: 'EMAIL_GROUP_PERSONAL',
  CENTRO: 'EMAIL_GROUP_CENTRO',
  TUTOR1: 'EMAIL_GROUP_TUTOR1',
  TUTOR2: 'EMAIL_GROUP_TUTOR2',
};
