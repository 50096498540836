import { Col } from 'reactstrap';
import ComponentCard from '../../layouts/containers/ComponentCard';
import FormularioSuplantacionIdentidad from '../../components/suplantacionidentidad/FormularioSuplantacionIdentidad';

const SuplantacionIdentidad = () => {
  return (
    <>
      <Col md="12">
        <ComponentCard
          title="Suplantación de identidad"
          subtitle="Puedes buscar por nombre o email y recorrer la aplicación como si fueras el usuario que se seleccion en el listado."
        >
          <FormularioSuplantacionIdentidad />
        </ComponentCard>
      </Col>
    </>
  );
};

export default SuplantacionIdentidad;
