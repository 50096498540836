import { useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../../context/authContext';

// HOC para no permitir el acceso a ramas internas dependiendo el rol del usuario.
const PrivateRoute = ({ roles, children }) => {
  const navigate = useNavigate();

  const { getUserRol } = useContext(AuthContext);
  const userRol = getUserRol();

  // TODO: Pendiente de incluir una página de acceso denegado (access-denied).
  if (roles && !roles.includes(userRol)) navigate('/');

  return children;
};

PrivateRoute.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node.isRequired,
};

export default PrivateRoute;
