/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import reportWebVitals from './reportWebVitals';
import { store } from './store/Store';
import { ContextProvider } from './context/authContext';
import { SearchContextProvider } from './context/searchContext';
import { SearchMatContextProvider } from './context/searchMatContext';
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <ContextProvider>
    <SearchContextProvider>
      <SearchMatContextProvider>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
          <Provider store={store}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </Provider>
        </GoogleOAuthProvider>
      </SearchMatContextProvider>
    </SearchContextProvider>
  </ContextProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
