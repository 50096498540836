import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ComponentCard from '../../layouts/containers/ComponentCard';
//import Loader from '../../layouts/loader/Loader'
import Ciclo from './Ciclo';
import { AuthContext } from '../../context/authContext';

const Ciclos = (props) => {
  // Declaramos los estados
  const [arrCiclos, setArrCiclos] = useState([]);

  const { userOU } = useContext(AuthContext);

  // Función para filtrar los ciclos por la propiedad nombre
  function filtrarPorPropiedadUnica(arr, propiedad) {
    // Objeto para hacer un seguimiento de las propiedades únicas
    const unique = {};

    // Array resultante después de filtrar los objetos repetidos
    const result = arr.filter((obj) => {
      // Si la propiedad actual no está en el objeto unique, añádela y devuelve true
      if (!unique.hasOwnProperty(obj[propiedad])) {
        unique[obj[propiedad]] = true;
        return true;
      }

      // Si la propiedad ya está en el objeto unique, devuelve false para filtrar el objeto
      return false;
    });

    // Seteamos el arreglo filtrado en el estado
    setArrCiclos(result);

    return result;
  }

  useEffect(() => {
    filtrarPorPropiedadUnica(props.ciclos, 'nombre');
  }, []);

  return (
    <ComponentCard title="Ciclos formativos matriculados" hasButton={false}>
      {userOU.centro !== 'SUPERADMIN' &&
      userOU.centro !== 'CEDESCA' &&
      userOU.centro !== 'CNTEC Girona' &&
      userOU.centro !== 'CNTEC Sevilla' &&
      userOU.centro !== 'SECRETARÍA' &&
      userOU.centro !== 'ORIENTACIÓN' ? (
        props.datos.personales.lopdSede === 'Consiento' ? (
          arrCiclos.map((ciclo, index) => (
            <Ciclo
              recibosPendientes={props.recibosPendientes}
              key={`${ciclo.codigo}-${index}`}
              ciclo={ciclo}
              datos={props.datos}
              matriculas={props.matriculas}
              balance={props.balance}
              documento={ciclo.documentos}
            />
          ))
        ) : (
          <font color="#b71c1c">
            El alumno{' '}
            <b>
              {props.datos.personales.nombre} {props.datos.personales.apellidos}
            </b>{' '}
            no ha autorizado el acceso a las notas.
          </font>
        )
      ) : (
        arrCiclos.map((ciclo, index) => (
          <Ciclo
            recibosPendientes={props.recibosPendientes}
            key={`${ciclo.codigo}-${index}`}
            ciclo={ciclo}
            datos={props.datos}
            matriculas={props.matriculas}
            balance={props.balance}
            documento={ciclo.documentos}
          />
        ))
      )}
    </ComponentCard>
  );
};

Ciclos.propTypes = {
  recibosPendientes: PropTypes.any,
  ciclos: PropTypes.any,
  datos: PropTypes.any,
  matriculas: PropTypes.any,
  balance: PropTypes.any,
};
export default Ciclos;
