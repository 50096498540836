import { useEffect, useState } from 'react';
import ReactTable from 'react-table-v6';
import { Button, Col } from 'reactstrap';
import envioMailService from '../../services/http/envioEmailService';
import ModalHeaderBody from '../../layouts/modals/ModalHeaderBody';
import BodyRemesa from './BodyRemesa';

const translations = {
  previousText: '<',
  nextText: '>',
  loadingText: 'Cargando..',
  ofText: 'de',
  rowsText: 'líneas',
  noDataText: 'Datos no encontrados',
  pageText: 'Página',
};

const ListadoEmails = () => {
  const [remesas, setRemesas] = useState([]);
  const [loadingData, setLoadingData] = useState([]);
  const [dataDetail, setDataDetail] = useState(null);
  const [openRemesaDetail, setOpenRemesaDetail] = useState(false);

  const fetchRemesas = async () => {
    setLoadingData(true);

    try {
      const data = await envioMailService.getRemesas({ headquarter: 'CEDESCA' });
      setRemesas(data);
    } catch (error) {
      setRemesas([]);
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchRemesas();
  }, []);

  if (loadingData) {
    return (
      <Col className="mt-3" md="12">
        Cargando remesas
      </Col>
    );
  }

  return (
    <>
      <Col className="mt-3" md="12">
        <ReactTable
          {...translations}
          columns={[
            {
              Header: 'ID',
              accessor: 'IdRemesa',
            },
            {
              Header: 'Asunto',
              accessor: 'Descripcion',
            },
            {
              Header: 'Fecha emisión',
              accessor: 'FechaEmision',
            },
            {
              Header: 'Fecha envio',
              accessor: 'FechaEnvio',
            },
            {
              Header: 'Hora',
              accessor: 'Hora',
            },
            {
              Header: 'Acciones',
              accessor: 'actions',
              sortable: false,
              filterable: false,
            },
          ]}
          data={remesas.map((r) => ({
            ...r,
            actions: (
              <Button
                color="success"
                onClick={() => {
                  setDataDetail(r);
                  setOpenRemesaDetail(true);
                }}
              >
                <a className="text-light text-decoration-none">Ver destinatarios</a>
              </Button>
            ),
          }))}
          defaultPageSize={10}
          showPaginationBottom
          className="-striped -highlight mt-5 text-center"
        />
      </Col>

      {openRemesaDetail && (
        <ModalHeaderBody
          open={openRemesaDetail}
          setOpen={setOpenRemesaDetail}
          title={`Detalle de la remesa ${dataDetail.IdRemesa}`}
        >
          <BodyRemesa remesa={dataDetail} />
        </ModalHeaderBody>
      )}
    </>
  );
};
export default ListadoEmails;
