import HttpService from './httpService';

class PhpApiService extends HttpService {
  constructor(baseUrl, generalCatch) {
    super(baseUrl, generalCatch);
  }

  async generarEnvioBolnot({ centre, curso, grau, modalitat, data }) {
    return this.post('generarEnvioBolnot.php', data, {
      params: { centre, curso, grau, modalitat },
    });
  }

  async createDriveFolder({ db, nombre, apellidos, dni, idCentro }) {
    return this.get('createDriveFolder.php', { params: { db, nombre, apellidos, dni, idCentro } });
  }
}

const phpApiService = new PhpApiService(process.env.REACT_APP_URL_API);
export default phpApiService;
