import moment from 'moment';

const esAlumnoMenor = (fecha) => {
  const fechaActual = new Date();
  let fechaNacimiento;

  if (fecha !== '') {
    if (moment.isMoment(fecha)) {
      fechaNacimiento = fecha.toDate();
    } else {
      // Convertimos la fecha de nacimiento en un objeto Date
      const dateParts = fecha.split('/'); // Divide la cadena en partes: día, mes, año

      if (dateParts.length === 3) {
        const day = parseInt(dateParts[0], 10); // Obtén el día como número entero
        const month = parseInt(dateParts[1], 10) - 1; // Obtén el mes (resta 1 ya que los meses en JavaScript son de 0 a 11)
        const year = parseInt(dateParts[2], 10); // Obtén el año como número entero

        fechaNacimiento = new Date(year, month, day); // Crea el objeto Date con los valores obtenidos
      }
    }

    // Hemos obtenido una fecha
    if (!Number.isNaN(fechaNacimiento.getTime())) {
      // Calcular la diferencia en milisegundos entre la fecha actual y la fecha de nacimiento,
      // luego convertimos la diferencia de milisegundos a años.
      const diferenciaEnMilisegundos = fechaActual.getTime() - fechaNacimiento.getTime();
      const edadEnAnios = diferenciaEnMilisegundos / (1000 * 60 * 60 * 24 * 365.25);

      return edadEnAnios < 18;
    }
  }

  return null;
};

export default esAlumnoMenor;
