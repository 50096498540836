import { useState, useEffect } from 'react';
import { FormGroup, Label, Input, Button } from 'reactstrap';
import Loader from '../../layouts/loader/Loader';
import funcionalidadesApiService from '../../services/http/funcionalidadesApiService';
import ModalClickable from '../../layouts/modals/ModalClickable';

const PanelDeControl = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaved, setIsSaved] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const datos = await funcionalidadesApiService.cargarFuncionalidades({
          headquarter: 'CEDESCA',
        });
        setIsLoading(false);
        setData(datos);
      } catch (error) {
        setIsLoading(false);
        setData([]);
      }
    };

    fetchData();
  }, []);
  const saveData = () => {
    funcionalidadesApiService.guardarFuncionalidades({
      data,
    });
    setIsSaved(true);
  };
  return (
    <div>
      {isSaved && <ModalClickable header="Cambios guardados" />}
      {isLoading && data.length > 0 ? (
        <Loader />
      ) : (
        <>
          <h4>Funcionalidades</h4>
          {data.map((item) => (
            <FormGroup switch className="">
              {/* Ordenamos el Label antes del Input */}
              <Label check className="">
                {item.nombre}
              </Label>
              <Input
                type="switch"
                defaultChecked={item.estado === 1}
                style={{
                  outline: 'none', // Fondo verde o rojo según el estado
                }}
                onClick={() => {
                  item.estado = item.estado === 1 ? 'false' : 'true';
                  setData([...data]);
                }}
              />
            </FormGroup>
          ))}

          {/* Botón alineado a la derecha */}
          <div className="d-flex justify-content-end mt-3">
            <Button onClick={saveData}>Guardar cambios</Button>
          </div>
        </>
      )}
    </div>
  );
};

export default PanelDeControl;
