import { React, useState } from 'react';
import PropTypes from 'prop-types';
import FiltrarAlumnos from './FiltrarAlumnos';
import ListadoAlumnos from './ListadoAlumnos';
import Loader from '../../layouts/loader/Loader';

const BuscarAlumnos = ({
  alumnos,
  setAlumnos,
  setSelectedEmails,
  selectedEmails,
  filters,
  setFilters,
  emailGroup,
  setEmailGroup,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <FiltrarAlumnos
        setAlumnos={setAlumnos}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        emailGroup={emailGroup}
        setEmailGroup={setEmailGroup}
        setSelectedEmails={setSelectedEmails}
        filters={filters}
        setFilters={setFilters}
      />
      {!isLoading ? (
        <ListadoAlumnos
          alumnos={alumnos}
          grupo={emailGroup}
          selectedEmails={selectedEmails}
          setSelectedEmails={setSelectedEmails}
        />
      ) : (
        <Loader />
      )}
    </>
  );
};

BuscarAlumnos.propTypes = {
  alumnos: PropTypes.any,
  setAlumnos: PropTypes.any,
  setSelectedEmails: PropTypes.any,
  selectedEmails: PropTypes.any,
  filters: PropTypes.any,
  setFilters: PropTypes.any,
  emailGroup: PropTypes.any,
  setEmailGroup: PropTypes.any,
};

export default BuscarAlumnos;
