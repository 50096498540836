import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  ModalBody,
  ModalFooter,
  Modal,
  Button,
  Label,
  FormGroup,
  Row,
  Col,
  Spinner,
} from 'reactstrap';
import Select from 'react-select';
import gruposService from '../../services/http/gruposService';

const ModalProfesores = (props) => {
  const [modalState, setModalState] = useState(true);
  const [pData, setPData] = useState([]);
  const [pTutor, setPtutor] = useState();
  const [pCord, setPcord] = useState();
  const [p1, setP1] = useState();
  const [p2, setP2] = useState();
  const [p3, setP3] = useState();

  const [pTutorA, setPtutorA] = useState();
  const [pCordA, setPcordA] = useState();
  const [p1A, setP1A] = useState();
  const [p2A, setP2A] = useState();
  const [p3A, setP3A] = useState();

  // Estados para gestionar el estado de guardado
  const [isSaving, setIsSaving] = useState(false); // Para mostrar el spinner
  const [isSaved, setIsSaved] = useState(false); // Para mostrar el mensaje de éxito
  const [isError, setIsError] = useState(false); // Para mostrar mensaje de error

  useEffect(() => {
    const fetchGrupoData = async () => {
      const data = await gruposService.consultarProfesores({
        headquarter: props.empresaId,
      });
      // Agregar la opción "Sin Asignar" al principio de la lista
      const updatedData = [{ nombre: 'Sin Asignar', indice: 0 }, ...data];
      setPData(updatedData);

      setPtutor(props.tutor === null ? 0 : props.tutor);

      setPcord(props.coord === null ? 0 : props.coord);

      setP1(props.primero === null ? 0 : props.primero);

      setP2(props.segundo === null ? 0 : props.segundo);

      setP3(props.tercero === null ? 0 : props.tercero);

      setPtutorA(props.tutor === null ? 0 : props.tutor);

      setPcordA(props.coord === null ? 0 : props.coord);

      setP1A(props.primero === null ? 0 : props.primero);

      setP2A(props.segundo === null ? 0 : props.segundo);

      setP3A(props.tercero === null ? 0 : props.tercero);
    };
    fetchGrupoData();
  }, [props.empresaId]);

  const handleModalState = () => {
    if (modalState === true) {
      setModalState(false);
      if (props.url) {
        window.location.href = props.url;
      }
    } else {
      setModalState(true);
    }
    if (props.function !== undefined) {
      props.function(false);
    }
  };

  // Función para manejar el guardado de los datos
  const handleSave = async () => {
    setIsSaving(true); // Activar spinner (el proceso de guardado ha comenzado)
    setIsSaved(false); // Reiniciar estado de éxito
    setIsError(false); // Reiniciar estado de error

    try {
      const values = {
        p1,
        pCord,
        p2,
        p3,
        pTutor,
        idActividad: props.idGrupo,
        p1A,
        pCordA,
        p2A,
        p3A,
        pTutorA,
      };

      gruposService.guardarDatosProfesores({
        data: values,
        headquarter: props.empresaId,
      });

      // Simulamos un retraso de 2 segundos para la llamada a la API
      setTimeout(() => {
        setIsSaving(false); // Desactivar el spinner
        setIsSaved(true); // Mostrar el mensaje de éxito
        props.svf(!props.sv);
      }, 2000);
    } catch (error) {
      setIsSaving(false); // Desactivar el spinner
      setIsError(true); // Activar el mensaje de error
    }
  };

  return (
    <Modal isOpen={modalState} centered size="lg">
      <ModalBody>
        <h4 className="mt-3">Editar profesores</h4>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="tutorSelect">Tutor</Label>
              <Select
                id="tutorSelect"
                placeholder="Seleccionar"
                value={
                  pData.find((profesor) => profesor.indice === pTutor)
                    ? {
                        label: pData.find((profesor) => profesor.indice === pTutor).nombre,
                        value: pTutor,
                      }
                    : null
                }
                options={pData.map((profesor) => ({
                  label: profesor.nombre, // Muestra el nombre
                  value: profesor.indice, // Guarda el índice
                }))}
                onChange={(e) => setPtutor(e.value)} // Guarda solo el índice seleccionado
              />
            </FormGroup>
            <FormGroup>
              <Label for="coordinatorSelect">Profesor Coordinador</Label>
              <Select
                id="coordinatorSelect"
                placeholder="Seleccionar"
                value={
                  pData.find((profesor) => profesor.indice === pCord)
                    ? {
                        label: pData.find((profesor) => profesor.indice === pCord).nombre,
                        value: pCord,
                      }
                    : null
                }
                options={pData.map((profesor) => ({
                  label: profesor.nombre, // Lo que se muestra
                  value: profesor.indice, // Lo que se guarda
                }))}
                onChange={(e) => setPcord(e.value)} // Guarda solo el índice
              />
            </FormGroup>
            <FormGroup>
              <Label for="adjunct1Select">Profesor Adjunto 1</Label>
              <Select
                id="adjunct1Select"
                placeholder="Seleccionar"
                value={
                  pData.find((profesor) => profesor.indice === p1)
                    ? {
                        label: pData.find((profesor) => profesor.indice === p1).nombre,
                        value: p1,
                      }
                    : null
                }
                options={pData.map((profesor) => ({
                  label: profesor.nombre,
                  value: profesor.indice,
                }))}
                onChange={(e) => setP1(e.value)}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="adjunct2Select">Profesor Adjunto 2</Label>
              <Select
                id="adjunct2Select"
                placeholder="Seleccionar"
                value={
                  pData.find((profesor) => profesor.indice === p2)
                    ? {
                        label: pData.find((profesor) => profesor.indice === p2).nombre,
                        value: p2,
                      }
                    : null
                }
                options={pData.map((profesor) => ({
                  label: profesor.nombre,
                  value: profesor.indice,
                }))}
                onChange={(e) => setP2(e.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="adjunct3Select">Profesor Adjunto 3</Label>
              <Select
                id="adjunct3Select"
                placeholder="Seleccionar"
                value={
                  pData.find((profesor) => profesor.indice === p3)
                    ? {
                        label: pData.find((profesor) => profesor.indice === p3).nombre,
                        value: p3,
                      }
                    : null
                }
                options={pData.map((profesor) => ({
                  label: profesor.nombre,
                  value: profesor.indice,
                }))}
                onChange={(e) => setP3(e.value)}
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          color={props.color ? props.color : 'success'}
          onClick={handleSave}
          disabled={isSaving} // Deshabilitar el botón mientras se está guardando
        >
          {isSaving ? (
            <Spinner size="sm" /> // Mostrar el spinner durante el guardado
          ) : isSaved ? (
            'Guardado correctamente' // Mensaje de éxito después de guardar
          ) : (
            'Guardar'
          )}
        </Button>
        <Button
          color="danger"
          onClick={handleModalState}
          disabled={isSaving} // Deshabilitar el botón mientras se está guardando
        >
          Cerrar
        </Button>
        {isError && <div className="text-danger mt-2">Hubo un error al guardar los datos.</div>}
      </ModalFooter>
    </Modal>
  );
};

ModalProfesores.propTypes = {
  color: PropTypes.any,
  url: PropTypes.any,
  function: PropTypes.any,
  tutor: PropTypes.any,
  coord: PropTypes.any,
  primero: PropTypes.any,
  segundo: PropTypes.any,
  tercero: PropTypes.any,
  empresaId: PropTypes.any,
  idGrupo: PropTypes.any,
  sv: PropTypes.any,
  svf: PropTypes.any,
};

export default ModalProfesores;
