import { Input, FormGroup, FormFeedback, Row } from 'reactstrap';
import PropTypes from 'prop-types';

const Comentarios = ({ setComentarios, invalidComentarios, setInvalidComentarios }) => {
  const handleComentarios = (e) => {
    if (invalidComentarios) {
      setInvalidComentarios(false);
    }

    setComentarios(e.target.value);
  };

  return (
    <Row className="mt-3">
      <h4>Comentarios</h4>
      <FormGroup>
        <Input
          type="textarea"
          name="text"
          id="exampleText"
          className="mb-3"
          onChange={(e) => handleComentarios(e)}
          invalid={invalidComentarios}
        />
        <FormFeedback>Este campo es obligatorio</FormFeedback>
      </FormGroup>
    </Row>
  );
};
Comentarios.propTypes = {
  setComentarios: PropTypes.any,
  invalidComentarios: PropTypes.any,
  setInvalidComentarios: PropTypes.any,
};
export default Comentarios;
