import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import PropTypes from 'prop-types';

const ModalSeguimiento = ({ isOpen, setIsOpen, toggle, onSave, titulo, comentario }) => {
  const [newEntry, setNewEntry] = useState({ titulo, comentarios: comentario });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewEntry({ ...newEntry, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await onSave(newEntry);
    setNewEntry({ titulo, comentarios: comentario });
    setIsOpen(false);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {' '}
        {titulo === '' && comentario === '' ? 'Añadir' : 'Editar'} Seguimiento
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="titulo">Título</Label>
            <Input
              type="text"
              name="titulo"
              id="titulo"
              value={newEntry.titulo}
              onChange={handleInputChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="comentarios">Comentarios</Label>
            <Input
              type="textarea"
              name="comentarios"
              id="comentarios"
              value={newEntry.comentarios}
              onChange={handleInputChange}
              required
              style={{ height: '100px' }}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button type="submit" color="primary" onClick={handleSubmit}>
          Guardar
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ModalSeguimiento.propTypes = {
  isOpen: PropTypes.any,
  setIsOpen: PropTypes.any,
  toggle: PropTypes.string,
  onSave: PropTypes.any,
  titulo: PropTypes.any,
  comentario: PropTypes.any,
};

export default ModalSeguimiento;
