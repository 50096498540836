import React, { useEffect, useState } from 'react';
import { Button, Input } from 'reactstrap';
import ReactTable from 'react-table-v6';
import PropTypes from 'prop-types';
import { EMAIL_GROUPS } from '../../helpers/constants';

const selectEmailsByGroups = (grupo, alumno) => {
  const emailsSelectedes = grupo.map((g) => {
    let emailToSelect = '';

    switch (g.value) {
      case EMAIL_GROUPS.PERSONAL:
        emailToSelect = alumno.Email_personal;
        break;
      case EMAIL_GROUPS.CENTRO:
        emailToSelect = alumno.Email_Cedetec;
        break;
      case EMAIL_GROUPS.TUTOR1:
        emailToSelect = alumno.Tutor1_Mail;
        break;
      case EMAIL_GROUPS.TUTOR2:
        emailToSelect = alumno.Tutor2_Mail;
        break;
      default:
        break;
    }

    return emailToSelect === '-' ? '' : emailToSelect || '';
  });

  return emailsSelectedes;
};

const ListadoAlumnos = ({ alumnos, grupo, selectedEmails, setSelectedEmails }) => {
  useEffect(() => {
    if (!alumnos || alumnos.length === 0) {
      setSelectedEmails({});
      return;
    }

    const newSelectedEmails = {};
    const alumnosSelecteds = alumnos.filter((a) =>
      Object.keys(selectedEmails).includes(String(a.CODIGO)),
    );

    alumnosSelecteds.forEach((alumno) => {
      const emailsSelectedes = selectEmailsByGroups(grupo, alumno);
      newSelectedEmails[alumno.CODIGO] = emailsSelectedes;
    });

    setSelectedEmails(newSelectedEmails);
  }, [alumnos, grupo]);

  const translations = {
    previousText: '<',
    nextText: '>',
    loadingText: 'Cargando..',
    ofText: 'de',
    rowsText: 'líneas',
    noDataText: 'Datos no encontrados',
    pageText: 'Página',
  };

  const [allSelected, setAllSelected] = useState(false); // Estado para controlar si todos están seleccionados

  const handleCheckboxChange = (codigo, email) => {
    const updatedEmails = { ...selectedEmails };
    if (!!updatedEmails[codigo] || updatedEmails[codigo] === '') {
      delete updatedEmails[codigo]; // Si ya está seleccionado, deselecciona
    } else {
      updatedEmails[codigo] = email; // Si no está seleccionado, añade el email
    }
    setSelectedEmails(updatedEmails);
  };

  const toggleSelectAll = () => {
    if (allSelected) {
      setSelectedEmails({}); // Desmarcar todos
    } else {
      const allEmails = {};
      alumnos.forEach((alumno) => {
        const emailsSelectedes = selectEmailsByGroups(grupo, alumno);
        allEmails[alumno.CODIGO] = emailsSelectedes; // Guarda el email
      });
      setSelectedEmails(allEmails); // Marcar todos
    }
    setAllSelected(!allSelected); // Cambiar el estado de selección
  };

  return (
    <>
      {alumnos.length > 0 ? (
        <>
          <Button onClick={toggleSelectAll}>
            {allSelected ? 'Desmarcar Todo' : 'Marcar Todo'}
          </Button>
          <ReactTable
            {...translations}
            columns={[
              {
                Header: 'Seleccionar',
                accessor: 'actions',
                Cell: (row) => {
                  const checked =
                    !!selectedEmails[row.original.CODIGO] ||
                    selectedEmails[row.original.CODIGO] === '';

                  const emails = selectEmailsByGroups(grupo, row.original);

                  return (
                    <Input
                      type="checkbox"
                      checked={checked}
                      onChange={() => handleCheckboxChange(row.original.CODIGO, emails)}
                    />
                  );
                },
              },
              {
                Header: 'Código',
                accessor: 'CODIGO',
              },
              {
                Header: 'Sede',
                accessor: 'SEDE',
              },
              {
                Header: 'Nombre',
                accessor: 'NOMBRE_ALUMNO',
              },
              {
                Header: 'Apellidos',
                accessor: 'APELLIDOS',
              },
              {
                Header: 'Email Cedetec',
                accessor: 'Email_Cedetec',
              },
              {
                Header: 'Email Personal',
                accessor: 'Email_personal',
              },
              {
                Header: 'Tutor 1 Email',
                accessor: 'Tutor1_Mail',
              },
              {
                Header: 'Tutor 2 Email',
                accessor: 'Tutor2_Mail',
              },
              {
                Header: 'DNI',
                accessor: 'DNI',
              },
            ]}
            data={alumnos}
            defaultPageSize={10}
            showPaginationBottom
            className="-striped -highlight mt-5 text-center"
          />
        </>
      ) : (
        ''
      )}
    </>
  );
};

ListadoAlumnos.propTypes = {
  alumnos: PropTypes.array.isRequired,
  grupo: PropTypes.number.isRequired,
  selectedEmails: PropTypes.any,
  setSelectedEmails: PropTypes.any,
};

export default ListadoAlumnos;
