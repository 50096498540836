import { Col } from 'reactstrap';
import ComponentCard from '../../layouts/containers/ComponentCard';
import FormularioIntercambio from '../../components/serviciointercambio/FormularioIntercambio';

const ServicioIntercambio = () => {
  return (
    <>
      <Col md="12">
        <ComponentCard title="Servicio de intercambio" hasButton={false}>
          <FormularioIntercambio />
        </ComponentCard>
      </Col>
    </>
  );
};

export default ServicioIntercambio;
