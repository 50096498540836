import { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Table, Button } from 'reactstrap';
import * as Icon from 'react-feather';
import ModalRecibo from './ModalRecibo';
import ModalLoader from '../../../layouts/modals/ModalLoader';

import InputRecibo from './InputRecibo';
import EstadoRecibo from './EstadoRecibo';
import MetodosPago from './MetodosPago';
import matriculacionApiService from '../../../services/http/matriculacionApiService';
import documentosApiService from '../../../services/http/documentosApiService';

const Recibos = ({
  setListadoRecibos,
  listadoRecibos,
  idMatricula,
  idBorrados,
  setIdBorrados,
  setIdEditados,
  idEditados,
  idEditados2,
  setIdEditados2,
  nuevos,
  setNuevos,
}) => {
  const [recibos, setRecibos] = useState([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [isProceedingLoading, setIsProceedingLoading] = useState(false);
  const [totalRecibos, setTotalRecibos] = useState(0);
  console.log(recibos, 'yeee');
  // Preparamos las variables necesarias
  const enrollmentSession = JSON.parse(localStorage.getItem('enrollment'));

  useEffect(() => {
    const fetchRecibo = async () => {
      try {
        const dataRecibo = await matriculacionApiService.mostrarRecibos({
          headquarter: enrollmentSession.headquarter,
          id: idMatricula,
        });
        const a = [...nuevos, ...dataRecibo];
        let precioTotalRecibos = 0;
        a.forEach((e) => {
          precioTotalRecibos += parseFloat(e.Neto);
        });
        setTotalRecibos(precioTotalRecibos);
        setRecibos(a);
      } catch (error) {
        setTotalRecibos(0);
        setRecibos([]);
      }
    };

    fetchRecibo();
  }, [nuevos]);

  const handleReciboChange = (reciboId, isChecked) => {
    const updatedListadoRecibos = isChecked
      ? [...listadoRecibos, reciboId] // Agregar ID al listado
      : listadoRecibos.filter((id) => id !== reciboId); // Remover ID del listado
    setListadoRecibos(updatedListadoRecibos);
  };

  const marcarTodos = () => {
    // Filtra los recibos que tienen Estado igual a 'Pendiente' y luego extrae sus NUM_Recibo
    const pendienteReciboIds = recibos
      .filter((recibo) => recibo.Estado === 'Pendiente')
      .map((recibo) => recibo.NUM_Recibo);

    // Establece el listado de recibos pendientes
    setListadoRecibos(pendienteReciboIds);

    // Establece que todos están marcados como chequeados
    setIsAllChecked(true);
  };

  const desmarcarTodos = () => {
    setListadoRecibos([]);
    setIsAllChecked(false);
  };

  const deleteIds = () => {
    setIdBorrados([...idBorrados, ...listadoRecibos]);

    setListadoRecibos([]);
  };
  const openModal = () => {
    setIsVisible(true);
  };

  const downloadFile = async (e) => {
    setIsProceedingLoading(true);

    try {
      const dataBlob = await documentosApiService.exportarReciboPDF(
        { headquarter: enrollmentSession.headquarter, reciboId: e },
        { headquarter: enrollmentSession.headquarter },
      );
      const blobFinal = dataBlob.slice(0, dataBlob.size, 'application/pdf');
      const url = URL.createObjectURL(blobFinal);
      window.open(url, '_blank');
      setIsLoading(false);
      setIsProceedingLoading(false);
    } catch (error) {
      setIsLoading(false);
      setIsProceedingLoading(false);
    }
  };
  return (
    <>
      {isProceedingLoading ? <ModalLoader header="Generando documento" /> : ''}
      {isVisible && (
        <ModalRecibo
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          nuevos={nuevos}
          setNuevos={setNuevos}
          matricula={idMatricula}
        />
      )}
      {isAllChecked && !isLoading && !isLoading2 && idBorrados ? (
        <Button className="me-1 mb-2" onClick={desmarcarTodos}>
          <i className="bi bi-check-square" style={{ marginRight: '8px' }}></i>
          Desmarcar todos
        </Button>
      ) : (
        <Button className="me-1 mb-2" onClick={marcarTodos}>
          <i className="bi bi-check-square-fill" style={{ marginRight: '8px' }}></i>
          Marcar todos
        </Button>
      )}
      <Button className="me-1 mb-2" color="success" onClick={openModal}>
        Añadir
      </Button>
      <Button
        className="me-1 mb-2"
        color="danger"
        disabled={listadoRecibos.length === 0}
        onClick={deleteIds}
      >
        Borrar
      </Button>
      <Table
        responsive
        borderless
        style={{ fontSize: '13px', borderCollapse: 'separate', borderSpacing: '0 1em' }}
      >
        <thead>
          <tr>
            <th>ID recibo</th>
            <th>ID factura</th>
            <th>Concepto</th>
            <th>Fecha emisión</th>
            <th>Fecha vencimiento</th>
            <th>Fecha cobro</th>
            <th>Neto</th>
            <th>Bruto</th>
            <th>Descuento</th>
            <th>Tipo</th>
            <th>Forma de pago</th>
            <th>Estado</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {recibos.map((r) => (
            <>
              {idBorrados.includes(r.NUM_Recibo) ? (
                ''
              ) : (
                <tr key={r.codigo}>
                  <td id={r.codigo} className={r.Estado === 'Cobrado' ? 'text-black-50 ' : ''}>
                    <InputRecibo
                      key={r.NUM_Recibo}
                      id={r.NUM_Recibo}
                      concepto={r.Concepto}
                      neto={r.Neto}
                      estado={r.Estado}
                      onReciboChange={handleReciboChange}
                      listadoRecibos={listadoRecibos}
                      setListadoRecibos={setListadoRecibos}
                    />
                  </td>
                  <td className={r.Estado === 'Cobrado' ? 'text-black-50' : ''}>{r.Num_factura}</td>
                  <td className={r.Estado === 'Cobrado' ? 'text-black-50' : ''}>{r.Concepto}</td>
                  <td className={r.Estado === 'Cobrado' ? 'text-black-50' : ''}>
                    {r.Fecha_Emision}
                  </td>
                  <td className={r.Estado === 'Cobrado' ? 'text-black-50' : ''}>
                    {r.Fecha_vencimiento}
                  </td>
                  <td className={r.Estado === 'Cobrado' ? 'text-black-50' : ''}>{r.Fecha_cobro}</td>
                  <td className={r.Estado === 'Cobrado' ? 'text-black-50' : ''}>{r.Neto}€</td>
                  <td className={r.Estado === 'Cobrado' ? 'text-black-50' : ''}>{r.Bruto}€</td>
                  <td className={r.Estado === 'Cobrado' ? 'text-black-50' : ''}>{r.descuento}%</td>
                  <td className={r.Estado === 'Cobrado' ? 'text-black-50' : ''}>
                    {r.Tipo_cliente}
                  </td>
                  {r.Estado === 'Cobrado' ? (
                    <td className={r.Estado === 'Cobrado' ? 'text-black-50' : ''}>
                      {r.Forma_de_pago}
                    </td>
                  ) : (
                    <td>
                      <MetodosPago
                        numRecibo={r.NUM_Recibo}
                        isLoading={isLoading2}
                        setIsLoading={setIsLoading2}
                        actual={
                          idEditados.some((editado) => editado.id === r.NUM_Recibo.toString()) // Verificar si algún objeto en idEditados tiene el mismo ID
                            ? idEditados.find((editado) => editado.id === r.NUM_Recibo.toString())
                                .forma // Obtener forma si se encuentra
                            : r.idPago // Usar r.Forma_de_pago si no se encuentra
                        }
                        idEditados={idEditados}
                        setIdEditados={setIdEditados}
                      />
                    </td>
                  )}
                  {r.Estado === 'Cobrado' ? (
                    <td className={r.Estado === 'Cobrado' ? 'text-black-50' : ''}>{r.Estado}</td>
                  ) : (
                    <td>
                      <EstadoRecibo
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        idEditados={idEditados2}
                        setIdEditados={setIdEditados2}
                        numRecibo={r.NUM_Recibo}
                        actual={
                          idEditados2.some((editado) => editado.id === r.NUM_Recibo.toString()) // Verificar si algún objeto en idEditados tiene el mismo ID
                            ? idEditados2.find((editado) => editado.id === r.NUM_Recibo.toString())
                                .estado // Obtener forma si se encuentra
                            : r.IdEstado // Usar r.Forma_de_pago si no se encuentra
                        }
                      />
                    </td>
                  )}
                  <td
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Button size="sm" onClick={() => downloadFile(r.NUM_Recibo)}>
                      <Icon.Download size={20} className="align-text-center" />
                    </Button>
                  </td>
                </tr>
              )}
            </>
          ))}
        </tbody>
      </Table>
      <span className="text-danger">Importe total de los recibos: {totalRecibos.toFixed(2)} €</span>
    </>
  );
};
Recibos.propTypes = {
  listadoRecibos: PropTypes.any,
  setListadoRecibos: PropTypes.any,
  idMatricula: PropTypes.any,
  idBorrados: PropTypes.any,
  setIdBorrados: PropTypes.any,
  idEditados: PropTypes.any,
  setIdEditados: PropTypes.any,
  idEditados2: PropTypes.any,
  setIdEditados2: PropTypes.any,
  nuevos: PropTypes.any,
  setNuevos: PropTypes.any,
};

export default Recibos;
