import React from 'react';

const RightBg = () => {
  let colorP = '#2962ff';
  let colorS = '#3699ff';

  if (process.env.REACT_APP_DEPLOYMENT === 'local') {
    colorP = '#7bcc23';
    colorS = '#a8d479';
  } else if (process.env.REACT_APP_DEPLOYMENT === 'test') {
    colorP = '#BE2528';
    colorS = '#Daa6a8';
  }

  return (
    <div className="position-absolute end-0 top">
      <svg
        width="235"
        height="255"
        viewBox="0 0 235 255"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M68.0998 80.8352C29.568 58.8255 50.7474 26.3993 66.1536 12.9374L221.331 57.3035L173.743 227.335C119.588 252.59 90.715 235.279 83.0476 223.467C77.2742 209.877 90.3539 183.104 97.6153 171.416C117.578 148.566 120.185 122.853 118.994 112.853C112.554 93.0899 82.3813 83.2732 68.0998 80.8352Z"
          fill={colorS}
          fillOpacity="0.5"
        />
        <path
          d="M88 72C43.2 60 55.3333 19 67 0L235 1V197C187.8 240.2 154 230.333 143 220C133.4 207.2 139 174.667 143 160C156.6 129.6 152 101.333 148 91C136 71.8 103 70.3333 88 72Z"
          fill={colorP}
        />
      </svg>
    </div>
  );
};

export default RightBg;
