import { useState } from 'react';
import PropTypes from 'prop-types';
import { ModalBody, ModalFooter, Modal, Button } from 'reactstrap';

const ModalAviso = ({ toggle, header, onClose = () => {} }) => {
  const [modalState, setModalState] = useState(true);
  const handleModalState = () => {
    if (modalState === true) {
      setModalState(false);
      onClose();
    } else {
      setModalState(true);
    }
  };
  const handleModalSee = () => {
    toggle('2');
    setModalState(false);
    onClose();
  };

  return (
    <Modal isOpen={modalState} centered>
      <ModalBody>
        <h4 className="text-center mt-3">{header}</h4>
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleModalSee}>Ver</Button>
        <Button color="danger" onClick={handleModalState}>
          Cerrar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ModalAviso.propTypes = {
  toggle: PropTypes.any,
  header: PropTypes.any,
  onClose: PropTypes.func,
};

export default ModalAviso;
