import { useEffect, useState } from 'react';
import { Col, List, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import envioMailService from '../../services/http/envioEmailService';

const BodyRemesa = ({ remesa }) => {
  const [envios, setEnvios] = useState([]);
  const [loadingData, setLoadingData] = useState([]);

  const fetchEnvios = async () => {
    setLoadingData(true);

    try {
      const data = await envioMailService.getEnviosByRemesa({
        headquarter: 'CEDESCA',
        idRemesa: remesa.IdRemesa,
      });
      setEnvios(data);
    } catch (error) {
      setEnvios([]);
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchEnvios();
  }, []);

  if (loadingData) {
    return (
      <Col className="mt-3" md="12">
        Cargando envios de la remesa
      </Col>
    );
  }

  return (
    <Col md="12">
      <Row>
        <h4>Enviado a los siguiente destinatarios:</h4>
        <List>
          {envios.map((e) => (
            <Row className="m-0">
              - {e.EmailDestino} ({e.Nombre})
            </Row>
          ))}
        </List>
      </Row>
    </Col>
  );
};

BodyRemesa.propTypes = {
  remesa: PropTypes.any,
};

export default BodyRemesa;
