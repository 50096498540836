import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Row, Col, Label, Input, Form, FormGroup, Button } from 'reactstrap';
import Loader from '../../layouts/loader/Loader';
import gruposService from '../../services/http/gruposService';
import ModalClickable from '../../layouts/modals/ModalClickable';

const FormularioGrupos = () => {
  const navigate = useNavigate();
  const { empresaId, id } = useParams();
  const [isSaved, setIsSaved] = useState(false);
  const [grupoDataa, setGrupoDataa] = useState([]);
  const [grupoDataYear, setGrupoDataYear] = useState([]);
  const [grupoDataState, setGrupoDataState] = useState([]);
  const [grupoDataAA, setGrupoDataAA] = useState([]);

  useEffect(() => {
    const fetchGrupoData = async () => {
      const grupoData = await gruposService.consultarDetallesGrupo({
        headquarter: empresaId,
        id,
      });
      setGrupoDataa(grupoData);
    };
    const fetchGrupoYear = async () => {
      const grupoDataY = await gruposService.consultarGruposAnos({
        headquarter: empresaId,
      });
      setGrupoDataYear(grupoDataY);
    };
    const fetchGrupoAA = async () => {
      const grupoDataAAAA = await gruposService.consultarGruposAreaAcademica({
        headquarter: empresaId,
      });
      setGrupoDataAA(grupoDataAAAA);
    };
    const fetchGrupoState = async () => {
      const grupoDataS = await gruposService.consultarGruposEstado({
        headquarter: empresaId,
      });
      setGrupoDataState(grupoDataS);
    };
    fetchGrupoData();

    fetchGrupoYear();
    fetchGrupoAA();
    fetchGrupoState();
  }, []);

  const handleBack = () => {
    navigate(`/grupos`); // Navega a la ruta deseada
  };

  // Validación con Yup
  const validationSchema = Yup.object({
    MaximoAlumnos: Yup.number().required('El máximo de alumnos es obligatorio'),
    Nombre: Yup.string().required('El nombre es obligatorio'),
    Descripcion: Yup.string().required('La descripción es obligatoria'),
  });

  // Verificar si grupoDataa[0] tiene datos válidos
  const grupo = grupoDataa[0];

  // Formik setup
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      codigogrupo: grupo?.codigogrupo || '', // El valor de codigogrupo es solo lectura
      MaximoAlumnos: grupo?.MaximoAlumnos || '',
      Nombre: grupo?.Nombre || '',
      Descripcion: grupo?.Descripcion || '',
      AreaAcademica: grupo?.AreasAcademicas || '',
      Estado: grupo?.Estado || '',
      Año: grupo?.AñoAcademico || '',
    },
    validationSchema, // Validación
    onSubmit: (values) => {
      // Aquí podrías manejar la lógica de envío, por ejemplo:
      gruposService.guardarDatosGrupos({
        data: values,
        empresaId,
      });
      setIsSaved(true);
    },
  });

  return (
    <>
      {isSaved && <ModalClickable header="Datos guardados correctamente." />}

      {!grupoDataa.length > 0 ? (
        <Loader />
      ) : (
        <div className="mt-3">
          {console.log(formik.values.codigogrupo)}
          <Form onSubmit={formik.handleSubmit}>
            <Row form>
              <Col md={3}>
                <FormGroup>
                  <Label for="codigogrupo">Código de Grupo</Label>
                  <Input
                    id="codigogrupo"
                    name="codigogrupo"
                    type="text"
                    value={formik.values.codigogrupo}
                    readOnly
                    disabled
                  />
                </FormGroup>
              </Col>

              <Col md={3}>
                <FormGroup>
                  <Label for="MaximoAlumnos">Máximo Alumnos</Label>
                  <Input
                    id="MaximoAlumnos"
                    name="MaximoAlumnos"
                    type="number"
                    value={formik.values.MaximoAlumnos}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={formik.touched.MaximoAlumnos && !!formik.errors.MaximoAlumnos}
                  />
                  {formik.touched.MaximoAlumnos && formik.errors.MaximoAlumnos ? (
                    <div className="invalid-feedback">{formik.errors.MaximoAlumnos}</div>
                  ) : null}
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label for="Nombre">Nombre</Label>
                  <Input
                    id="Nombre"
                    name="Nombre"
                    type="text"
                    value={formik.values.Nombre}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={formik.touched.Nombre && !!formik.errors.Nombre}
                  />
                  {formik.touched.Nombre && formik.errors.Nombre ? (
                    <div className="invalid-feedback">{formik.errors.Nombre}</div>
                  ) : null}
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label for="Descripcion">Descripción</Label>
                  <Input
                    id="Descripcion"
                    name="Descripcion"
                    type="text"
                    value={formik.values.Descripcion}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={formik.touched.Descripcion && !!formik.errors.Descripcion}
                  />
                  {formik.touched.Descripcion && formik.errors.Descripcion ? (
                    <div className="invalid-feedback">{formik.errors.Descripcion}</div>
                  ) : null}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="Año">Año Académico</Label>
                  <Input
                    type="select"
                    id="Año"
                    name="Año"
                    value={formik.values.Año}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={formik.touched.Año && !!formik.errors.Año}
                  >
                    {grupoDataYear.map((year) => (
                      <option key={year.Id} value={year.Id}>
                        {year.Valor}
                      </option>
                    ))}
                  </Input>
                  {formik.touched.Año && formik.errors.Año ? (
                    <div className="invalid-feedback">{formik.errors.Año}</div>
                  ) : null}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="Estado">Estado</Label>
                  <Input
                    type="select"
                    id="Estado"
                    name="Estado"
                    value={formik.values.Estado}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={formik.touched.Estado && !!formik.errors.Estado}
                  >
                    {grupoDataState.map((year) => (
                      <option key={year.IdEstado} value={year.IdEstado}>
                        {year.Descripcion}
                      </option>
                    ))}
                  </Input>
                  {formik.touched.Estado && formik.errors.Estado ? (
                    <div className="invalid-feedback">{formik.errors.Estado}</div>
                  ) : null}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="AreaAcademica">Área Académica</Label>
                  <Input
                    type="select"
                    id="AreaAcademica"
                    name="AreaAcademica"
                    value={formik.values.AreaAcademica}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={formik.touched.AreaAcademica && !!formik.errors.AreaAcademica}
                  >
                    {grupoDataAA.map((year) => (
                      <option key={year.Id} value={year.Id}>
                        {year.Valor}
                      </option>
                    ))}
                  </Input>
                  {formik.touched.AreaAcademica && formik.errors.AreaAcademica ? (
                    <div className="invalid-feedback">{formik.errors.AreaAcademica}</div>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="10" className="d-flex justify-content-start" onClick={handleBack}>
                <Button>Atrás</Button>
              </Col>
              <Col className="d-flex justify-content-end">
                <Button type="submit" color="success">
                  Guardar
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </>
  );
};
export default FormularioGrupos;
