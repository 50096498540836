import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import moment from 'moment';
import { ModalBody, ModalFooter, Modal, Button, Label } from 'reactstrap';

const ModalDate = (props) => {
  const handleFecha = (value) => {
    const fechad = moment.utc(value).toDate();
    const formattedDate = fechad.toLocaleDateString('es-ES', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    props.setFecha(formattedDate);
  };

  const handleFechaFinal = (value) => {
    const fechad = moment.utc(value).toDate();
    const formattedDate = fechad.toLocaleDateString('es-ES', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    props.setFechaFinal(formattedDate);
  };
  return (
    <Modal isOpen={props.state} centered>
      <ModalBody>
        <Label htmlFor="dateCreate">
          <b>Seleccionar fecha inicial</b>
        </Label>
        <Datetime timeFormat={false} onChange={handleFecha} />

        <Label htmlFor="dateCreate">
          <b>Seleccionar fecha final</b>
        </Label>
        <Datetime timeFormat={false} onChange={handleFechaFinal} />
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={props.dateFunction}
          disabled={props.fecha === '' || props.fechaFinal === '' ? 'disabled' : ''}
        >
          Aceptar
        </Button>
        <Button
          color="danger"
          onClick={() => {
            props.setState(false);
          }}
        >
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};
ModalDate.propTypes = {
  dateFunction: PropTypes.any,
  state: PropTypes.any,
  setState: PropTypes.any,
  setFecha: PropTypes.any,
  fecha: PropTypes.any,
  setFechaFinal: PropTypes.any,
  fechaFinal: PropTypes.any,
};
export default ModalDate;
