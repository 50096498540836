import { Col } from 'reactstrap';
import ComponentCard from '../../layouts/containers/ComponentCard';
import FormularioActa from '../../components/actas/FormularioActa';

const Actas = () => {
  return (
    <>
      <Col md="12">
        <ComponentCard title="Generar acta" hasButton={false}>
          <FormularioActa isActa />
        </ComponentCard>
      </Col>
    </>
  );
};
export default Actas;
