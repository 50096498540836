import { ModalBody, ModalFooter, Modal, Button } from 'reactstrap';
import PropTypes from 'prop-types';

const ModalHeaderBody = ({ open = false, setOpen = () => {}, title, children }) => {
  return (
    <Modal isOpen={open} centered>
      <ModalBody>
        <h4 className="text-center mt-3">{title}</h4>
        {children}
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => setOpen(false)}>Cerrar</Button>
      </ModalFooter>
    </Modal>
  );
};

ModalHeaderBody.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.any,
  title: PropTypes.any,
  children: PropTypes.any,
};

export default ModalHeaderBody;
