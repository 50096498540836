import { useState } from 'react';
import { Col, Nav, NavItem, NavLink } from 'reactstrap';
import ComponentCard from '../../layouts/containers/ComponentCard';
import EnvioMails from './EnvioEmails';
import ListadoEmails from './ListadoEmails';

const ENVIAR_EMAIL_TABS = {
  ENVIAR: 'enviar',
  LISTADO: 'listado',
};

const EnvioMailsWrapper = () => {
  const [activeTab, setActiveTab] = useState(ENVIAR_EMAIL_TABS.ENVIAR);

  return (
    <Col md="12">
      <ComponentCard title="Enviar emails" hasButton={false}>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={activeTab === ENVIAR_EMAIL_TABS.ENVIAR ? 'active' : ''}
              onClick={() => {
                setActiveTab(ENVIAR_EMAIL_TABS.ENVIAR);
              }}
            >
              Enviar
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === ENVIAR_EMAIL_TABS.LISTADO ? 'active' : ''}
              onClick={() => {
                setActiveTab(ENVIAR_EMAIL_TABS.LISTADO);
              }}
            >
              Listado
            </NavLink>
          </NavItem>
        </Nav>

        {activeTab === ENVIAR_EMAIL_TABS.ENVIAR && <EnvioMails />}

        {activeTab === ENVIAR_EMAIL_TABS.LISTADO && <ListadoEmails />}
      </ComponentCard>
    </Col>
  );
};
export default EnvioMailsWrapper;
