import { useState } from 'react';
import { Col } from 'reactstrap';
import FormularioNuevoUsuario from '../../components/nuevoUsuario/FormularioNuevoUsuario';
import ListadoEmpresas from '../../components/matriculas/grupos/ListadoEmpresas';

const NuevoAlumno = () => {
  const [pageCont, setPageCont] = useState(1);
  const [empresaId, setEmpresaId] = useState('CEDESCA');

  const nextPage = () => {
    setPageCont(2);
  };
  return (
    <>
      <Col md="12">
        {pageCont === 1 && <ListadoEmpresas setEmpresaId={setEmpresaId} next={nextPage} />}
        {pageCont === 2 && <FormularioNuevoUsuario empresaId={empresaId} />}
      </Col>
    </>
  );
};
export default NuevoAlumno;
