import { useState } from 'react';
import { Col } from 'reactstrap';
import ListadoEmpresas from '../../components/matriculas/grupos/ListadoEmpresas';
import MostrarGrupos from '../../components/grupos/MostrarGrupos';

const Grupos = () => {
  const [pageCont, setPageCont] = useState(1);
  const [empresaId, setEmpresaId] = useState('CEDESCA');

  const nextPage = () => {
    setPageCont(2);
  };

  const returnPage = () => {
    setPageCont(pageCont - 1);
  };
  return (
    <>
      <Col md="12">
        {pageCont === 1 && <ListadoEmpresas setEmpresaId={setEmpresaId} next={nextPage} />}
        {pageCont === 2 && <MostrarGrupos empresaId={empresaId} ret={returnPage} />}
      </Col>
    </>
  );
};
export default Grupos;
