import React, { useState, useEffect, useContext } from 'react';
import { Input } from 'reactstrap';
import PropTypes from 'prop-types';
import { AuthContext } from '../../../context/authContext';
import matriculacionApiService from '../../../services/http/matriculacionApiService';

const InputModulos = ({
  obj,
  modulos,
  setTotal,
  setModulos,
  setHoursCounter,
  hoursCounter,
  total,
  estado,
  dataApi,
}) => {
  // Preparamos variables necesarias
  const [isChecked, setIsChecked] = useState(false);
  const { userOU } = useContext(AuthContext);
  const [done, setDone] = useState(false);
  const localEnrollment = JSON.parse(localStorage.getItem('enrollment'));

  // Efectos
  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataMatricula = await matriculacionApiService.detalleMatricula({
          headquarter: localEnrollment.headquarter,
          id: localEnrollment.id,
        });

        if (dataMatricula.some((objeto) => objeto.descripcion === obj.descripcion)) {
          const newArr = modulos;

          newArr.push(`${obj.modulo} ${obj.descripcion}`);
          setModulos([...newArr]);
          setIsChecked(true);
        }

        setDone(true);
      } catch (error) {
        setModulos([]);
        setIsChecked(false);
        setDone(false);
      }
    };

    fetchData();
  }, []);

  // Función para controlar los cambios de los checkbox
  const handleCheckboxChange = (e) => {
    if (!modulos.includes(`${obj.modulo} ${obj.descripcion}`)) {
      const newArr = modulos;
      const splitted = e.target.value.split('HH');
      if (
        !splitted[0].includes('Formación en el centro de trabajo') &&
        !splitted[0].includes('Formación en centros de trabajo')
      ) {
        const notaAsInt = parseInt(splitted[1], 10);
        setHoursCounter(hoursCounter + notaAsInt);
      }
      setTotal(total + obj.precio);
      newArr.push(splitted[0].trim());
      setModulos([...newArr]);
    } else {
      const splitted = e.target.value.split('HH');
      const text = splitted[0].trim();
      const index = modulos.indexOf(text);
      if (index !== -1) {
        if (
          !splitted[0].includes('Formación en el centro de trabajo') &&
          !splitted[0].includes('Formación en centros de trabajo')
        ) {
          const notaAsInt = parseInt(splitted[1], 10);
          setHoursCounter(hoursCounter - notaAsInt);
        }
        setTotal(total - obj.precio);
        const newArr = modulos;
        newArr.splice(index, 1);
        setModulos([...newArr]);
      }
      setIsChecked(!isChecked);
    }
    setIsChecked(!isChecked);
  };

  return (
    <>
      {done && dataApi.length > 0 && (
        <Input
          type="checkbox"
          id={obj.codigo}
          name="option"
          value={`${obj.modulo} ${obj.descripcion} HH${obj.horas}`}
          onChange={handleCheckboxChange}
          checked={modulos.includes(`${obj.modulo} ${obj.descripcion}`)}
          disabled={
            obj.Nota === 1 ||
            parseInt(obj.convocatorias, 10) >= 5 ||
            estado ||
            dataApi[0].estado === 'Revisar secretaria' ||
            userOU.centro === 'ORIENTACIÓN'
          }
        />
      )}
    </>
  );
};
InputModulos.propTypes = {
  hoursCounter: PropTypes.any,
  setHoursCounter: PropTypes.any,
  total: PropTypes.any,
  setTotal: PropTypes.any,
  obj: PropTypes.any,
  modulos: PropTypes.any,
  setModulos: PropTypes.any,
  estado: PropTypes.any,
  dataApi: PropTypes.any,
};

export default InputModulos;
