import HttpService from './httpService';

class CalculadoraNotasApiService extends HttpService {
  constructor(baseUrl, generalCatch) {
    super(baseUrl, generalCatch);
  }

  async mostrarCursoActual({ headquarter }) {
    return this.get('mostrarCursoActual', { params: { headquarter } });
  }

  async mostrarAlumnos({ headquarter }) {
    return this.get('mostrarAlumnos', { params: { headquarter } });
  }

  async calcularNotas({ id, idplan, idplan2, centro }) {
    return this.post('calcularNotas', { id, idplan, idplan2, centro });
  }
}

const calculadoraNotasApiService = new CalculadoraNotasApiService(process.env.REACT_APP_NODE_API);
export default calculadoraNotasApiService;
