import HttpService from './httpService';

class LoginApiService extends HttpService {
  constructor(baseUrl, generalCatch) {
    super(baseUrl, generalCatch);
  }

  async auth({ tokenId, profileObj }) {
    return this.post('auth', { tokenId, profileObj });
  }
}

const loginApiService = new LoginApiService(process.env.REACT_APP_NODE_API);
export default loginApiService;
