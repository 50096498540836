import { Col } from 'reactstrap';
import ComponentCard from '../../layouts/containers/ComponentCard';
import FiltroSolicitudes from '../../components/matriculas/solicitudes/FiltroSolicitudes';

const Solicitudes = () => {
  return (
    <>
      <Col md="12">
        <ComponentCard title="Solicitudes" hasButton={false} className="clearfix">
          <FiltroSolicitudes />
        </ComponentCard>
      </Col>
    </>
  );
};

export default Solicitudes;
