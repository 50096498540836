import { Col } from 'reactstrap';
import ComponentCard from '../../layouts/containers/ComponentCard';
import FormularioActa from '../../components/actas/FormularioActa';

const ImportarNotas = () => {
  return (
    <>
      <Col md="12">
        <ComponentCard title="Importar notas" hasButton={false}>
          <FormularioActa />
        </ComponentCard>
      </Col>
    </>
  );
};
export default ImportarNotas;
