import { useState, useEffect, useContext } from 'react';
import { Col, Input, Label, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { AuthContext } from '../../context/authContext';
import alumnosApiService from '../../services/http/alumnosApiService';

const Tutor = ({ posicion, menorEdad, formikValues, setValues, handleChange, errors }) => {
  const { userOU } = useContext(AuthContext);
  // Declaramos las variables necesarias
  const nombreTutor = `nombreTutor${posicion}`;
  const apellido1Tutor = `apellido1Tutor${posicion}`;
  const apellido2Tutor = `apellido2Tutor${posicion}`;
  const telefonoTutor = `telefonoTutor${posicion}`;
  const movilTutor = `movilTutor${posicion}`;
  const emailTutor = `emailTutor${posicion}`;
  const tipoDocumentoTutor = `tipoDocumentoTutor${posicion}`;
  const documentoTutor = `documentoTutor${posicion}`;
  const parentescoTutor = `parentescoTutor${posicion}`;
  const parentescoTutorId = `parentescoTutorId${posicion}`;
  const parent = `parentesco${posicion}`;

  const { empresaId } = JSON.parse(localStorage.getItem('student'));

  // Declaramos los estados necesarios
  const [document, setDocument] = useState([]);
  const [parentescoList, setParentescoList] = useState([]);
  const [parentesco, setParentesco] = useState('');

  // Declaramos los efectos

  // Peticiones a API
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const dataParentescos = await alumnosApiService.cargarParentescos({
          headquarter: empresaId,
        });
        const arrayFiltrado = dataParentescos.filter(
          (objeto) => objeto.descripcion === formikValues[parentescoTutor],
        );
        if (arrayFiltrado.length > 0) {
          setValues((prev) => ({ ...prev, [parentescoTutorId]: arrayFiltrado[0].id }));
        }
        setParentescoList(dataParentescos);
      } catch (error) {
        setParentescoList([]);
      }

      try {
        const dataDocumentos = await alumnosApiService.cargarDocumentos({ empresaId });
        setDocument(dataDocumentos);
      } catch (error) {
        setDocument([]);
      }
    };

    fetchOptions();
  }, []);

  // Efecto para setear los datos del parentesco
  useEffect(() => {
    let filtered = [];
    filtered = parentescoList.filter((item) => item.descripcion === formikValues[parentescoTutor]);

    if (filtered.length > 0) {
      setValues((prev) => ({ ...prev, [parentescoTutor]: filtered[0].id }));
      setParentesco(filtered[0].descripcion);
    }
  }, [parentescoList]);

  // Efecto para actualizar el texto del parentesco
  useEffect(() => {
    let filtered = [];
    filtered = parentescoList.filter(
      (item) => item.id === parseInt(formikValues[parentescoTutor], 10),
    );

    if (filtered.length > 0) {
      setParentesco(filtered[0].descripcion);
    } else {
      setParentesco('');
    }
  }, [formikValues[parentescoTutor]]);

  // Efecto para actualizar el parentesco en formik
  useEffect(() => {
    setValues((prev) => ({ ...prev, [parent]: parentesco }));
  }, [parentesco]);

  return (
    <div className="mb-4">
      <Row>
        <Col md="12">
          <h3>{parentesco}</h3>
        </Col>
      </Row>
      <Row>
        <Col md="2">
          <Label htmlFor={nombreTutor}>
            <small>
              Nombre {menorEdad && posicion === '1' && <span className="text-danger">*</span>}
            </small>
          </Label>
          <Input
            id={nombreTutor}
            name={nombreTutor}
            type="text"
            onChange={handleChange}
            value={formikValues[nombreTutor]}
            className={errors[nombreTutor] ? 'border border-danger error' : ''}
            disabled={userOU.centro === 'ORIENTACIÓN'}
          />
          {errors[nombreTutor] ? (
            <div className="text-danger tiny">{errors[nombreTutor]}</div>
          ) : null}
        </Col>
        <Col md="2">
          <Label htmlFor={apellido1Tutor}>
            <small>
              Primer apellido{' '}
              {menorEdad && posicion === '1' && <span className="text-danger">*</span>}
            </small>
          </Label>
          <Input
            id={apellido1Tutor}
            name={apellido1Tutor}
            type="text"
            onChange={handleChange}
            value={formikValues[apellido1Tutor]}
            className={errors[apellido1Tutor] ? 'border border-danger error' : ''}
            disabled={userOU.centro === 'ORIENTACIÓN'}
          />
          {errors[apellido1Tutor] ? (
            <div className="text-danger tiny">{errors[apellido1Tutor]}</div>
          ) : null}
        </Col>
        <Col md="2">
          <Label htmlFor={apellido2Tutor}>
            <small>
              Segundo apellido{' '}
              {menorEdad && posicion === '1' && <span className="text-danger">*</span>}
            </small>
          </Label>
          <Input
            id={apellido2Tutor}
            name={apellido2Tutor}
            type="text"
            onChange={handleChange}
            value={formikValues[apellido2Tutor]}
            className={errors[apellido2Tutor] ? 'border border-danger error' : ''}
            disabled={userOU.centro === 'ORIENTACIÓN'}
          />
          {errors[apellido2Tutor] ? (
            <div className="text-danger tiny">{errors[apellido2Tutor]}</div>
          ) : null}
        </Col>
        <Col md="2">
          <Label htmlFor={telefonoTutor}>
            <small>Teléfono</small>
          </Label>
          <Input
            id={telefonoTutor}
            name={telefonoTutor}
            type="text"
            onChange={handleChange}
            value={formikValues[telefonoTutor]}
            className={errors[telefonoTutor] ? 'border border-danger error' : ''}
            disabled={userOU.centro === 'ORIENTACIÓN'}
          />
          {errors[telefonoTutor] ? (
            <div className="text-danger tiny">{errors[telefonoTutor]}</div>
          ) : null}
        </Col>
        <Col md="2">
          <Label htmlFor={movilTutor}>
            <small>
              Móvil {menorEdad && posicion === '1' && <span className="text-danger">*</span>}
            </small>
          </Label>
          <Input
            id={movilTutor}
            name={movilTutor}
            type="text"
            onChange={handleChange}
            value={formikValues[movilTutor]}
            className={errors[movilTutor] ? 'border border-danger error' : ''}
            disabled={userOU.centro === 'ORIENTACIÓN'}
          />
          {errors[movilTutor] ? <div className="text-danger tiny">{errors[movilTutor]}</div> : null}
        </Col>
        <Col md="2">
          <Label htmlFor={emailTutor}>
            <small>
              Email {menorEdad && posicion === '1' && <span className="text-danger">*</span>}
            </small>
          </Label>
          <Input
            id={emailTutor}
            name={emailTutor}
            type="text"
            onChange={handleChange}
            value={formikValues[emailTutor]}
            className={errors[emailTutor] ? 'border border-danger error' : ''}
            disabled={userOU.centro === 'ORIENTACIÓN'}
          />
          {errors[emailTutor] ? <div className="text-danger tiny">{errors[emailTutor]}</div> : null}
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md="2">
          <Label htmlFor={tipoDocumentoTutor}>
            <small>
              Tipo documento{' '}
              {menorEdad && posicion === '1' && <span className="text-danger">*</span>}
            </small>
          </Label>
          <Input
            id={tipoDocumentoTutor}
            name={tipoDocumentoTutor}
            type="select"
            onChange={handleChange}
            value={formikValues[tipoDocumentoTutor]}
            className={errors[tipoDocumentoTutor] ? 'border border-danger error' : ''}
            disabled={userOU.centro === 'ORIENTACIÓN'}
          >
            {document !== ''
              ? document.map((option) => (
                  <option key={`${option.id}-tipoDoc${posicion}`} value={option.id}>
                    {option.nombre}
                  </option>
                ))
              : ''}
          </Input>
          {errors[tipoDocumentoTutor] ? (
            <div className="text-danger tiny">{errors[tipoDocumentoTutor]}</div>
          ) : null}
        </Col>
        <Col md="2">
          <Label htmlFor={documentoTutor}>
            <small>
              Documento {menorEdad && posicion === '1' && <span className="text-danger">*</span>}
            </small>
          </Label>
          <Input
            id={documentoTutor}
            name={documentoTutor}
            type="text"
            onChange={handleChange}
            value={formikValues[documentoTutor]}
            className={errors[documentoTutor] ? 'border border-danger error' : ''}
            disabled={formikValues[tipoDocumentoTutor] === '-1' || userOU.centro === 'ORIENTACIÓN'}
          />
          {errors[documentoTutor] ? (
            <div className="text-danger tiny">{errors[documentoTutor]}</div>
          ) : null}
        </Col>
        <Col md="2">
          <Label htmlFor={parentescoTutor}>
            <small>
              Parentesco {menorEdad && posicion === '1' && <span className="text-danger">*</span>}
            </small>
          </Label>
          <Input
            name={parentescoTutor}
            id={parentescoTutor}
            type="select"
            onChange={handleChange}
            value={formikValues[parentescoTutor]}
            className={errors[parentescoTutor] ? 'border border-danger error' : ''}
            disabled={userOU.centro === 'ORIENTACIÓN'}
          >
            {parentescoList !== ''
              ? parentescoList.map((option) => (
                  <option key={`${option.id}-parentesco${posicion}`} value={option.id}>
                    {option.descripcion}
                  </option>
                ))
              : ''}
          </Input>
          {errors[parentescoTutor] ? (
            <div className="text-danger tiny">{errors[parentescoTutor]}</div>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

Tutor.propTypes = {
  posicion: PropTypes.any,
  menorEdad: PropTypes.any,
  formikValues: PropTypes.any,
  setValues: PropTypes.any,
  handleChange: PropTypes.any,
  errors: PropTypes.any,
};

export default Tutor;
