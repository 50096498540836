import HttpService from './httpService';

class FuncionalidadesApiService extends HttpService {
  constructor(baseUrl, generalCatch) {
    super(baseUrl, generalCatch);
  }

  async cargarFuncionalidades({ headquarter }) {
    return this.get('cargarFuncionalidades', { params: { headquarter } });
  }

  async guardarFuncionalidades({ data }) {
    return this.post('guardarFuncionalidades', { params: { data } });
  }
}

const funcionalidadesApiService = new FuncionalidadesApiService(process.env.REACT_APP_NODE_API);
export default funcionalidadesApiService;
