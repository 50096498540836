import React from 'react';

const LeftBg = () => {
  let colorP = '#2962ff';

  if (process.env.REACT_APP_DEPLOYMENT === 'local') {
    colorP = '#7bcc23';
  } else if (process.env.REACT_APP_DEPLOYMENT === 'test') {
    colorP = '#BE2528';
  }

  return (
    <div className="position-absolute left bottom-0">
      <svg
        width="376"
        height="317"
        viewBox="0 0 376 317"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M98 59.596C70 -2.00401 21 -4.07068 0 2.59599V316.596L375 315.596C377.4 234.796 297 180.263 257 165.596C215.667 155.929 126 121.196 98 59.596Z"
          fill={colorP}
        />
      </svg>
    </div>
  );
};

export default LeftBg;
