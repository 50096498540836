import { useState, useEffect, useRef, useContext } from 'react';
import { Form, Input, Label, Row, Col, Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import { useFormik } from 'formik';
import * as Icon from 'react-feather';
import ComponentCard from '../../layouts/containers/ComponentCard';
import ModalClickable from '../../layouts/modals/ModalClickable';
import { validarCIF, validarDNI, validarNIE } from '../../functions/documentType';
import { AuthContext } from '../../context/authContext';
import alumnosApiService from '../../services/http/alumnosApiService';
import phpApiService from '../../services/http/phpApiService';
import matriculacionApiService from '../../services/http/matriculacionApiService';

const disableFutureDates = (current) => {
  // Obtiene la fecha actual
  const today = new Date();

  // Compara la fecha actual con la fecha seleccionada
  return current.isBefore(today);
};

const FormularioNuevoUsuario = (props) => {
  const navigate = useNavigate();
  const { userOU } = useContext(AuthContext);
  const { idCedesca, idCntec, centro } = userOU;

  // Estados de error
  const [errorPart1, setErrorPart1] = useState(true);
  const [errorPart2, setErrorPart2] = useState(true);
  const [errorPart3, setErrorPart3] = useState(true);
  const [errorPart4, setErrorPart4] = useState(true);

  const [newId, setNewId] = useState();
  // Otros estados
  const [text, setText] = useState('');
  const [stay, setStay] = useState(true);
  const [modal, setModal] = useState(false);
  const [age, setAge] = useState(0);
  const [yearsApi, setYearsApi] = useState('');
  const [academicFormation, setAcademicFormation] = useState('');
  const [formationCenter, setFormationCenter] = useState([]);
  const [country, setCountry] = useState('');
  const [province, setProvince] = useState('');
  const [poblation, setPoblation] = useState('');
  const [document, setDocument] = useState('');
  const [cp, setCP] = useState('');
  const [poblationFiltered, setPoblationFiltered] = useState('');
  const [poblationBirthFiltered, setPoblationBirthFiltered] = useState('');
  const [cpFiltered, setCPFiltered] = useState('');
  const [studiesList, setStudiesList] = useState([]);
  const [studiesList2, setStudiesList2] = useState([]);
  const [fidelitationlist, setFidelitationList] = useState([]);
  const [sitLaboralList, setSitLaboralList] = useState([]);

  const [lastStudiesList, setLastStudiesList] = useState('');
  const [parentescoList, setParentescoList] = useState([]);

  // Refs para los campos de entrada
  const rowRef1 = useRef(null);
  const rowRef2 = useRef(null);
  const rowRef3 = useRef(null);
  const rowRef4 = useRef(null);

  // Función para validar campos
  const validate = (values) => {
    // Seteamos los bloques de errores
    setErrorPart1(false);
    setErrorPart2(false);
    setErrorPart3(false);
    setErrorPart4(false);

    const errors = {};

    /** DATOS PERSONALES */

    // Validamos el nombre
    if (!values.nombre) {
      errors.nombre = 'Obligatorio';
      setErrorPart1(true);
    } else if (!/^(?![\s.]+$)[a-zA-ZÀ-ÿ\s.]*$/i.test(values.nombre.trim())) {
      errors.nombre = 'Nombre incorrecto';
      setErrorPart1(true);
    }

    // Validamos apellidos
    if (!values.apellidos) {
      errors.apellidos = 'Obligatorio';
      setErrorPart1(true);
    } else if (!/^(?![\s.]+$)[a-zA-ZÀ-ÿ\s.]*$/i.test(values.nombre.trim())) {
      errors.apellidos = 'Apellidos incorrectos';
      setErrorPart1(true);
    }

    // Validamos el tipo de documento
    if (values.tipoDocumento === '-1') {
      errors.tipoDocumento = 'Obligatorio';
      setErrorPart1(true);
    }

    // Validamos el documento
    if (!values.documento) {
      errors.documento = 'Obligatorio';
      setErrorPart1(true);
    } else if (values.tipoDocumento === '0' && !validarDNI(values.documento)) {
      errors.documento = 'Formato incorrecto';
      setErrorPart1(true);
    } else if (values.tipoDocumento === '1' && !validarNIE(values.documento)) {
      errors.documento = 'Formato incorrecto';
      setErrorPart1(true);
    } else if (values.tipoDocumento === '3' && !validarCIF(values.documento)) {
      errors.documento = 'Formato incorrecto';
      setErrorPart1(true);
    }

    // Validamos la fecha de nacimiento
    if (!values.fechaNacimiento) {
      errors.fechaNacimiento = 'Obligatorio';
      setErrorPart1(true);
    }

    // Validamos el país de residencia
    if (!values.pais) {
      errors.pais = 'Obligatorio';
      setErrorPart1(true);
    }

    // Validamos la provincia de residencia
    if (!values.provincia && values.pais === 'España') {
      errors.provincia = 'Obligatorio';
      setErrorPart1(true);
    }

    // Validamos el municipio de residencia
    if (!values.municipio && values.pais === 'España') {
      errors.municipio = 'Obligatorio';
      setErrorPart1(true);
    }

    // Validamos el código postal
    if (!values.cp && values.pais === 'España') {
      errors.cp = 'Obligatorio';
      setErrorPart1(true);
    }

    // Validamos el código postal
    if (values.conocer === 0 || values.conocer === '0') {
      errors.conocer = 'Obligatorio';
      setErrorPart1(true);
    }

    // Validamos el domicilio
    if (!values.domicilio) {
      errors.domicilio = 'Obligatorio';
      setErrorPart1(true);
    }

    // Validamos el país de nacimiento
    if (!values.paisNacimiento) {
      errors.paisNacimiento = 'Obligatorio';
      setErrorPart1(true);
    } else if (values.paisNacimiento !== 'España') {
      values.provinciaNacimiento = '';
      values.municipioNacimiento = '';
    }

    // Validamos la provincia de nacimiento
    if (values.paisNacimiento === 'España' && !values.provinciaNacimiento) {
      errors.provinciaNacimiento = 'Obligatorio';
      setErrorPart1(true);
    }

    // Validamos el municipio de nacimiento
    if (values.paisNacimiento === 'España' && !values.municipioNacimiento) {
      errors.municipioNacimiento = 'Obligatorio';
      setErrorPart1(true);
    }

    // Validamos la nacionalidad
    if (!values.nacionalidad) {
      errors.nacionalidad = 'Obligatorio';
      setErrorPart1(true);
    }

    // Validamos el número de la seguridad social (no obligatorio)
    if (values.nss.toString().length > 0 && values.nss.toString().length !== 12) {
      errors.nss = 'Longitud incorrecta';
      setErrorPart1(true);
    }

    // Validamos el centro
    if (values.centro === '') {
      values.centro = formationCenter[0].Nombre;
      setErrorPart1(true);
    }
    if (values.centro === '-1') {
      errors.centro = 'Obligatorio';
      setErrorPart2(true);
    }

    // Validamos el sexo
    if (values.sexo === '0') {
      errors.sexo = 'Obligatorio';
      setErrorPart1(true);
    }

    // Validamos el email personal
    if (!values.emailPers) {
      errors.emailPers = 'Obligatorio';
      setErrorPart1(true);
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emailPers)) {
      errors.emailPers = 'Email incorrecto';
      setErrorPart1(true);
    }

    // Validamos el teléfono (no obligatorio)
    if (values.telefono.length > 0 && !/^\d{9}$/i.test(values.telefono)) {
      errors.telefono = 'Teléfono incorrecto';
      setErrorPart1(true);
    }

    // Validamos el móvil
    if (!values.movil) {
      errors.movil = 'Obligatorio';
      setErrorPart1(true);
    } else if (!/^\d{9}$/i.test(values.movil)) {
      errors.movil = 'Móvil incorrecto';
      setErrorPart1(true);
    }

    /** DATOS ESCOLARES */

    // Validamos el idioma
    if (values.idioma === '') {
      errors.idioma = 'Obligatorio';
      setErrorPart2(true);
    }

    // Validamos el sede examen
    if (!values.sede) {
      errors.sede = 'Obligatorio';
      setErrorPart2(true);
    }

    // if(values.formacionAcademica === '-1'){
    //   errors.formacionAcademica = 'Obligatorio';
    //   setErrorPart2(true);
    // }
    // if (!values.antiguoCentro){
    //   errors.antiguoCentro = 'Obligatorio';
    //   setErrorPart2(true);
    // }
    // if (!values.antiguosEstudios){
    //   errors.antiguosEstudios = 'Obligatorio';
    //   setErrorPart2(true);
    // }

    // Validamos la nota media (no obligatorio)
    if (values.notaMedia.toString().length > 0 && !/^\d*\.?\d+$/i.test(values.notaMedia)) {
      errors.notaMedia = 'Debe ser un valor decimal separado por un punto';
      setErrorPart2(true);
    }

    // Validamos los estudios siguientes
    if (!values.siguientesEstudios) {
      errors.siguientesEstudios = 'Obligatorio';
      setErrorPart2(true);
    }

    // Validamos la forma de acceso
    if (values.formaAcceso === '-1') {
      errors.formaAcceso = 'Obligatorio';
      setErrorPart2(true);
    }

    // if(values.fechaUltimoCurso === ''){
    //   errors.fechaUltimoCurso = 'Obligatorio';
    //   setErrorPart2(true);
    // }
    // if (!values.fechaTitulo){
    //   errors.fechaTitulo = 'Obligatorio';
    //   setErrorPart2(true);
    // }

    /**
     * TUTORES
     * PRIMER TUTOR
     */

    // Validación del nombre del primer tutor
    if (!values.nombreTutor && age < 18) {
      errors.nombreTutor = 'Obligatorio';
      setErrorPart3(true);
    } else if (!/^(?![\s.]+$)[a-zA-ZÀ-ÿ\s.]*$/i.test(values.nombreTutor.trim())) {
      errors.nombreTutor = 'Nombre incorrecto';
      setErrorPart3(true);
    }

    // Validación de los apellidos del primer tutor
    if (!values.apellido1Tutor && age < 18) {
      errors.apellido1Tutor = 'Obligatorio';
      setErrorPart3(true);
    } else if (!/^(?![\s.]+$)[a-zA-ZÀ-ÿ\s.]*$/i.test(values.apellido1Tutor.trim())) {
      errors.apellido1Tutor = 'Apellido incorrecto';
      setErrorPart3(true);
    }

    if (!values.apellido2Tutor && age < 18) {
      errors.apellido2Tutor = 'Obligatorio';
      setErrorPart3(true);
    } else if (!/^(?![\s.]+$)[a-zA-ZÀ-ÿ\s.]*$/i.test(values.apellido2Tutor.trim())) {
      errors.apellido2Tutor = 'Apellido incorrecto';
      setErrorPart3(true);
    }

    // Validación del teléfono del primer tutor (no obligatorio)
    if (
      !/^9\d{8}$/i.test(values.telefonoTutor.trim()) &&
      values.telefonoTutor.toString().length > 1
    ) {
      errors.telefonoTutor = 'Teléfono incorrecto';
      setErrorPart3(true);
    }

    // Validación del movil del primer tutor
    if (!values.movilTutor && age < 18) {
      errors.movilTutor = 'Obligatorio';
      setErrorPart3(true);
    } else if (
      !/^[6-7]\d{8}$/i.test(values.movilTutor.trim()) &&
      values.movilTutor.toString().length > 1
    ) {
      errors.movilTutor = 'Móvil incorrecto';
      setErrorPart3(true);
    }

    // Validación del correo electrónico del primer tutor
    if (!values.emailTutor && age < 18) {
      errors.emailTutor = 'Obligatorio';
      setErrorPart3(true);
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emailTutor) &&
      values.emailTutor.toString().length > 1
    ) {
      errors.emailTutor = 'Email incorrecto';
      setErrorPart3(true);
    }

    // Validación del tipo de documento del primer tutor
    if (values.tipoDocumentoTutor === '-1' && age < 18) {
      errors.tipoDocumentoTutor = 'Obligatorio';
      setErrorPart3(true);
    }

    // Validación del documento del primer tutor
    if (!values.documentoTutor && age < 18) {
      errors.documentoTutor = 'Obligatorio';
      setErrorPart3(true);
    } else if (values.tipoDocumentoTutor === '0') {
      if (
        validarDNI(values.documentoTutor) === false &&
        values.documentoTutor.toString().length > 0
      ) {
        errors.documentoTutor = 'Formato incorrecto';
        setErrorPart3(true);
      }
    } else if (values.tipoDocumentoTutor === '1') {
      if (
        validarNIE(values.documentoTutor) === false &&
        values.documentoTutor.toString().length > 0
      ) {
        errors.documentoTutor = 'Formato incorrecto';
        setErrorPart3(true);
      }
    } else if (values.tipoDocumentoTutor === '3') {
      if (
        validarCIF(values.documentoTutor) === false &&
        values.documentoTutor.toString().length > 0
      ) {
        errors.documentoTutor = 'Formato incorrecto';
        setErrorPart3(true);
      }
    }

    // Validación del parentesco del primer tutor
    if (values.parentescoTutor === '0' && age < 18) {
      errors.parentescoTutor = 'Obligatorio';
      setErrorPart3(true);
    }

    /**
     * TUTORES
     * SEGUNDO TUTOR
     */

    // Validación del nombre del segundo tutor (no obligatorio)
    if (!/^(?![\s.]+$)[a-zA-ZÀ-ÿ\s.]*$/i.test(values.nombreTutor2.trim())) {
      errors.nombreTutor2 = 'Nombre incorrecto';
      setErrorPart4(true);
    }

    // Validación de los apellidos del segundo tutor (no obligatorio)
    if (!/^(?![\s.]+$)[a-zA-ZÀ-ÿ\s.]*$/i.test(values.apellido1Tutor2.trim())) {
      errors.apellido1Tutor2 = 'Apellido incorrecto';
      setErrorPart4(true);
    }
    if (!/^(?![\s.]+$)[a-zA-ZÀ-ÿ\s.]*$/i.test(values.apellido2Tutor2.trim())) {
      errors.apellido2Tutor2 = 'Apellido incorrecto';
      setErrorPart4(true);
    }

    // Validación del teléfono del segundo tutor (no obligatorio)
    if (
      !/^9\d{8}$/i.test(values.telefonoTutor2.trim()) &&
      values.telefonoTutor2.toString().length > 1
    ) {
      errors.telefonoTutor2 = 'Teléfono incorrecto';
      setErrorPart4(true);
    }

    // Validación del movil del segundo tutor (no obligatorio)
    if (
      !/^[6-7]\d{8}$/i.test(values.movilTutor2.trim()) &&
      values.movilTutor2.toString().length > 1
    ) {
      errors.movilTutor2 = 'Móvil incorrecto';
      setErrorPart4(true);
    }

    // Validación del correo electrónico del segundo tutor (no obligatorio)
    if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emailTutor2) &&
      values.emailTutor2.toString().length > 1
    ) {
      errors.emailTutor2 = 'Email incorrecto';
      setErrorPart4(true);
    }

    // Validación del tipo de documento del segundo tutor (no obligatorio)
    if (values.tipoDocumentoTutor2 === '0') {
      if (
        validarDNI(values.documentoTutor2) === false &&
        values.documentoTutor2.toString().length > 0
      ) {
        errors.documentoTutor2 = 'Formato incorrecto';
        setErrorPart4(true);
      }
    } else if (values.tipoDocumentoTutor2 === '1') {
      if (
        validarNIE(values.documentoTutor2) === false &&
        values.documentoTutor2.toString().length > 0
      ) {
        errors.documentoTutor2 = 'Formato incorrecto';
        setErrorPart4(true);
      }
    } else if (values.tipoDocumentoTutor2 === '3') {
      if (
        validarCIF(values.documentoTutor2) === false &&
        values.documentoTutor2.toString().length > 0
      ) {
        errors.documentoTutor2 = 'Formato incorrecto';
        setErrorPart4(true);
      }
    }

    // Validación del parentesco del segundo tutor
    if (values.parentescoTutor2 === '0' && values.nombreTutor2 !== '') {
      errors.parentescoTutor2 = 'Obligatorio';
      setErrorPart3(true);
    }

    // Observe los cambios en los errores y desplácese al primer campo con error
    if (errorPart4 && rowRef4.current) {
      rowRef4.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
    }
    if (errorPart3 && rowRef3.current) {
      rowRef3.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
    }
    if (errorPart2 && rowRef2.current) {
      rowRef2.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
    }
    if (errorPart1 && rowRef1.current) {
      rowRef1.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
    }

    // Devolvemos los errores
    return errors;
  };

  // Variable de Formik
  const formik = useFormik({
    initialValues: {
      nombre: '',
      apellidos: '',
      tipoDocumento: '-1',
      documento: '',
      fechaNacimiento: '',
      pais: 'España',
      provincia: '',
      municipio: '',
      cp: '',
      domicilio: '',
      paisNacimiento: 'España',
      provinciaNacimiento: '',
      municipioNacimiento: '',
      nacionalidad: '',
      nss: '',
      centro: '-1',
      sexo: '0',
      emailPers: '',
      telefono: '',
      movil: '',
      idioma: 'Español',
      idalu: '',
      sede: '',
      nee: '',
      tsi: '',
      situacionLaboral: '',
      formacionAcademica: '-1',
      antiguoCentro: '',
      antiguosEstudios: '',
      notaMedia: 0,
      siguientesEstudios: '',
      formaAcceso: '-1',
      emailCorp: '',
      fechaUltimoCurso: '',
      fechaTitulo: '',
      ultimaMatricula: '',
      nombreTutor: '',
      apellido1Tutor: '',
      apellido2Tutor: '',
      telefonoTutor: '',
      movilTutor: '',
      emailTutor: '',
      tipoDocumentoTutor: '-1',
      documentoTutor: '',
      parentescoTutor: '0',
      nombreTutor2: '',
      apellido1Tutor2: '',
      apellido2Tutor2: '',
      telefonoTutor2: '',
      movilTutor2: '',
      emailTutor2: '',
      tipoDocumentoTutor2: '-1',
      documentoTutor2: '',
      parentescoTutor2: '0',
      conocer: '0',
      dndconocer: '',
      userEmail: JSON.parse(localStorage.getItem('user')).profileObj.email,
    },
    validateOnChange: false,
    validate,
    onSubmit: async (values) => {
      try {
        const dataCreacionUsuario = await alumnosApiService.crearUsuario(values, {
          empresaId: props.empresaId,
          headquarter: props.empresaId,
        });

        console.log(dataCreacionUsuario.response, dataCreacionUsuario, 'hola');
        setModal(true);
        if (dataCreacionUsuario.exists !== undefined && dataCreacionUsuario.exists === true) {
          setText('Este alumno ya existe, revise el documento.');
          setStay(true);
        } else {
          const ccodcli = dataCreacionUsuario.response.split(':');

          const v = {
            id: ccodcli[1],
            nombre: values.nombre,
            apellidos: values.apellidos,
            empresaId: props.empresaId,
          };

          localStorage.setItem('student', JSON.stringify(v));
          setNewId(ccodcli[1]);
          setText('Alumno creado.');
          setStay(false);

          await phpApiService.createDriveFolder({
            db: props.empresaId,
            nombre: values.nombre,
            apellidos: values.apellidos,
            dni: values.documento,
            idCentro: values.centro,
          });

          let empresa = 'cedesca';
          if (props.empresaId === 'CNTEC' || props.empresaId === '0' || props.empresaId === 0) {
            empresa = 'cntec';
          }
          navigate(`/alumnos/${empresa}/${ccodcli[1]}/detalles`);
        }
      } catch (error) {
        setModal(true);
        setText('Ha ocurrido un problema al crear el usuario.');
        setStay(true);
      }
    },
  });

  // Peticiones API
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const dataAEscolares = await alumnosApiService.cargarAEscolares({
          empresaId: props.empresaId,
          headquarter: props.empresaId,
        });
        const arrayFiltrado = dataAEscolares;
        const nuevoObjeto = { Id: '-1', Valor: '', Valor2: '' };
        arrayFiltrado.unshift(nuevoObjeto);
        setYearsApi(arrayFiltrado);
      } catch (error) {
        setYearsApi('');
      }

      try {
        const dataEstudios = await alumnosApiService.cargarEstudios({
          empresaId: props.empresaId,
          procedence: '1',
        });
        setStudiesList([
          { Id_Estudio: 0, Estudio: '', Estudio_cast: '', Codigo_Estudios: 0 },
          ...dataEstudios,
        ]);
      } catch (error) {
        setStudiesList([]);
      }

      try {
        const dataEstudios2 = await alumnosApiService.cargarEstudios({
          empresaId: props.empresaId,
          procedence: '0',
        });
        setStudiesList2([
          { Id_Estudio: 0, Estudio: '', Estudio_cast: '', Codigo_Estudios: 0 },
          ...dataEstudios2,
        ]);
      } catch (error) {
        setStudiesList2([]);
      }

      try {
        const dataFidelizacion = await matriculacionApiService.cargarFidelizacion({
          empresaId: props.empresaId,
          procedence: '0',
        });
        setFidelitationList(dataFidelizacion);
      } catch (error) {
        setFidelitationList([]);
      }

      try {
        const dataUltimosEstudios = await alumnosApiService.cargarUltimosEstudios({
          empresaId: props.empresaId,
        });
        const arrayFiltrado = dataUltimosEstudios;
        const nuevoObjeto = { Id: '-1', name: '' };
        arrayFiltrado.unshift(nuevoObjeto);
        setLastStudiesList(dataUltimosEstudios);
      } catch (error) {
        setLastStudiesList('');
      }

      try {
        const dataCentros = await alumnosApiService.cargarCentrosFormacion({
          empresaId: props.empresaId,
        });
        const arrayFiltrado = dataCentros;
        const nuevoObjeto = { Id: '-1', nombre: '' };
        arrayFiltrado.unshift(nuevoObjeto);
        setFormationCenter(arrayFiltrado);
      } catch (error) {
        setFormationCenter([]);
      }

      try {
        const dataFormaciones = await alumnosApiService.cargarFormacionesAcademicas({
          empresaId: props.empresaId,
        });
        const arrayFiltrado = dataFormaciones;
        const nuevoObjeto = { Id: '-1', Nombre: '' };
        arrayFiltrado.unshift(nuevoObjeto);
        setAcademicFormation(arrayFiltrado);
      } catch (error) {
        setAcademicFormation('');
      }

      try {
        const dataPaises = await alumnosApiService.cargarPaises({ empresaId: '1' });
        setCountry(dataPaises);
      } catch (error) {
        setCountry('');
      }

      try {
        const dataProvincia = await alumnosApiService.cargarProvincia({ empresaId: '1' });
        setProvince(dataProvincia);
      } catch (error) {
        setProvince('');
      }

      try {
        const dataPoblaciones = await alumnosApiService.cargarPoblaciones({
          empresaId: props.empresaId,
        });
        const arrayFiltrado = dataPoblaciones.filter((objeto) => objeto.provincia === '');
        setPoblationFiltered(arrayFiltrado);
        setPoblation(dataPoblaciones);
        setPoblationBirthFiltered(arrayFiltrado);
      } catch (error) {
        setPoblationFiltered('');
        setPoblation('');
        setPoblationBirthFiltered('');
      }

      try {
        const dataCps = await alumnosApiService.cargarCp({ empresaId: props.empresaId });
        const arrayFiltrado = dataCps.filter((objeto) => objeto.nom_municipi === '');
        setCPFiltered(arrayFiltrado);
        setCP(dataCps);
      } catch (error) {
        setCPFiltered('');
        setCP('');
      }

      try {
        const dataDocumentos = await alumnosApiService.cargarDocumentos({
          empresaId: props.empresaId,
        });
        const arrayFiltrado = dataDocumentos;
        setDocument(arrayFiltrado);
      } catch (error) {
        setDocument('');
      }

      try {
        const dataParentescos = await alumnosApiService.cargarParentescos({
          headquarter: props.empresaId,
        });
        const arrayFiltrado = dataParentescos;
        setParentescoList(arrayFiltrado);
      } catch (error) {
        setParentescoList([]);
      }

      try {
        const dataSituacionLaboral = await alumnosApiService.cargarSituacionLaboral({
          empresaId: props.empresaId,
        });
        setSitLaboralList(dataSituacionLaboral);
      } catch (error) {
        setSitLaboralList([]);
      }
    };

    fetchOptions();

    /* eslint-disable no-restricted-globals */
    window.scrollTo(0, 0);
    history.scrollRestoration = 'manual';
    /* eslint-enable no-restricted-globals */
  }, []);

  // Efecto para setear centro por defecto
  useEffect(() => {
    // Si el rol es de delegación, asignamos valor por defecto
    if (formationCenter.length > 0 && idCedesca > 0 && idCntec > 0) {
      const idCentroFiltrado = formationCenter.filter((center) => center.Nombre === centro)[0].Id;
      formik.setValues((prev) => ({ ...prev, centro: idCentroFiltrado, sede: centro }));
    }
  }, [formationCenter]);

  const handleFechaTitulos = (value) => {
    // Seteamos el valor a formik
    formik.setValues((prev) => ({ ...prev, fechaTitulo: value }));
  };

  const handleFechaNacimiento = (value) => {
    // Almacenamos la fecha en formik
    formik.setValues((prev) => ({ ...prev, fechaNacimiento: value }));

    // Convierte la cadena de fecha de nacimiento a un objeto Date
    const fechaNac = new Date(value);

    // Obtiene la fecha actual
    const fechaActual = new Date();

    // Calcula la diferencia en milisegundos entre la fecha actual y la fecha de nacimiento
    const diferencia = fechaActual - fechaNac;

    // Convierte la diferencia de milisegundos a años
    const edadEnAnios = Math.floor(diferencia / (1000 * 60 * 60 * 24 * 365.25));

    setAge(edadEnAnios);
  };

  const handleBirthProvinceChange = (event) => {
    formik.values.provinciaNacimiento = event.target.value;
    const arrayFiltrado = poblation.filter((objeto) => objeto.provincia === event.target.value);
    const nuevoObjeto = { nom_municipi: '', provincia: event.target.value };
    arrayFiltrado.unshift(nuevoObjeto);
    formik.values.municipioNacimiento = arrayFiltrado[0].nom_municipi;
    setPoblationBirthFiltered(arrayFiltrado);
  };

  const handleProvinceChange = (event) => {
    formik.values.provincia = event.target.value;
    const arrayFiltrado = poblation.filter((objeto) => objeto.provincia === event.target.value);
    const nuevoObjeto = { nom_municipi: '', provincia: event.target.value };
    arrayFiltrado.unshift(nuevoObjeto);
    formik.values.poblacion = arrayFiltrado[0].nom_municipi;
    setPoblationFiltered(arrayFiltrado);
  };

  const handlePoblationChange = (event) => {
    formik.values.municipio = event.target.value;
    const arrayFiltrado = cp.filter((objeto) => objeto.nom_municipi === formik.values.municipio);
    setCPFiltered(arrayFiltrado);
    formik.values.cp = arrayFiltrado[0].cp;
  };

  return (
    <>
      {modal ? (
        <ModalClickable
          header={text}
          color="primary"
          url={stay ? '' : `/alumnos/${props.empresaId}/${newId}/detalles`}
          function={setModal}
        />
      ) : (
        ''
      )}
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <ComponentCard title="Datos personales" hasButton>
          <Row>
            <Col md="2">
              <Label htmlFor="nombre">
                <small ref={rowRef1}>
                  Nombre <span className="text-danger">*</span>
                </small>
              </Label>
              <Input
                id="nombre"
                name="nombre"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.nombre}
                className={formik.errors.nombre ? 'border border-danger error' : ''}
              />
              {formik.errors.nombre ? (
                <div className="text-danger tiny">{formik.errors.nombre}</div>
              ) : null}
            </Col>
            <Col md="4">
              <Label htmlFor="apellidos">
                <small>
                  Apellidos <span className="text-danger">*</span>
                </small>
              </Label>
              <Input
                id="apellidos"
                name="apellidos"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.apellidos}
                className={formik.errors.apellidos ? 'border border-danger error' : ''}
              />
              {formik.errors.apellidos ? (
                <div className="text-danger tiny">{formik.errors.apellidos}</div>
              ) : null}
            </Col>
            <Col md="2">
              <Label htmlFor="tipoDocumento">
                <small>
                  Tipo de documento <span className="text-danger">*</span>
                </small>
              </Label>
              <Input
                id="tipoDocumento"
                name="tipoDocumento"
                type="select"
                onChange={formik.handleChange}
                value={formik.values.tipoDocumento}
                className={formik.errors.tipoDocumento ? 'border border-danger error' : ''}
              >
                {document !== ''
                  ? document.map((option) => (
                      <option key={`${option.id}-documento`} value={option.id}>
                        {option.nombre}
                      </option>
                    ))
                  : ''}
              </Input>
              {formik.errors.tipoDocumento ? (
                <div className="text-danger tiny">{formik.errors.tipoDocumento}</div>
              ) : null}
            </Col>
            <Col md="2">
              <Label htmlFor="documento">
                <small>
                  Documento <span className="text-danger">*</span>
                </small>
              </Label>
              <Input
                id="documento"
                name="documento"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.documento}
                className={formik.errors.documento ? 'border border-danger error' : ''}
                disabled={formik.values.tipoDocumento === '-1'}
              />
              {formik.errors.documento ? (
                <div className="text-danger tiny">{formik.errors.documento}</div>
              ) : null}
            </Col>
            <Col md="2">
              <Label htmlFor="fechaNacimiento">
                <small>
                  Fecha nacimiento <span className="text-danger">*</span>
                </small>
              </Label>
              <Datetime
                utc
                timeFormat={false}
                isValidDate={disableFutureDates}
                onChange={handleFechaNacimiento}
                className={
                  formik.errors.fechaNacimiento ? 'border border-danger error rounded ' : ''
                }
              />
              {age > 0 && age < 18 && (
                <span className="text-warning">
                  <b>Es alumno menor</b>
                </span>
              )}

              {formik.errors.fechaNacimiento ? (
                <div className="text-danger tiny">{formik.errors.fechaNacimiento}</div>
              ) : null}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md="2">
              <Label htmlFor="pais">
                <small>
                  País de residencia <span className="text-danger">*</span>
                </small>
              </Label>
              <Input
                id="pais"
                name="pais"
                type="select"
                onChange={formik.handleChange}
                value={formik.values.pais}
                className={formik.errors.pais ? 'border border-danger error' : ''}
              >
                {country !== ''
                  ? country.map((option) => (
                      <option key={option.idcodi_pais} value={option.nom}>
                        {option.nom}
                      </option>
                    ))
                  : ''}
              </Input>
              {formik.errors.pais ? (
                <div className="text-danger tiny">{formik.errors.pais}</div>
              ) : null}
            </Col>
            <Col md="2">
              <Label htmlFor="provincia">
                <small>
                  Provincia de residencia{' '}
                  {formik.values.pais !== 'España' ? '' : <span className="text-danger">*</span>}
                </small>
              </Label>
              <Input
                id="provincia"
                name="provincia"
                type="select"
                disabled={formik.values.pais !== 'España'}
                onChange={handleProvinceChange}
                value={formik.values.provincia}
                className={formik.errors.provincia ? 'border border-danger error' : ''}
              >
                {province !== ''
                  ? province.map((option) => (
                      <option key={option.provincia} value={option.provincia}>
                        {option.provincia}
                      </option>
                    ))
                  : ''}
              </Input>
              {formik.errors.provincia ? (
                <div className="text-danger tiny">{formik.errors.provincia}</div>
              ) : null}
            </Col>
            <Col md="3">
              <Label htmlFor="municipio">
                <small>
                  Municipio de residencia{' '}
                  {formik.values.pais !== 'España' ? '' : <span className="text-danger">*</span>}
                </small>
              </Label>
              <Input
                id="municipio"
                name="municipio"
                type="select"
                disabled={formik.values.pais !== 'España'}
                onChange={handlePoblationChange}
                value={formik.values.municipio}
                className={formik.errors.municipio ? 'border border-danger error' : ''}
              >
                {poblationFiltered !== ''
                  ? poblationFiltered.map((option) => (
                      <option key={option.nom_municipi} value={option.nom_municipi}>
                        {option.nom_municipi}
                      </option>
                    ))
                  : ''}
              </Input>
              {formik.errors.municipio ? (
                <div className="text-danger tiny">{formik.errors.municipio}</div>
              ) : null}
            </Col>
            <Col md="2">
              <Label htmlFor="cp">
                <small>
                  Código postal{' '}
                  {formik.values.pais !== 'España' ? '' : <span className="text-danger">*</span>}
                </small>
              </Label>
              <Input
                id="cp"
                name="cp"
                type="select"
                disabled={formik.values.pais !== 'España'}
                onChange={formik.handleChange}
                value={formik.values.cp}
                className={formik.errors.cp ? 'border border-danger error' : ''}
              >
                {cpFiltered !== ''
                  ? cpFiltered.map((option) => (
                      <option key={`${option.cp}${option.nom_municipi}`} value={option.cp}>
                        {option.cp}
                      </option>
                    ))
                  : ''}
              </Input>
              {formik.errors.cp ? <div className="text-danger tiny">{formik.errors.cp}</div> : null}
            </Col>
            <Col md="3">
              <Label htmlFor="domicilio">
                <small>
                  Domicilio <span className="text-danger">*</span>
                </small>
              </Label>
              <Input
                id="domicilio"
                name="domicilio"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.domicilio}
                className={formik.errors.domicilio ? 'border border-danger error' : ''}
              />
              {formik.errors.domicilio ? (
                <div className="text-danger tiny">{formik.errors.domicilio}</div>
              ) : null}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md="2">
              <Label htmlFor="paisNacimiento">
                <small>
                  País de nacimiento <span className="text-danger">*</span>
                </small>
              </Label>
              <Input
                id="paisNacimiento"
                name="paisNacimiento"
                type="select"
                onChange={formik.handleChange}
                value={formik.values.paisNacimiento}
                className={formik.errors.paisNacimiento ? 'border border-danger error' : ''}
              >
                {country !== ''
                  ? country.map((option) => (
                      <option key={`${option.idcodi_pais}-nac`} value={option.nom}>
                        {option.nom}
                      </option>
                    ))
                  : ''}
              </Input>
              {formik.errors.paisNacimiento ? (
                <div className="text-danger tiny">{formik.errors.paisNacimiento}</div>
              ) : null}
            </Col>
            <Col md="2">
              <Label htmlFor="provinciaNacimiento">
                <small>
                  Provincia de nacimiento{' '}
                  {formik.values.paisNacimiento !== 'España' ? (
                    ''
                  ) : (
                    <span className="text-danger">*</span>
                  )}
                </small>
              </Label>
              <Input
                id="provinciaNacimiento"
                name="provinciaNacimiento"
                type="select"
                onChange={handleBirthProvinceChange}
                value={formik.values.provinciaNacimiento}
                className={formik.errors.provinciaNacimiento ? 'border border-danger error' : ''}
                disabled={formik.values.paisNacimiento !== 'España' ? 'disabled' : ''}
              >
                {province !== ''
                  ? province.map((option) => (
                      <option key={`${option.provincia}-nac`} value={option.provincia}>
                        {option.provincia}
                      </option>
                    ))
                  : ''}
              </Input>
              {formik.errors.provinciaNacimiento ? (
                <div className="text-danger tiny">{formik.errors.provinciaNacimiento}</div>
              ) : null}
            </Col>
            <Col md="3">
              <Label htmlFor="municipioNacimiento">
                <small>
                  Municipio de nacimiento{' '}
                  {formik.values.paisNacimiento !== 'España' ? (
                    ''
                  ) : (
                    <span className="text-danger">*</span>
                  )}
                </small>
              </Label>
              <Input
                id="municipioNacimiento"
                name="municipioNacimiento"
                type="select"
                onChange={formik.handleChange}
                value={formik.values.municipioNacimiento}
                className={formik.errors.municipioNacimiento ? 'border border-danger error' : ''}
                disabled={formik.values.paisNacimiento !== 'España' ? 'disabled' : ''}
              >
                {poblationBirthFiltered !== ''
                  ? poblationBirthFiltered.map((option) => (
                      <option key={`${option.nom_municipi}-nac`} value={option.nom_municipi}>
                        {option.nom_municipi}
                      </option>
                    ))
                  : ''}
              </Input>
              {formik.errors.municipioNacimiento ? (
                <div className="text-danger tiny">{formik.errors.municipioNacimiento}</div>
              ) : null}
            </Col>
            <Col md="2">
              <Label htmlFor="nacionalidad">
                <small>
                  Nacionalidad <span className="text-danger">*</span>
                </small>
              </Label>
              <Input
                id="nacionalidad"
                name="nacionalidad"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.nacionalidad}
                className={formik.errors.nacionalidad ? 'border border-danger error' : ''}
              />
              {formik.errors.nacionalidad ? (
                <div className="text-danger tiny">{formik.errors.nacionalidad}</div>
              ) : null}
            </Col>
            <Col md="3">
              <Label htmlFor="nss">
                <small>Núm. Seguridad Social</small>
              </Label>
              <Input
                id="nss"
                name="nss"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.nss}
                className={formik.errors.nss ? 'border border-danger error' : ''}
              />
              {formik.errors.nss ? (
                <div className="text-danger tiny">{formik.errors.nss}</div>
              ) : null}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md="2">
              <Label htmlFor="centro">
                <small>
                  Centro <span className="text-danger">*</span>
                </small>
              </Label>
              <Input
                id="centro"
                name="centro"
                type="select"
                onChange={formik.handleChange}
                className={formik.errors.centro ? 'border border-danger error' : ''}
                disabled={idCedesca > 0 && idCntec > 0}
                value={formik.values.centro}
              >
                {formationCenter !== ''
                  ? formationCenter.map((option) => (
                      <option key={`${option.Id}-centro`} value={option.Id}>
                        {option.Nombre}
                      </option>
                    ))
                  : ''}
              </Input>
              {formik.errors.centro ? (
                <div className="text-danger tiny">{formik.errors.centro}</div>
              ) : null}
            </Col>
            <Col md="2">
              <Label htmlFor="sexo">
                <small>
                  Sexo <span className="text-danger">*</span>
                </small>
              </Label>
              <Input
                id="sexo"
                name="sexo"
                type="select"
                onChange={formik.handleChange}
                value={formik.values.sexo}
                className={formik.errors.sexo ? 'border border-danger error rounded ' : ''}
              >
                <option key="0-sexo" value="0"></option>
                <option key="1-sexo" value="1">
                  Hombre
                </option>
                <option key="2-sexo" value="2">
                  Mujer
                </option>
                <option key="3-sexo" value="3">
                  No binario
                </option>
              </Input>
              {formik.errors.sexo ? (
                <div className="text-danger tiny">{formik.errors.sexo}</div>
              ) : null}
            </Col>
            <Col md="2">
              <Label htmlFor="emailPers">
                <small>
                  Email <span className="text-danger">*</span>
                </small>
              </Label>
              <Input
                id="emailPers"
                name="emailPers"
                type="email"
                onChange={formik.handleChange}
                value={formik.values.emailPers}
                className={formik.errors.emailPers ? 'border border-danger error' : ''}
              />
              {formik.errors.emailPers ? (
                <div className="text-danger tiny">{formik.errors.emailPers}</div>
              ) : null}
            </Col>
            <Col md="2">
              <Label htmlFor="telefono">
                <small>Teléfono</small>
              </Label>
              <Input
                id="telefono"
                name="telefono"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.telefono}
              />
            </Col>
            <Col md="2">
              <Label htmlFor="movil">
                <small>
                  Móvil <span className="text-danger">*</span>
                </small>
              </Label>
              <Input
                id="movil"
                name="movil"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.movil}
                className={formik.errors.movil ? 'border border-danger error' : ''}
              />
              {formik.errors.movil ? (
                <div className="text-danger tiny">{formik.errors.movil}</div>
              ) : null}
            </Col>
            <Col md="2">
              <Label htmlFor="conocer">
                <small>
                  ¿Cómo nos has conocido? <span className="text-danger">*</span>
                </small>
              </Label>
              <Input
                id="conocer"
                name="conocer"
                type="select"
                onChange={formik.handleChange}
                value={formik.values.conocer}
                className={formik.errors.conocer ? 'border border-danger error' : ''}
              >
                {fidelitationlist !== ''
                  ? fidelitationlist.map((option) => (
                      <option key={`${option.id}`} value={option.nom}>
                        {option.nom}
                      </option>
                    ))
                  : ''}
              </Input>
              {formik.errors.conocer ? (
                <div className="text-danger tiny">{formik.errors.conocer}</div>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col className="mt-2" md="2">
              <Label htmlFor="dndconocer">
                <small>¿Dónde nos has conocido?</small>
              </Label>
              <Input
                id="dndconocer"
                name="dndconocer"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.dndconocer}
                className={formik.errors.dndconocer ? 'border border-danger error' : ''}
              />
            </Col>
          </Row>
        </ComponentCard>
        <ComponentCard title="Datos escolares" hasButton>
          <Row>
            <Col md="2">
              <Label htmlFor="idioma">
                <small ref={rowRef2}>
                  Idioma <span className="text-danger">*</span>
                </small>
              </Label>
              <Input
                id="idioma"
                name="idioma"
                type="select"
                onChange={formik.handleChange}
                value={formik.values.idioma}
                className={formik.errors.idioma ? 'border border-danger error rounded ' : ''}
              >
                <option key="0-idioma" value=""></option>
                <option key="1-idioma" value="Español">
                  Español
                </option>
                <option key="2-idioma" value="Catalán">
                  Catalán
                </option>
              </Input>
              {formik.errors.idioma ? (
                <div className="text-danger tiny">{formik.errors.idioma}</div>
              ) : null}
            </Col>
            <Col md="2">
              <Label htmlFor="idalu">
                <small>IDALU</small>
              </Label>
              <Input
                id="idalu"
                name="idalu"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.idalu}
              />
            </Col>
            <Col md="3">
              <Label htmlFor="sede">
                <small>
                  Sede examen <span className="text-danger">*</span>
                </small>
              </Label>
              <Input
                id="sede"
                name="sede"
                type="select"
                onChange={formik.handleChange}
                value={formik.values.sede}
                disabled={
                  idCedesca > 0 &&
                  idCntec > 0 &&
                  ((formik.values.centro.toString() !== '9' && props.empresaId === 'CNTEC') ||
                    (formik.values.centro.toString() !== '7' && props.empresaId === 'CEDESCA'))
                }
                className={formik.errors.sede ? 'border border-danger error rounded' : ''}
              >
                {formationCenter !== ''
                  ? (formik.values.centro.toString() === '9' && props.empresaId === 'CNTEC') ||
                    (formik.values.centro.toString() === '7' && props.empresaId === 'CEDESCA')
                    ? formationCenter
                        .filter(
                          (entry) => entry.Nombre === 'CAMP JOLIU' || entry.Nombre === 'CEDESCA',
                        )
                        .map((option) => (
                          <option key={`${option.Id}-sedeExamen`} value={option.Nombre}>
                            {option.Nombre}
                          </option>
                        ))
                    : formationCenter.map((option) => (
                        <option key={`${option.Id}-sedeExamen`} value={option.Nombre}>
                          {option.Nombre}
                        </option>
                      ))
                  : null}
              </Input>
              {formik.errors.sede ? (
                <div className="text-danger tiny">{formik.errors.sede}</div>
              ) : null}
            </Col>
            <Col md="3">
              <Label htmlFor="nee">
                <small>NEE</small>
              </Label>
              <Input
                id="nee"
                name="nee"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.nee}
              />
            </Col>
            <Col md="2">
              <Label htmlFor="tsi">
                <small>TSI </small>
              </Label>
              <Input
                id="tsi"
                name="tsi"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.tsi}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md="2">
              <Label htmlFor="situacionLaboral">
                <small>Situación laboral</small>
              </Label>
              <Input
                id="situacionLaboral"
                name="situacionLaboral"
                type="select"
                onChange={formik.handleChange}
                value={formik.values.situacionLaboral}
              >
                <option key="0-sitLaboral" value=""></option>
                {sitLaboralList.map((item, index) => (
                  <option key={`${index}-sitLaboral`} value={item.Valor_cast}>
                    {item.Valor_cast}
                  </option>
                ))}
              </Input>
            </Col>
            {/* <Col md="2">
              <Label htmlFor="situacionLaboral"><small>Situación laboral</small></Label>
              <Input
                id="situacionLaboral"
                name="situacionLaboral"
                type="text"
                onChange={ formik.handleChange }
                value={ formik.values.situacionLaboral }
              />
            </Col> */}
            <Col md="3">
              <Label htmlFor="formacionAcademica">
                <small>Curso realizado</small>
              </Label>
              <Input
                id="formacionAcademica"
                name="formacionAcademica"
                type="select"
                onChange={formik.handleChange}
                value={formik.values.formacionAcademica}
              >
                {academicFormation !== ''
                  ? academicFormation.map((option) => (
                      <option key={`${option.id}-formacionAcademica`} value={option.Valor}>
                        {option.Valor}
                      </option>
                    ))
                  : ''}
              </Input>
            </Col>
            <Col md="3">
              <Label htmlFor="antiguoCentro">
                <small>Centro de procedencia</small>
              </Label>
              <Input
                id="antiguoCentro"
                name="antiguoCentro"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.antiguoCentro}
              />
            </Col>
            <Col md="4">
              <Label htmlFor="antiguosEstudios">
                <small>Estudios de procedencia</small>
              </Label>
              <Input
                id="antiguosEstudios"
                name="antiguosEstudios"
                type="select"
                onChange={formik.handleChange}
                value={formik.values.antiguosEstudios}
              >
                {studiesList !== ''
                  ? studiesList.map((option) => (
                      <option
                        key={`${option.Id_Estudio}-estudiosProcedencia`}
                        value={option.Estudio}
                      >
                        {option.Estudio_cast}
                      </option>
                    ))
                  : ''}
              </Input>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md="2">
              <Label htmlFor="notaMedia">
                <small>Nota media</small>
              </Label>
              <Input
                id="notaMedia"
                name="notaMedia"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.notaMedia}
                className={formik.errors.notaMedia ? 'border border-danger error' : ''}
              />
              {formik.errors.notaMedia ? (
                <div className="text-danger tiny">{formik.errors.notaMedia}</div>
              ) : null}
            </Col>
            <Col md="3">
              <Label htmlFor="fechaUltimoCurso">
                <small>Fecha último curso</small>
              </Label>
              <Input
                id="fechaUltimoCurso"
                name="fechaUltimoCurso"
                type="select"
                onChange={formik.handleChange}
                value={formik.values.fechaUltimoCurso}
              >
                {yearsApi !== ''
                  ? yearsApi.map((option) => (
                      <option key={`${option.Id}-fechaUltimoCurso`} value={option.Valor}>
                        {option.Valor}
                      </option>
                    ))
                  : ''}
              </Input>
            </Col>
            <Col md="3">
              <Label htmlFor="fechaTitulo">
                <small>Fecha obtención del título</small>
              </Label>
              <Datetime
                utc
                timeFormat={false}
                isValidDate={disableFutureDates}
                onChange={handleFechaTitulos}
              />
            </Col>
            <Col md="4">
              <Label htmlFor="emailCorp">
                <small>Email corporativo</small>
              </Label>
              <Input
                id="emailCorp"
                name="emailCorp"
                type="text"
                disabled
                onChange={formik.handleChange}
                value={formik.values.emailCorp}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md="4">
              <Label htmlFor="siguientesEstudios">
                <small>
                  Estudios siguientes <span className="text-danger">*</span>
                </small>
              </Label>
              <Input
                id="siguientesEstudios"
                name="siguientesEstudios"
                type="select"
                onChange={formik.handleChange}
                value={formik.values.siguientesEstudios}
                className={formik.errors.siguientesEstudios ? 'border border-danger error' : ''}
              >
                {studiesList2 !== ''
                  ? studiesList2.map((option) => (
                      <option
                        key={`${option.Id_Estudio}-estudiosSiguientes`}
                        value={option.Estudio}
                      >
                        {option.Estudio_cast}
                      </option>
                    ))
                  : ''}
              </Input>
              {formik.errors.siguientesEstudios ? (
                <div className="text-danger tiny">{formik.errors.siguientesEstudios}</div>
              ) : null}
            </Col>
            <Col md="3">
              <Label htmlFor="formaAcceso">
                <small>
                  Forma de acceso <span className="text-danger">*</span>
                </small>
              </Label>
              <Input
                id="formaAcceso"
                name="formaAcceso"
                type="select"
                onChange={formik.handleChange}
                value={formik.values.formaAcceso}
                className={formik.errors.formaAcceso ? 'border border-danger error' : ''}
              >
                {lastStudiesList !== ''
                  ? lastStudiesList.map((option) => (
                      <option key={`${option.Id}-formaAccesso`} value={option.name}>
                        {option.name_cast}
                      </option>
                    ))
                  : ''}
              </Input>
              {formik.errors.formaAcceso ? (
                <div className="text-danger tiny">{formik.errors.formaAcceso}</div>
              ) : null}
            </Col>
          </Row>
        </ComponentCard>
        <ComponentCard title="Datos primer tutor" hasButton>
          <Row>
            <Col md="2">
              <Label htmlFor="nombreTutor">
                <small ref={rowRef3}>
                  Nombre {age < 18 ? <span className="text-danger">*</span> : ''}
                </small>
              </Label>
              <Input
                id="nombreTutor"
                name="nombreTutor"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.nombreTutor}
                className={formik.errors.nombreTutor ? 'border border-danger error' : ''}
              />
              {formik.errors.nombreTutor ? (
                <div className="text-danger tiny">{formik.errors.nombreTutor}</div>
              ) : null}
            </Col>
            <Col md="2">
              <Label htmlFor="apellido1Tutor">
                <small>
                  Primer apellido {age < 18 ? <span className="text-danger">*</span> : ''}
                </small>
              </Label>
              <Input
                id="apellido1Tutor"
                name="apellido1Tutor"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.apellido1Tutor}
                className={formik.errors.apellido1Tutor ? 'border border-danger error' : ''}
              />
              {formik.errors.apellido1Tutor ? (
                <div className="text-danger tiny">{formik.errors.apellido1Tutor}</div>
              ) : null}
            </Col>
            <Col md="2">
              <Label htmlFor="apellido2Tutor">
                <small>
                  Segundo apellido {age < 18 ? <span className="text-danger">*</span> : ''}
                </small>
              </Label>
              <Input
                id="apellido2Tutor"
                name="apellido2Tutor"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.apellido2Tutor}
                className={formik.errors.apellido2Tutor ? 'border border-danger error' : ''}
              />
              {formik.errors.apellido2Tutor ? (
                <div className="text-danger tiny">{formik.errors.apellido2Tutor}</div>
              ) : null}
            </Col>
            <Col md="2">
              <Label htmlFor="telefonoTutor">
                <small>Teléfono</small>
              </Label>
              <Input
                id="telefonoTutor"
                name="telefonoTutor"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.telefonoTutor}
              />
            </Col>
            <Col md="2">
              <Label htmlFor="movilTutor">
                <small>Móvil {age < 18 ? <span className="text-danger">*</span> : ''}</small>
              </Label>
              <Input
                id="movilTutor"
                name="movilTutor"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.movilTutor}
                className={formik.errors.movilTutor ? 'border border-danger error' : ''}
              />
              {formik.errors.movilTutor ? (
                <div className="text-danger tiny">{formik.errors.movilTutor}</div>
              ) : null}
            </Col>

            <Col md="2">
              <Label htmlFor="emailTutor">
                <small>Email {age < 18 ? <span className="text-danger">*</span> : ''}</small>
              </Label>
              <Input
                id="emailTutor"
                name="emailTutor"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.emailTutor}
                className={formik.errors.emailTutor ? 'border border-danger error' : ''}
              />
              {formik.errors.emailTutor ? (
                <div className="text-danger tiny">{formik.errors.emailTutor}</div>
              ) : null}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md="2">
              <Label htmlFor="tipoDocumentoTutor">
                <small>
                  Tipo documento {age < 18 ? <span className="text-danger">*</span> : ''}
                </small>
              </Label>
              <Input
                id="tipoDocumentoTutor"
                name="tipoDocumentoTutor"
                type="select"
                onChange={formik.handleChange}
                value={formik.values.tipoDocumentoTutor}
                className={formik.errors.tipoDocumentoTutor ? 'border border-danger error' : ''}
              >
                {document !== ''
                  ? document.map((option) => (
                      <option key={`${option.id}-tipoDocT1`} value={option.id}>
                        {option.nombre}
                      </option>
                    ))
                  : ''}
              </Input>
              {formik.errors.tipoDocumentoTutor ? (
                <div className="text-danger tiny">{formik.errors.tipoDocumentoTutor}</div>
              ) : null}
            </Col>
            <Col md="2">
              <Label htmlFor="documentoTutor">
                <small>Documento {age < 18 ? <span className="text-danger">*</span> : ''}</small>
              </Label>
              <Input
                id="documentoTutor"
                name="documentoTutor"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.documentoTutor}
                className={formik.errors.documentoTutor ? 'border border-danger error' : ''}
                disabled={formik.values.tipoDocumentoTutor === '-1'}
              />
              {formik.errors.documentoTutor ? (
                <div className="text-danger tiny">{formik.errors.documentoTutor}</div>
              ) : null}
            </Col>
            <Col md="2">
              <Label htmlFor="parentescoTutor">
                <small>Parentesco {age < 18 ? <span className="text-danger">*</span> : ''}</small>
              </Label>
              <Input
                type="select"
                name="parentescoTutor"
                id="parentescoTutor"
                onChange={formik.handleChange}
                value={formik.values.parentescoTutor}
                className={formik.errors.parentescoTutor ? 'border border-danger error' : ''}
              >
                {parentescoList !== ''
                  ? parentescoList.map((option) => (
                      <option key={`${option.id}-parentesco1`} value={option.id}>
                        {option.descripcion}
                      </option>
                    ))
                  : ''}
              </Input>
              {formik.errors.parentescoTutor ? (
                <div className="text-danger tiny">{formik.errors.parentescoTutor}</div>
              ) : null}
            </Col>
          </Row>
        </ComponentCard>
        <ComponentCard title="Datos segundo tutor" hasButton>
          <Row>
            <Col md="2">
              <Label htmlFor="nombreTutor2">
                <small ref={rowRef4}>Nombre</small>
              </Label>
              <Input
                id="nombreTutor2"
                name="nombreTutor2"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.nombreTutor2}
                className={formik.errors.nombreTutor2 ? 'border border-danger error' : ''}
              />
              {formik.errors.nombreTutor2 ? (
                <div className="text-danger tiny">{formik.errors.nombreTutor2}</div>
              ) : null}
            </Col>
            <Col md="2">
              <Label htmlFor="apellido1Tutor2">
                <small>Primer apellido</small>
              </Label>
              <Input
                id="apellido1Tutor2"
                name="apellido1Tutor2"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.apellido1Tutor2}
                className={formik.errors.apellido1Tutor2 ? 'border border-danger error' : ''}
              />
              {formik.errors.apellido1Tutor2 ? (
                <div className="text-danger tiny">{formik.errors.apellido1Tutor2}</div>
              ) : null}
            </Col>
            <Col md="2">
              <Label htmlFor="apellido2Tutor2">
                <small>Segundo apellido</small>
              </Label>
              <Input
                id="apellido2Tutor2"
                name="apellido2Tutor2"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.apellido2Tutor2}
                className={formik.errors.apellido2Tutor2 ? 'border border-danger error' : ''}
              />
              {formik.errors.apellido2Tutor2 ? (
                <div className="text-danger tiny">{formik.errors.apellido2Tutor2}</div>
              ) : null}
            </Col>
            <Col md="2">
              <Label htmlFor="telefonoTutor2">
                <small>Teléfono</small>
              </Label>
              <Input
                id="telefonoTutor2"
                name="telefonoTutor2"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.telefonoTutor2}
                className={formik.errors.telefonoTutor2 ? 'border border-danger error' : ''}
              />
              {formik.errors.telefonoTutor2 ? (
                <div className="text-danger tiny">{formik.errors.telefonoTutor2}</div>
              ) : null}
            </Col>
            <Col md="2">
              <Label htmlFor="movilTutor2">
                <small>Móvil</small>
              </Label>
              <Input
                id="movilTutor2"
                name="movilTutor2"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.movilTutor2}
                className={formik.errors.movilTutor2 ? 'border border-danger error' : ''}
              />
              {formik.errors.movilTutor2 ? (
                <div className="text-danger tiny">{formik.errors.movilTutor2}</div>
              ) : null}
            </Col>
            <Col md="2">
              <Label htmlFor="emailTutor2">
                <small>Email</small>
              </Label>
              <Input
                id="emailTutor2"
                name="emailTutor2"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.emailTutor2}
                className={formik.errors.emailTutor2 ? 'border border-danger error' : ''}
              />
              {formik.errors.emailTutor2 ? (
                <div className="text-danger tiny">{formik.errors.emailTutor2}</div>
              ) : null}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md="2">
              <Label htmlFor="tipoDocumentoTutor2">
                <small>Tipo documento</small>
              </Label>
              <Input
                id="tipoDocumentoTutor2"
                name="tipoDocumentoTutor2"
                type="select"
                onChange={formik.handleChange}
                value={formik.values.tipoDocumentoTutor2}
                className={formik.errors.tipoDocumentoTutor2 ? 'border border-danger error' : ''}
              >
                {document !== ''
                  ? document.map((option) => (
                      <option key={`${option.id}-tipoDocT2`} value={option.id}>
                        {option.nombre}
                      </option>
                    ))
                  : ''}
              </Input>
              {formik.errors.tipoDocumentoTutor2 ? (
                <div className="text-danger tiny">{formik.errors.tipoDocumentoTutor2}</div>
              ) : null}
            </Col>
            <Col md="2">
              <Label htmlFor="documentoTutor2">
                <small>Documento</small>
              </Label>
              <Input
                id="documentoTutor2"
                name="documentoTutor2"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.documentoTutor2}
                className={formik.errors.documentoTutor2 ? 'border border-danger error' : ''}
                disabled={formik.values.tipoDocumentoTutor2 === '-1'}
              />
              {formik.errors.documentoTutor2 ? (
                <div className="text-danger tiny">{formik.errors.documentoTutor2}</div>
              ) : null}
            </Col>
            <Col md="2">
              <Label htmlFor="parentescoTutor2">
                <small>
                  Parentesco{' '}
                  {formik.values.nombreTutor2 !== '' ? <span className="text-danger">*</span> : ''}
                </small>
              </Label>
              <Input
                type="select"
                name="parentescoTutor2"
                id="parentescoTutor2"
                onChange={formik.handleChange}
                value={formik.values.parentescoTutor2}
                className={formik.errors.parentescoTutor2 ? 'border border-danger error' : ''}
              >
                {parentescoList !== ''
                  ? parentescoList.map((option) => (
                      <option key={`${option.id}-parentesco2`} value={option.id}>
                        {option.descripcion}
                      </option>
                    ))
                  : ''}
              </Input>
              {formik.errors.parentescoTutor2 ? (
                <div className="text-danger tiny">{formik.errors.parentescoTutor2}</div>
              ) : null}
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm="12" className="mt-1 mt-md-0">
              <Button color="success" type="submit">
                <Icon.Save />
              </Button>
            </Col>
          </Row>
        </ComponentCard>
      </Form>
    </>
  );
};

FormularioNuevoUsuario.propTypes = {
  empresaId: PropTypes.any,
};

export default FormularioNuevoUsuario;
