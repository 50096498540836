import { useState, useEffect, useContext } from 'react';
import { Button, Col, Form, Row, TabPane } from 'reactstrap';
import { useFormik } from 'formik';
import * as Icon from 'react-feather';
import PropTypes from 'prop-types';
import Tutor from './Tutor';
import ModalOptions from '../../layouts/modals/ModalOptions';
import { validarCIF, validarDNI, validarNIE } from '../../functions/documentType';
import { AuthContext } from '../../context/authContext';
import { httpServiceInstanceNode } from '../../services/http/httpService';

const DatosTutores = ({ prevActiveTab, userId, datosTutor, menorEdad, missingDataFunction }) => {
  // Declaramos las variables necesarias
  const idStudent = JSON.parse(localStorage.getItem('student')).id;
  const { empresaId } = JSON.parse(localStorage.getItem('student'));
  const { userOU } = useContext(AuthContext);
  // Declaramos los estados
  const [isModalShown, setIsModalShown] = useState(false);
  const [raw, setRaw] = useState('');
  const [url, setUrl] = useState('');

  const validate = (values) => {
    // Preparamos variable de errores
    const errors = {};

    // Validamos el nombre
    if (menorEdad && !values.nombreTutor1) {
      errors.nombreTutor1 = 'Obligatorio';
      missingDataFunction(true);
    } else if (!/^(?![\s.]+$)[a-zA-ZÀ-ÿ\s.]*$/i.test(values.nombreTutor1.trim())) {
      errors.nombreTutor1 = 'Nombre incorrecto';
      missingDataFunction(true);
    }
    if (!/^(?![\s.]+$)[a-zA-ZÀ-ÿ\s.]*$/i.test(values.nombreTutor2.trim())) {
      errors.nombreTutor2 = 'Nombre incorrecto';
      missingDataFunction(true);
    }

    // Validamos el primer apellido
    if (menorEdad && !values.apellido1Tutor1) {
      errors.apellido1Tutor1 = 'Obligatorio';
      missingDataFunction(true);
    } else if (!/^(?![\s.]+$)[a-zA-ZÀ-ÿ\s.]*$/i.test(values.apellido1Tutor1.trim())) {
      errors.apellido1Tutor1 = 'Apellido incorrecto';
      missingDataFunction(true);
    }
    if (!/^(?![\s.]+$)[a-zA-ZÀ-ÿ\s.]*$/i.test(values.apellido1Tutor2.trim())) {
      errors.apellido1Tutor2 = 'Apellido incorrecto';
      missingDataFunction(true);
    }

    // Validamos el segundo apellido
    if (menorEdad && !values.apellido2Tutor1) {
      errors.apellido2Tutor1 = 'Obligatorio';
      missingDataFunction(true);
    } else if (!/^(?![\s.]+$)[a-zA-ZÀ-ÿ\s.]*$/i.test(values.apellido2Tutor1.trim())) {
      errors.apellido2Tutor1 = 'Apellido incorrecto';
      missingDataFunction(true);
    }
    if (!/^(?![\s.]+$)[a-zA-ZÀ-ÿ\s.]*$/i.test(values.apellido2Tutor2.trim())) {
      errors.apellido2Tutor2 = 'Apellido incorrecto';
      missingDataFunction(true);
    }

    // Validamos el teléfono (no obligatorio)
    if (
      values.telefonoTutor1 !== '' &&
      !/^(\+34|0034|34)?[6789]\d{8}$/i.test(values.telefonoTutor1)
    ) {
      errors.telefonoTutor1 = 'Teléfono incorrecto';
      missingDataFunction(true);
    }
    if (
      values.telefonoTutor2 !== '' &&
      !/^(\+34|0034|34)?[6789]\d{8}$/i.test(values.telefonoTutor2)
    ) {
      errors.telefonoTutor2 = 'Teléfono incorrecto';
      missingDataFunction(true);
    }

    // Validamos el móvil
    if (menorEdad && !values.movilTutor1) {
      errors.movilTutor1 = 'Obligatorio';
      missingDataFunction(true);
    } else if (values.movilTutor1 !== '' && !/^[6-7]\d{8}$/i.test(values.movilTutor1)) {
      errors.movilTutor1 = 'Móvil incorrecto';
      missingDataFunction(true);
    }
    if (values.movilTutor2 !== '' && !/^[6-7]\d{8}$/i.test(values.movilTutor2)) {
      errors.movilTutor2 = 'Móvil incorrecto';
      missingDataFunction(true);
    }

    // Validamos el email
    if (menorEdad && !values.emailTutor1) {
      errors.emailTutor1 = 'Obligatorio';
      missingDataFunction(true);
    } else if (
      values.emailTutor1 !== '' &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emailTutor1)
    ) {
      errors.emailTutor1 = 'Email incorrecto';
      missingDataFunction(true);
    }
    if (
      values.emailTutor2 !== '' &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emailTutor2)
    ) {
      errors.emailTutor2 = 'Email incorrecto';
      missingDataFunction(true);
    }

    // Validamos el tipo de documento
    if (menorEdad && values.tipoDocumentoTutor1 === '-1') {
      errors.tipoDocumentoTutor1 = 'Obligatorio';
      missingDataFunction(true);
    }

    // Validamos el documento
    if (menorEdad && !values.documentoTutor1 && values.tipoDocumentoTutor1 !== '-1') {
      errors.documentoTutor1 = 'Obligatorio';
      missingDataFunction(true);
    } else if (values.tipoDocumentoTutor1 === '0' && !validarDNI(values.documentoTutor1)) {
      errors.documentoTutor1 = 'Formato incorrecto';
      missingDataFunction(true);
    } else if (values.tipoDocumentoTutor1 === '1' && !validarNIE(values.documentoTutor1)) {
      errors.documentoTutor1 = 'Formato incorrecto';
      missingDataFunction(true);
    } else if (values.tipoDocumentoTutor1 === '3' && !validarCIF(values.documentoTutor1)) {
      errors.documentoTutor1 = 'Formato incorrecto';
      missingDataFunction(true);
    }

    // Validamos el parentesco
    if (menorEdad && values.parentescoTutor1 === '0') {
      errors.parentescoTutor1 = 'Obligatorio';
      missingDataFunction(true);
    }

    // Devolvemos los errores
    return errors;
  };

  const saveData = async (values, whitModal = true) => {
    try {
      const urlToGo = `${process.env.REACT_APP_NODE_API}actualizarUsuarioTutores?empresaId=${empresaId}`;
      const rawData = {
        id: idStudent,
        nombreTutor: values.nombreTutor1,
        apellido1Tutor: values.apellido1Tutor1,
        apellido2Tutor: values.apellido2Tutor1,
        telefonoTutor: values.telefonoTutor1,
        movilTutor: values.movilTutor1,
        emailTutor: values.emailTutor1,
        tipoDocumentoTutor: values.tipoDocumentoTutor1,
        documentoTutor: values.documentoTutor1,
        parentescoTutor: values.parentesco1,
        nombreTutor2: values.nombreTutor2,
        apellido1Tutor2: values.apellido1Tutor2,
        apellido2Tutor2: values.apellido2Tutor2,
        telefonoTutor2: values.telefonoTutor2,
        movilTutor2: values.movilTutor2,
        emailTutor2: values.emailTutor2,
        tipoDocumentoTutor2: values.tipoDocumentoTutor2,
        documentoTutor2: values.documentoTutor2,
        parentescoTutor2: values.parentesco2,
        headquarter: empresaId,
      };

      if (whitModal) {
        setRaw(rawData);
        setUrl(urlToGo);
        setIsModalShown(true);
      } else {
        await httpServiceInstanceNode.post(urlToGo, rawData);
      }
    } catch (error) {
      // No hacer nada específico aquí.
    }
  };

  // Variable de Formik
  const formik = useFormik({
    initialValues: {
      id: userId,
      nombreTutor1: datosTutor[0].nombreTutor,
      apellido1Tutor1: datosTutor[0].apellido1Tutor,
      apellido2Tutor1: datosTutor[0].apellido2Tutor,
      telefonoTutor1: datosTutor[0].telefonoTutor,
      movilTutor1: datosTutor[0].movilTutor,
      emailTutor1: datosTutor[0].emailTutor,
      tipoDocumentoTutor1: datosTutor[0].tipoDocumentoTutor.toString(),
      documentoTutor1: datosTutor[0].documentoTutor,
      parentescoTutor1: datosTutor[0].parentescoTutor,
      nombreTutor2: datosTutor[1].nombreTutor,
      apellido1Tutor2: datosTutor[1].apellido1Tutor,
      apellido2Tutor2: datosTutor[1].apellido2Tutor,
      telefonoTutor2: datosTutor[1].telefonoTutor,
      movilTutor2: datosTutor[1].movilTutor,
      emailTutor2: datosTutor[1].emailTutor,
      tipoDocumentoTutor2: datosTutor[1].tipoDocumentoTutor.toString(),
      documentoTutor2: datosTutor[1].documentoTutor,
      parentescoTutor2: datosTutor[1].parentescoTutor,
      parentesco1: '',
      parentesco2: '',
    },
    validateOnChange: false,
    validate,
    onSubmit: (values) => saveData(values, true),
  });

  // Declaramos los efectos

  // Efecto para activar la validación al cargar el componente
  useEffect(() => {
    formik.validateForm();
  }, [formik.values]);

  // Efecto para activar missingDataFunction cuando el componente se monta
  useEffect(() => {
    if (menorEdad) {
      const {
        nombreTutor1,
        apellido1Tutor1,
        apellido2Tutor1,
        movilTutor1,
        emailTutor1,
        tipoDocumentoTutor1,
        documentoTutor1,
        parentescoTutor1,
      } = formik.values;

      // Verificar si alguno de los campos está vacío
      if (
        !nombreTutor1 ||
        !apellido1Tutor1 ||
        !apellido2Tutor1 ||
        !movilTutor1 ||
        !emailTutor1 ||
        tipoDocumentoTutor1 === '-1' ||
        !documentoTutor1 ||
        parentescoTutor1 === '0'
      ) {
        missingDataFunction(true); // Activar missingDataFunction si faltan datos
      } else {
        missingDataFunction(false); // No hay datos faltantes
      }
    }
  }, []); // El efecto se ejecutará solo al montar el componente

  // Efecto para guardar al cambiar de pestaña y resetear el dirty de formik.
  useEffect(() => {
    const exec = async () => {
      try {
        if (prevActiveTab === '3') {
          if (formik.dirty) await saveData(formik.values, false);
        } else {
          formik.resetForm({ values: formik.values });
        }
      } catch (error) {
        // No hacer nada específico aquí.
      }
    };

    exec();
  }, [prevActiveTab]);

  return (
    <TabPane tabId="3">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        {isModalShown ? (
          <ModalOptions
            header="¿Desea realizar estos cambios?"
            url={url}
            raw={raw}
            load={setIsModalShown}
            text1="Cancelar"
            color1="danger"
            text2="Aceptar"
            color2="primary"
          />
        ) : (
          ''
        )}
        <Tutor
          posicion="1"
          menorEdad={menorEdad}
          formikValues={formik.values}
          setValues={formik.setValues}
          handleChange={formik.handleChange}
          errors={formik.errors}
        />
        <Tutor
          posicion="2"
          menorEdad={menorEdad}
          formikValues={formik.values}
          setValues={formik.setValues}
          handleChange={formik.handleChange}
          errors={formik.errors}
        />
        <Row>
          <Col sm="11" className="mt-1 mt-md-0">
            {userOU.centro === 'ORIENTACIÓN' ? (
              ''
            ) : (
              <Button color="success" type="submit">
                <Icon.Save />
              </Button>
            )}
          </Col>
        </Row>
      </Form>
    </TabPane>
  );
};

DatosTutores.propTypes = {
  prevActiveTab: PropTypes.string,
  datosTutor: PropTypes.any,
  userId: PropTypes.string,
  menorEdad: PropTypes.any,
  missingDataFunction: PropTypes.any,
};

export default DatosTutores;
