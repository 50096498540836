import HttpService from './httpService';

class IntercambioApiService extends HttpService {
  constructor(baseUrl, generalCatch) {
    super(baseUrl, generalCatch);
  }

  async generarXML({ headquarter, evaluaciones, ano, grado }) {
    return this.post('generarXML', {}, { params: { headquarter, evaluaciones, ano, grado } });
  }
}

const intercambioApiService = new IntercambioApiService(process.env.REACT_APP_NODE_API);
export default intercambioApiService;
