import { useState, useEffect, useCallback } from 'react';
import { Col, Form, Row, Input, Button, Label, Spinner } from 'reactstrap';
import Datetime from 'react-datetime';
import moment from 'moment';
import PropTypes from 'prop-types';
import ModalLoader from '../../layouts/modals/ModalLoader';
import Loader from '../../layouts/loader/Loader';
import ListadoAlumnosActas from './ListadoAlumnosActas';
import actasApiService from '../../services/http/actasApiService';
import d2lApiService from '../../services/http/d2lApiService';
import ModalClickable from '../../layouts/modals/ModalClickable';

const FormularioActa = ({ isActa }) => {
  const [data, setData] = useState('');
  const [headquarter, setHeadquarter] = useState('CEDESCA');
  const [courseYear, setCourseYear] = useState('2324');
  const [course, setCourse] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isProceedingLoading, setIsProceedingLoading] = useState(false);
  const [type, setType] = useState(1);
  const [group, setGroup] = useState();
  const [fecha, setFecha] = useState('');
  const [listadoAlumnos, setListadoAlumnos] = useState([]);
  const [alumnosIds, setAlumnosIds] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataActas = await actasApiService.cargarActas({
          degree: '1',
          years: '2324',
          headquarter: 'CEDESCA',
          type: '1',
        });
        setGroup(dataActas.groups[0].titulo);
        setData(dataActas);
      } catch (error) {
        setGroup();
        setData('');
      }
    };

    fetchData();
  }, []);

  const handleStudent = useCallback(
    (ccodcli) => {
      setAlumnosIds((prevIds) => {
        const newAlumnosIds = prevIds.includes(ccodcli)
          ? prevIds.filter((id) => id !== ccodcli)
          : [...prevIds, ccodcli];

        // Actualizar listadoAlumnos
        setListadoAlumnos((prevList) =>
          prevList.map((alumno) =>
            alumno.ccodcli === ccodcli
              ? {
                  ...alumno,
                  actions: (
                    <Input
                      type="checkbox"
                      id={ccodcli}
                      name="option"
                      checked={newAlumnosIds.includes(ccodcli)}
                      onChange={() => handleStudent(ccodcli)}
                    />
                  ),
                }
              : alumno,
          ),
        );

        return newAlumnosIds;
      });
    },
    [alumnosIds],
  );

  const handleHeadquarter = async (e) => {
    setIsLoading(true);
    setHeadquarter(e.target.value);

    try {
      const dataActas = await actasApiService.cargarActas({
        degree: course,
        years: courseYear,
        headquarter: e.target.value,
        type,
      });
      setGroup(dataActas.groups[0].titulo);
      setData(dataActas);
    } catch (error) {
      setGroup();
      setData('');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCourseYear = async (e) => {
    setIsLoading(true);
    setCourseYear(e.target.value);

    try {
      const dataActas = await actasApiService.cargarActas({
        degree: course,
        years: e.target.value,
        headquarter,
        type,
      });
      setGroup(dataActas.groups[0].titulo);
      setData(dataActas);
    } catch (error) {
      setGroup();
      setData('');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCourse = async (e) => {
    setIsLoading(true);
    setCourse(e.target.value);

    try {
      const dataActas = await actasApiService.cargarActas({
        degree: e.target.value,
        years: courseYear,
        headquarter,
        type,
      });
      setGroup(dataActas.groups[0].titulo);
      setData(dataActas);
    } catch (error) {
      setGroup();
      setData('');
    } finally {
      setIsLoading(false);
    }
  };

  const handleType = async (e) => {
    setIsLoading(true);
    setType(e.target.value);

    try {
      const dataActas = await actasApiService.cargarActas({
        degree: course,
        years: courseYear,
        headquarter,
        type: e.target.value,
      });
      setGroup(dataActas.groups[0].titulo);
      setData(dataActas);
    } catch (error) {
      setGroup();
      setData('');
    } finally {
      setIsLoading(false);
    }
  };

  const handleGroup = (e) => {
    setGroup(e.target.value);
  };

  const handleGenerate = async () => {
    setIsProceedingLoading(true);

    try {
      const dataBlob = await actasApiService.buscarDatosActas({
        course,
        courseYear,
        type,
        headquarter,
        group,
        fecha,
        usersId: alumnosIds,
      });

      const blobFinal = dataBlob.slice(0, dataBlob.size, 'application/pdf');
      const url = URL.createObjectURL(blobFinal);
      window.open(url, '_blank');
      setIsLoading(false);
      setIsProceedingLoading(false);
    } catch (error) {
      setIsLoading(false);
      setIsProceedingLoading(false);
    }
  };

  const handleProceeding = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    try {
      const dataAlumnosActas = await actasApiService.buscarAlumnosActas({
        type,
        group,
        fecha,
        courseYear,
        headquarter,
        isActa,
      });

      dataAlumnosActas.forEach((element) => {
        element.actions = (
          <Input
            type="checkbox"
            id={element.ccodcli}
            name="option"
            checked={alumnosIds.includes(element.ccodcli)}
            onChange={() => handleStudent(element.ccodcli)}
          />
        );
      });
      setListadoAlumnos(dataAlumnosActas);
      setAllChecked(false);
    } catch (error) {
      setListadoAlumnos([]);
      setAllChecked(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheck = () => {
    if (!allChecked) {
      // Marcar todos
      const nuevoArray = listadoAlumnos.map((a) => a.ccodcli);
      setAlumnosIds(nuevoArray);

      // Actualizar listadoAlumnos para que todos los checkboxes estén marcados
      setListadoAlumnos((prevList) =>
        prevList.map((alumno) => ({
          ...alumno,
          actions: (
            <Input
              type="checkbox"
              id={alumno.ccodcli}
              name="option"
              checked // Marcar checkbox
              onChange={() => handleStudent(alumno.ccodcli)}
            />
          ),
        })),
      );
    } else {
      // Desmarcar todos
      setAlumnosIds([]);

      // Actualizar listadoAlumnos para desmarcar todos los checkboxes
      setListadoAlumnos((prevList) =>
        prevList.map((alumno) => ({
          ...alumno,
          actions: (
            <Input
              type="checkbox"
              id={alumno.ccodcli}
              name="option"
              checked={false} // Desmarcar checkbox
              onChange={() => handleStudent(alumno.ccodcli)}
            />
          ),
        })),
      );
    }

    // Alternar el estado de allChecked
    setAllChecked(!allChecked);
  };

  const handleFecha = (value) => {
    const fechad = moment.utc(value).toDate();
    const formattedDate = fechad.toLocaleDateString('es-ES', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    setFecha(formattedDate);
  };

  const handleImport = async () => {
    setIsImporting(true);
    const datad2l = {
      type,
      group,
      fecha,
      courseYear,
      headquarter,
    };

    await d2lApiService.importarNotasD2L({
      datad2l,
      alumnosIds,
    });
    setIsImporting(false);
    setIsDone(true);
  };

  return (
    <>
      {isProceedingLoading ? <ModalLoader header="Generando acta" /> : ''}
      {isImporting ? <ModalLoader header="Importando notas." /> : ''}
      {isDone ? <ModalClickable header="Notas importadas." /> : ''}
      {data !== '' ? (
        <Form>
          <Row className="mb-3">
            <Col sm>
              <Label for="centros">Centro</Label>
              <Input
                type="select"
                name="centros"
                id="centros"
                onChange={handleHeadquarter}
                disabled={isLoading ? 'disabled' : ''}
              >
                {data.headquarters.map((datos) => (
                  <option key={datos} value={datos}>
                    {datos}
                  </option>
                ))}
              </Input>
            </Col>
            <Col sm className="mt-2 mt-md-0">
              <Label for="grado">Grado</Label>
              <Input
                type="select"
                name="grado"
                id="grado"
                onChange={handleCourse}
                disabled={isLoading ? 'disabled' : ''}
              >
                {data.degree.map((datos) => (
                  <option key={datos.value} value={datos.value}>
                    {datos.name}
                  </option>
                ))}
              </Input>
            </Col>
          </Row>
          <Row className="mt-0 mt-md-2 mb-3">
            <Col sm className="mt-2 mt-md-0">
              <Label for="curso">Curso</Label>
              <Input
                type="select"
                name="curso"
                id="curso"
                onChange={handleCourseYear}
                disabled={isLoading ? 'disabled' : ''}
              >
                {data.years.map((datos) => (
                  <option key={datos.value} value={datos.value}>
                    {datos.name}
                  </option>
                ))}
                <option value="2122">2021/2022</option>
              </Input>
            </Col>
            <Col sm className="mt-2 mt-md-0">
              <Label for="tipo">Tipo</Label>
              <Input
                type="select"
                name="tipo"
                id="tipo"
                onChange={handleType}
                disabled={isLoading ? 'disabled' : ''}
              >
                {data.type.map(
                  (datos) =>
                    (isActa || datos.value !== 3) && (
                      <option key={datos.value} value={datos.value}>
                        {datos.name}
                      </option>
                    ),
                )}
              </Input>
            </Col>
          </Row>
          <Row className="mt-0 mt-md-2">
            <Col sm className="mt-2 mt-md-0" md={!isActa && 6}>
              <Label for="grupo">Grupo</Label>
              <Input
                type="select"
                name="grupo"
                id="grupo"
                disabled={isLoading ? 'disabled' : ''}
                onChange={handleGroup}
              >
                {data.groups.length > 0
                  ? data.groups.map((datos) => (
                      <option key={datos.titulo} value={datos.titulo}>
                        {datos.titulo}
                      </option>
                    ))
                  : ''}
              </Input>
            </Col>
            {isActa && (
              <Col sm className="mt-2 mt-md-0">
                <Label for="fecha">Fecha</Label>
                <Datetime
                  id="fecha"
                  name="fecha"
                  timeFormat={false}
                  onChange={handleFecha}
                  dateFormat
                />
                {fecha !== '' ? '' : <span className="text-danger tiny">*Obligatorio</span>}
              </Col>
            )}
          </Row>
          <Row className="mt-0 mt-md-2">
            <Col sm className="mt-6 mt-md-0 text-start">
              {isActa ? (
                <Button
                  className={
                    fecha !== '' && data.groups.length > 0 ? 'mt-3 w-auto' : 'mt-3 w-auto disabled'
                  }
                  onClick={handleProceeding}
                >
                  {isLoading ? (
                    <>
                      <Spinner color="primary" style={{ height: '15px', width: '15px' }} />{' '}
                    </>
                  ) : (
                    'Filtrar'
                  )}
                </Button>
              ) : (
                <Button
                  className={data.groups.length > 0 ? 'mt-3 w-auto' : 'mt-3 w-auto disabled'}
                  onClick={handleProceeding}
                >
                  {isLoading ? (
                    <>
                      <Spinner color="primary" style={{ height: '15px', width: '15px' }} />{' '}
                    </>
                  ) : (
                    'Filtrar'
                  )}
                </Button>
              )}
            </Col>
            <Col sm className="mt-6 mt-md-0 text-center">
              <Button
                className={
                  listadoAlumnos.length > 0 && data.groups.length > 0 && !isLoading
                    ? 'mt-3 w-auto'
                    : 'mt-3 w-auto disabled'
                }
                onClick={handleCheck}
              >
                {allChecked ? 'Desmarcar todos' : 'Marcar todos'}
              </Button>
            </Col>
            <Col sm className="mt-3 mt-md-0 text-end">
              {isActa ? (
                <Button
                  className={
                    alumnosIds.length > 0 && data.groups.length > 0 && !isLoading
                      ? 'mt-3 w-auto'
                      : 'mt-3 w-auto disabled'
                  }
                  onClick={handleGenerate}
                >
                  Generar acta
                </Button>
              ) : (
                <Button
                  className={
                    alumnosIds.length > 0 && data.groups.length > 0 && !isLoading
                      ? 'mt-3 w-auto'
                      : 'mt-3 w-auto disabled'
                  }
                  onClick={handleImport}
                >
                  Importar notas
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      ) : (
        <Loader />
      )}
      {listadoAlumnos.length > 0 ? <ListadoAlumnosActas data={listadoAlumnos} /> : ''}
    </>
  );
};

FormularioActa.propTypes = {
  isActa: PropTypes.any,
};
export default FormularioActa;
