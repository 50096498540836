import React from 'react';
import PropTypes from 'prop-types';

const ProfileDD = (props) => {
  return (
    <div>
      <div className="d-flex gap-3 p-3 border-bottom pt-2 align-items-center">
        <img src={props.image} alt="user" className="rounded-circle" width="60" />
        <span>
          <h6 className="mb-0">{props.name}</h6>
          <small>{props.email}</small>
          {!props.impersonating && (
            <>
              <br />
              <small style={{ display: 'block', marginTop: '10px' }}>{props.userOU.centro}</small>
            </>
          )}
        </span>
      </div>
      {props.impersonating && (
        <div className="d-flex flex-column m-2 rounded-3 p-2 align-items-center bg-light-warning">
          <h6 className="mb-0">Suplantando a</h6>
          <small>{props.impersonateName}</small>
          <small>{props.impersonateEmail}</small>
          <small>{props.userOU.centro}</small>
        </div>
      )}
    </div>
  );
};

ProfileDD.propTypes = {
  name: PropTypes.string,
  impersonateName: PropTypes.string,
  email: PropTypes.string,
  impersonateEmail: PropTypes.string,
  image: PropTypes.string,
  userOU: PropTypes.object,
  impersonating: PropTypes.bool,
};

export default ProfileDD;
