import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Form, Input, Row, Button, Spinner } from 'reactstrap';
import ReactTable from 'react-table-v6';
import Select from 'react-select';
import Loader from '../../layouts/loader/Loader';
import 'react-table-v6/react-table.css';
import '../../assets/styles/login/styles.css';
import { AuthContext } from '../../context/authContext';
import { SearchContext } from '../../context/searchContext';
import alumnosApiService from '../../services/http/alumnosApiService';
import otrosApiService from '../../services/http/otrosApiService';

const FormularioUsuarios = () => {
  const navigate = useNavigate();
  const translations = {
    previousText: '<',
    nextText: '>',
    loadingText: 'Cargando..',
    ofText: 'de',
    rowsText: 'líneas',
    noDataText: 'Datos no encontrados',
    pageText: 'Página',
  };

  const { searchContext, saveSearches } = useContext(SearchContext);

  const { userOU } = useContext(AuthContext);

  const [formationList, setFormationList] = useState('');
  const [formationCenter, setFormationCenter] = useState('');
  const [phone, setPhone] = useState({ data: '', error: '' });
  const [headquarterList, setHeadquarterList] = useState('');
  const [yearList, setYearList] = useState('');
  const [year, setYear] = useState('All');
  const [headquarter, setHeadquarter] = useState('CEDETECGROUP');
  const [dni, setDni] = useState({ data: '', error: '' });
  const [email, setEmail] = useState({ data: '', error: '' });
  const [name, setName] = useState({ data: '', error: '' });
  const [lastname, setLastname] = useState({ data: '', error: '' });
  const [idUser, setIdUser] = useState({ data: '', error: '' });
  const [isFormSubmittable, setIsFormSubmittable] = useState(true);
  const [isTableNotLoading, setIsTableNotLoading] = useState(true);
  const [data, setData] = useState(null);
  const [cicleList, setCicleList] = useState('');
  const [cicle, setCicle] = useState('All');
  const [typeList, setTypeList] = useState('');
  const [type, setType] = useState(
    userOU.centro !== 'SUPERADMIN' &&
      userOU.centro !== 'CEDESCA' &&
      userOU.centro !== 'CNTEC' &&
      userOU.centro !== '' &&
      userOU.centro !== 'SEDE' &&
      userOU.centro !== 'SECRETARÍA' &&
      userOU.centro !== 'ORIENTACIÓN'
      ? 'O'
      : 'All',
  );
  const [course, setCourse] = useState('All');
  const [courseList, setCourseList] = useState('');
  const [deudores, setDeudores] = useState(false);

  const handleStudentInfo = (id, empresaId = '') => {
    const empresaLowerCase = empresaId.toLowerCase();
    if (localStorage.getItem('student')) localStorage.removeItem('student');
    navigate(`${empresaLowerCase}/${id}/detalles`);
  };

  const handleOnClick = () => {
    if (localStorage.getItem('student')) {
      localStorage.removeItem('student');
    }
    window.location.href = '/nuevo-alumno';
  };

  useEffect(() => {
    const fetchBySearchContextAndOptions = async () => {
      if (searchContext !== '' && searchContext.newSearch === false) {
        setName({ data: searchContext.nameSearch, error: '' });
        setLastname({ data: searchContext.lastnameSearch, error: '' });
        setEmail({ data: searchContext.emailSearch, error: '' });
        setDni({ data: searchContext.dniSearch, error: '' });
        setHeadquarter(searchContext.headquarterSearch);
        setIdUser({ data: searchContext.idUserSearch, error: '' });
        setYear(searchContext.yearSearch);
        setCicle(searchContext.courseSearch);
        setType(searchContext.typeSearch);
        setCourse(searchContext.cicleSearch);
        setPhone(searchContext.phoneSearch);
        setFormationCenter(searchContext.formationCenterSearch);
        setDeudores(searchContext.deudoresSearch);
        setIsTableNotLoading(false);

        let params = null;
        let params2 = null;
        // Gestionamos el ID de delegación según empresa
        if (searchContext.headquarterSearch === 'CEDESCA') {
          params = {
            empresaId: 'CEDESCA',
            nombre: searchContext.nameSearch,
            apellido: searchContext.lastnameSearch,
            email: searchContext.emailSearch,
            dni: searchContext.dniSearch,
            idccod: searchContext.idUserSearch,
            modalitat: searchContext.typeSearch,
            curs: searchContext.yearSearch,
            grau: searchContext.cicleSearch,
            cicle: searchContext.courseSearch,
            phone: searchContext.phone,
            idDelegacio: userOU.idCedesca,
            nombreDelegacion: formationCenter,
            deudor: deudores,
          };
        } else if (searchContext.headquarterSearch === 'CNTEC') {
          params = {
            empresaId: 'CNTEC',
            nombre: searchContext.nameSearch,
            apellido: searchContext.lastnameSearch,
            email: searchContext.emailSearch,
            dni: searchContext.dniSearch,
            idccod: searchContext.idUserSearch,
            modalitat: searchContext.typeSearch,
            curs: searchContext.yearSearch,
            grau: searchContext.cicleSearch,
            cicle: searchContext.courseSearch,
            phone: searchContext.phone,
            idDelegacio: userOU.idCntec,
            nombreDelegacion: formationCenter,
            deudor: deudores,
          };
        } else {
          params = {
            empresaId: 'CEDESCA',
            nombre: searchContext.nameSearch,
            apellido: searchContext.lastnameSearch,
            email: searchContext.emailSearch,
            dni: searchContext.dniSearch,
            idccod: searchContext.idUserSearch,
            modalitat: searchContext.typeSearch,
            curs: searchContext.yearSearch,
            grau: searchContext.cicleSearch,
            cicle: searchContext.courseSearch,
            phone: searchContext.phone,
            idDelegacio: userOU.idCedesca,
            nombreDelegacion: searchContext.formationCenterSearch,
            deudor: deudores,
          };

          params2 = {
            empresaId: 'CNTEC',
            nombre: searchContext.nameSearch,
            apellido: searchContext.lastnameSearch,
            email: searchContext.emailSearch,
            dni: searchContext.dniSearch,
            idccod: searchContext.idUserSearch,
            modalitat: searchContext.typeSearch,
            curs: searchContext.yearSearch,
            grau: searchContext.cicleSearch,
            cicle: searchContext.courseSearch,
            phone: searchContext.phone,
            idDelegacio: userOU.idCntec,
            nombreDelegacion: searchContext.formationCenterSearch,
            deudor: deudores,
          };
        }

        let actualData = [];
        let actualData2 = [];
        try {
          if (params) {
            actualData = await alumnosApiService.buscarAlumnos(params);

            actualData.forEach((element) => {
              element.actions = (
                <>
                  <Button
                    color="success"
                    onClick={() => {
                      handleStudentInfo(element.id, element.empresaId);
                    }}
                  >
                    <a className="text-light text-decoration-none">Detalles</a>
                  </Button>
                </>
              );
            });
          }

          if (params2) {
            actualData2 = await alumnosApiService.buscarAlumnos(params2);

            actualData2.forEach((element) => {
              element.actions = (
                <>
                  <Button
                    color="success"
                    onClick={() => {
                      handleStudentInfo(element.id, element.empresaId);
                    }}
                  >
                    <a className="text-light text-decoration-none">Detalles</a>
                  </Button>
                </>
              );
            });
          }

          setData([...actualData, ...actualData2]);
        } catch (error) {
          // No hacer nada específico aquí.
        } finally {
          setIsTableNotLoading(true);
        }
      }

      setIsFormSubmittable(false);

      try {
        const dataConsultaEmpresas = await otrosApiService.consultaEmpresas({ headquarter: '1' });
        setHeadquarterList(dataConsultaEmpresas);
        setIsFormSubmittable(true);
      } catch (error) {
        setHeadquarterList('');
        setIsFormSubmittable(false);
      }

      try {
        const dataCentrosFormacion = await alumnosApiService.cargarCentrosFormacion({
          empresaId: 'CEDESCA',
        });
        dataCentrosFormacion.unshift({ Id: '', Nombre: 'Todos' });
        const opcionesRenombradas = dataCentrosFormacion.map((opcion) => ({
          value: opcion.Id,
          label: opcion.Nombre,
        }));
        setFormationList(opcionesRenombradas);
        setIsFormSubmittable(true);
      } catch (error) {
        setFormationList('');
        setIsFormSubmittable(false);
      }

      try {
        const dataOpcionesBuscarAlumno = await alumnosApiService.cargarOpcionesBuscarAlumno({
          headquarter: '1',
        });
        // Si se recibe un mensaje de error no dejamos crear usuario.
        if (dataOpcionesBuscarAlumno.error) {
          setIsFormSubmittable(false);
        }

        setTypeList(dataOpcionesBuscarAlumno.modalitat);
        setCicleList(dataOpcionesBuscarAlumno.grau);
        setCourseList(dataOpcionesBuscarAlumno.cicle);
        setYearList(dataOpcionesBuscarAlumno.curs);
        setIsFormSubmittable(true);
      } catch (error) {
        setTypeList('');
        setCicleList('');
        setCourseList('');
        setYearList('');
        setIsFormSubmittable(false);
      }
    };

    fetchBySearchContextAndOptions();
  }, [searchContext]);

  const handleIDOnChange = (e) => {
    if (e.target.value.trim().length === 0) {
      setIdUser({ data: '', error: '' });
      setIsFormSubmittable(true);
    } else if (e.target.value.trim().match(/^[^0-9]*$/)) {
      setIdUser({ data: '', error: 'format' });
      setIsFormSubmittable(false);
    } else {
      setIdUser({ data: e.target.value.trim(), error: '' });
      setIsFormSubmittable(true);
    }
  };

  const handleNameOnChange = (e) => {
    if (e.target.value.trim().length === 0) {
      setName({ data: '', error: '' });
      setIsFormSubmittable(true);
    } else if (!e.target.value.trim().match(/^(?![\s.]+$)[a-zA-ZÀ-ÿ\s.]*$/)) {
      setName({ data: '', error: 'format' });
      setIsFormSubmittable(false);
    } else if (e.target.value.trim().length === 1) {
      setName({ data: '', error: 'length' });
      setIsFormSubmittable(false);
    } else {
      setName({ data: e.target.value.trim(), error: '' });
      setIsFormSubmittable(true);
    }
  };
  const handleLastNameOnChange = (e) => {
    if (!e.target.value.trim().match(/^(?![\s.]+$)[a-zA-ZÀ-ÿ\s.]*$/)) {
      setLastname({ data: '', error: 'format' });
      setIsFormSubmittable(false);
    } else if (e.target.value.trim().length === 1) {
      setLastname({ data: '', error: 'length' });
      setIsFormSubmittable(false);
    } else {
      setLastname({ data: e.target.value.trim(), error: '' });
      setIsFormSubmittable(true);
    }
  };
  const handleDNIOnChange = (e) => {
    setDni({ data: e.target.value.trim(), error: '' });
    setIsFormSubmittable(true);
  };

  const handlePhoneOnChange = (e) => {
    const phon = e.target.value.trim();

    if (phon === '') {
      // Si está vacío, no hay error y no se verifica la validez.
      setPhone({ data: phon, error: '' });
      setIsFormSubmittable(true);
    } else {
      const isValidPhone = /^[67]\d{8}$/.test(phon);
      if (isValidPhone) {
        setPhone({ data: phon, error: '' });
        setIsFormSubmittable(true);
      } else {
        setPhone({ data: phon, error: 'El número debe comenzar con 7 o 6 y tener 9 dígitos.' });
        setIsFormSubmittable(false);
      }
    }

    console.log(phon, 'yeee');
  };

  const handleEmailOnChange = (e) => {
    if (
      e.target.value.trim().length > 0 &&
      !e.target.value
        .trim()
        .toLowerCase()
        .match(
          /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
        )
    ) {
      setEmail({ data: '', error: 'format' });
      setIsFormSubmittable(false);
    } else {
      setEmail({ data: e.target.value.trim(), error: '' });
      setIsFormSubmittable(true);
    }
  };

  const handleHeadquarter = (e) => {
    setHeadquarter(e.target.value);
  };

  const handleFormationCenter = (selected) => {
    setFormationCenter(selected);
  };

  const handleCourse = (e) => {
    setCourse(e.target.value);
  };
  const handleYear = (e) => {
    setYear(e.target.value);
  };

  const handleType = (e) => {
    setType(e.target.value);
  };
  const handleCicle = (e) => {
    setCicle(e.target.value);
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setIsTableNotLoading(false);

    let delegaciones = '';
    if (formationCenter !== '') {
      formationCenter.forEach((opcion) => {
        if (delegaciones === '') {
          delegaciones += `'${opcion.label}'`;
        } else {
          delegaciones += `,'${opcion.label}'`;
        }
      });
    }

    let params = null;
    let params2 = null;
    if (
      (userOU.idCedesca === 0 && userOU.idCntec === 0) ||
      (userOU.idCedesca === -1 && userOU.idCntec === -1) ||
      (userOU.idCedesca === -2 && userOU.idCntec === -2) ||
      (userOU.idCedesca === -3 && userOU.idCntec === -3)
    ) {
      // Petición si es Admin/IT o Sede
      params = {
        empresaId: headquarter,
        nombre: name.data,
        apellido: lastname.data,
        email: email.data,
        dni: dni.data,
        curs: year,
        grau: cicle,
        cicle: course,
        modalitat: type,
        idccod: idUser.data,
        nombreDelegacion: delegaciones,
        deudor: deudores,
        phone: phone.data,
      };
    } else if (headquarter === 'CEDESCA') {
      params = {
        empresaId: 'CEDESCA',
        nombre: name.data,
        apellido: lastname.data,
        email: email.data,
        dni: dni.data,
        curs: year,
        grau: cicle,
        cicle: course,
        modalitat: type,
        idccod: idUser.data,
        idDelegacio: userOU.idCedesca,
        phone: phone.data,
      };
    } else if (headquarter === 'CNTEC') {
      params = {
        empresaId: 'CNTEC',
        nombre: name.data,
        apellido: lastname.data,
        email: email.data,
        dni: dni.data,
        curs: year,
        grau: cicle,
        cicle: course,
        modalitat: type,
        idccod: idUser.data,
        idDelegacio: userOU.idCntec,
        phone: phone.data,
      };
    } else {
      params = {
        empresaId: 'CEDESCA',
        nombre: name.data,
        apellido: lastname.data,
        email: email.data,
        dni: dni.data,
        curs: year,
        grau: cicle,
        cicle: course,
        modalitat: type,
        idccod: idUser.data,
        idDelegacio: userOU.idCedesca,
        phone: phone.data,
      };

      params2 = {
        empresaId: 'CNTEC',
        nombre: name.data,
        apellido: lastname.data,
        email: email.data,
        dni: dni.data,
        curs: year,
        grau: cicle,
        cicle: course,
        modalitat: type,
        idccod: idUser.data,
        idDelegacio: userOU.idCntec,
        phone: phone.data,
      };
    }

    let actualData = [];
    let actualData2 = [];
    try {
      if (params) {
        actualData = await alumnosApiService.buscarAlumnos(params);

        actualData.forEach((element) => {
          element.actions = (
            <>
              <Button
                color="success"
                onClick={() => {
                  handleStudentInfo(element.id, element.empresaId);
                }}
              >
                <a className="text-light text-decoration-none">Detalles</a>
              </Button>
            </>
          );
        });
      }

      if (params2) {
        actualData2 = await alumnosApiService.buscarAlumnos(params2);

        actualData2.forEach((element) => {
          element.actions = (
            <>
              <Button
                color="success"
                onClick={() => {
                  handleStudentInfo(element.id, element.empresaId);
                }}
              >
                <a className="text-light text-decoration-none">Detalles</a>
              </Button>
            </>
          );
        });
      }

      setData([...actualData, ...actualData2]);
    } catch (error) {
      // No hacer nada específico aquí.
    } finally {
      setIsTableNotLoading(true);
    }

    const search = {
      nameSearch: name.data,
      lastnameSearch: lastname.data,
      dniSearch: dni.data,
      emailSearch: email.data,
      headquarterSearch: headquarter,
      courseSearch: course,
      typeSearch: type,
      cicleSearch: cicle,
      yearSearch: year,
      idUserSearch: idUser.data,
      formationCenterSearch: userOU.centro,
      newSearch: true,
      phoneSearch: phone.data,
      deudores,
    };

    //localStorage.setItem('search', JSON.stringify(search));
    saveSearches(search);
  };
  const handleDeudor = () => {
    setDeudores(!deudores);
  };
  const handleReset = () => {
    setHeadquarter('CEDETECGROUP');
    setCicle('All');
    setDni({ data: '', error: '' });
    setName({ data: '', error: '' });
    setLastname({ data: '', error: '' });
    setCourse('All');
    setType('All');
    setYear('All');
    setEmail({ data: '', error: '' });
    setFormationCenter('CEDESCA');
    setDeudores(false);
    setPhone({ data: '', error: '' });
  };
  const CustomClearText = () => 'borrar todo';
  const ClearIndicator = (props) => {
    const {
      // eslint-disable-next-line react/prop-types
      children = <CustomClearText />,
      // eslint-disable-next-line react/prop-types
      getStyles,
      // eslint-disable-next-line react/prop-types
      innerProps: { ref, ...restInnerProps },
    } = props;
    return (
      <div {...restInnerProps} ref={ref} style={getStyles('clearIndicator', props)}>
        <div style={{ padding: '0px 5px' }}>{children}</div>
      </div>
    );
  };

  return (
    <>
      {headquarterList !== '' &&
      cicleList !== '' &&
      typeList !== '' &&
      courseList !== '' &&
      userOU !== '' ? (
        <>
          <Form onSubmit={handleOnSubmit}>
            <Row className="mt-md-3" style={{ alignItems: 'flex-end' }}>
              <Col sm="2" className="mt-1 mt-md-0" onChange={handleNameOnChange}>
                {searchContext !== '' && searchContext.newSearch === false ? (
                  <>
                    <label htmlFor="name" className="form-label">
                      Nombre
                    </label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Buscar por nombre"
                      onChange={handleNameOnChange}
                      onInput={handleNameOnChange}
                      className={name.error !== '' ? 'border border-danger error' : ''}
                      defaultValue={name.data}
                      disabled={isTableNotLoading ? '' : 'disabled'}
                    />
                  </>
                ) : (
                  <>
                    <label htmlFor="name" className="form-label">
                      Nombre
                    </label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Buscar por nombre"
                      onChange={handleNameOnChange}
                      onInput={handleNameOnChange}
                      className={name.error !== '' ? 'border border-danger error' : ''}
                      disabled={isTableNotLoading ? '' : 'disabled'}
                    />
                  </>
                )}
                {name.error === 'length' && (
                  <span className="text-danger tiny">Debe tener mínimo 2 caracteres</span>
                )}
                {name.error === 'format' && (
                  <span className="text-danger tiny">Números o símbolos no permitidos</span>
                )}
              </Col>
              <Col sm="3" className="mt-1 mt-md-0">
                {searchContext !== '' && searchContext.newSearch === false ? (
                  <>
                    <label htmlFor="lastname" className="form-label">
                      Apellidos
                    </label>
                    <Input
                      type="text"
                      name="lastname"
                      id="lastname"
                      placeholder="Buscar por apellidos"
                      onChange={handleLastNameOnChange}
                      onInput={handleLastNameOnChange}
                      className={lastname.error !== '' ? 'border border-danger error' : ''}
                      defaultValue={lastname.data}
                      disabled={isTableNotLoading ? '' : 'disabled'}
                    />
                  </>
                ) : (
                  <>
                    <label htmlFor="lastname" className="form-label">
                      Apellidos
                    </label>
                    <Input
                      type="text"
                      name="lastname"
                      id="lastname"
                      placeholder="Buscar por apellidos"
                      onChange={handleLastNameOnChange}
                      onInput={handleLastNameOnChange}
                      className={lastname.error !== '' ? 'border border-danger error' : ''}
                      disabled={isTableNotLoading ? '' : 'disabled'}
                    />
                  </>
                )}
                {lastname.error === 'length' && (
                  <span className="text-danger tiny">Debe tener mínimo 2 caracteres</span>
                )}
                {lastname.error === 'format' && (
                  <span className="text-danger tiny">Números o símbolos no permitidos</span>
                )}
              </Col>
              <Col sm="2" className="mt-1 mt-md-0">
                {searchContext !== '' && searchContext.newSearch === false ? (
                  <>
                    <label htmlFor="dni" className="form-label">
                      DNI
                    </label>
                    <Input
                      type="text"
                      name="dni"
                      id="dni"
                      placeholder="Buscar por DNI"
                      onChange={handleDNIOnChange}
                      onInput={handleDNIOnChange}
                      className={dni.error !== '' ? 'border border-danger error' : ''}
                      defaultValue={dni.data}
                      disabled={isTableNotLoading ? '' : 'disabled'}
                    />
                  </>
                ) : (
                  <>
                    <label htmlFor="dni" className="form-label">
                      DNI
                    </label>
                    <Input
                      type="text"
                      name="dni"
                      id="dni"
                      placeholder="Buscar por DNI"
                      onChange={handleDNIOnChange}
                      onInput={handleDNIOnChange}
                      className={dni.error !== '' ? 'border border-danger error' : ''}
                      disabled={isTableNotLoading ? '' : 'disabled'}
                    />
                  </>
                )}
                {dni.error === 'format' && (
                  <span className="text-danger tiny">Formato incorrecto</span>
                )}
              </Col>
              <Col sm="2">
                {searchContext !== '' && searchContext.newSearch === false ? (
                  <>
                    <label htmlFor="idUser" className="form-label">
                      ID
                    </label>
                    <Input
                      type="text"
                      name="idUser"
                      id="idUser"
                      placeholder="Buscar por ID"
                      onChange={handleIDOnChange}
                      onInput={handleIDOnChange}
                      className={idUser.error !== '' ? 'border border-danger error' : ''}
                      defaultValue={idUser.data}
                      disabled={isTableNotLoading ? '' : 'disabled'}
                    />
                  </>
                ) : (
                  <>
                    <label htmlFor="idUser" className="form-label">
                      ID
                    </label>
                    <Input
                      type="text"
                      name="idUser"
                      id="idUser"
                      placeholder="Buscar por ID"
                      onChange={handleIDOnChange}
                      onInput={handleIDOnChange}
                      className={idUser.error !== '' ? 'border border-danger error' : ''}
                      disabled={isTableNotLoading ? '' : 'disabled'}
                    />
                  </>
                )}
              </Col>
              <Col sm="2">
                <label htmlFor="año" className="form-label">
                  Año
                </label>
                <select
                  className="form-select"
                  name="año"
                  id="año"
                  onChange={handleYear}
                  disabled={isTableNotLoading ? '' : 'disabled'}
                  value={year}
                >
                  {yearList !== ''
                    ? yearList.map((option) => (
                        <option key={option.codigo} value={option.codigo}>
                          {option.nombre}
                        </option>
                      ))
                    : ''}
                </select>
              </Col>
            </Row>
            <Row className="mt-auto mt-md-2">
              <Col sm="3">
                <label htmlFor="centros" className="form-label">
                  Centro
                </label>

                <select
                  className="form-select"
                  name="centros"
                  id="centros"
                  onChange={handleHeadquarter}
                  disabled={isTableNotLoading ? '' : 'disabled'}
                  value={headquarter}
                >
                  {headquarterList !== ''
                    ? headquarterList.map((option) => (
                        <option key={option.codigo} value={option.codigo}>
                          {option.nombre}
                        </option>
                      ))
                    : ''}
                </select>
              </Col>
              <Col sm="2">
                <label htmlFor="modalidad" className="form-label">
                  Modalidad
                </label>
                <select
                  className="form-select"
                  name="modalidad"
                  id="modalidad"
                  onChange={handleType}
                  disabled={
                    !isTableNotLoading ||
                    (userOU.centro !== 'CNTEC' &&
                      userOU.centro !== 'CEDESCA' &&
                      userOU.centro !== 'SUPERADMIN' &&
                      userOU.centro !== 'SECRETARÍA' &&
                      userOU.centro !== 'SEDE' &&
                      userOU.centro !== 'ORIENTACIÓN')
                      ? 'disabled'
                      : ''
                  }
                  value={
                    userOU.centro !== 'CNTEC' &&
                    userOU.centro !== 'CEDESCA' &&
                    userOU.centro !== 'SUPERADMIN' &&
                    userOU.centro !== 'SECRETARÍA' &&
                    userOU.centro !== 'SEDE' &&
                    userOU.centro !== 'ORIENTACIÓN'
                      ? 'O'
                      : type
                  }
                >
                  {typeList !== ''
                    ? typeList.map((option) => (
                        <option key={option.codigo} value={option.codigo}>
                          {option.nombre}
                        </option>
                      ))
                    : ''}
                </select>
              </Col>
              <Col sm="2">
                <label htmlFor="grado" className="form-label">
                  Grado
                </label>
                <select
                  className="form-select"
                  name="grado"
                  id="grado"
                  onChange={handleCicle}
                  disabled={isTableNotLoading ? '' : 'disabled'}
                  value={cicle}
                >
                  {cicleList !== ''
                    ? cicleList.map((option) => (
                        <option key={option.codigo} value={option.codigo}>
                          {option.nombre}
                        </option>
                      ))
                    : ''}
                </select>
              </Col>
              <Col sm="2">
                <label htmlFor="curso" className="form-label">
                  Curso
                </label>
                <select
                  className="form-select"
                  name="curso"
                  id="curso"
                  onChange={handleCourse}
                  disabled={isTableNotLoading ? '' : 'disabled'}
                  value={course}
                >
                  {courseList !== ''
                    ? courseList.map((option) => (
                        <option key={option.codigo} value={option.codigo}>
                          {option.nombre}
                        </option>
                      ))
                    : ''}
                </select>
              </Col>

              <Col sm="3" className="mt-1 mt-md-0">
                {searchContext !== '' && searchContext.newSearch === false ? (
                  <>
                    <label htmlFor="email" className="form-label">
                      Correo electrónico
                    </label>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Buscar por Email"
                      onChange={handleEmailOnChange}
                      onInput={handleEmailOnChange}
                      className={email.error !== '' ? 'border border-danger error' : ''}
                      defaultValue={email.data}
                      disabled={isTableNotLoading ? '' : 'disabled'}
                    />
                  </>
                ) : (
                  <>
                    <label htmlFor="email" className="form-label">
                      Correo electrónico
                    </label>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Buscar por Email"
                      onChange={handleEmailOnChange}
                      onInput={handleEmailOnChange}
                      className={email.error !== '' ? 'border border-danger error' : ''}
                      disabled={isTableNotLoading ? '' : 'disabled'}
                    />
                  </>
                )}
                {email.error === 'format' && (
                  <span className="text-danger tiny">Formato incorrecto</span>
                )}
              </Col>
            </Row>

            <Row
              className="mt-auto mt-md-2 d-flex flex-column flex-md-row"
              style={{ alignItems: 'flex-end' }}
            >
              <Col sm="3">
                <label htmlFor="centroFormacion" className="form-label">
                  Centros de estudio
                </label>
                {userOU.idCedesca > 0 ? (
                  <Input type="text" name="centroFormacion" placeholder={userOU.centro} disabled />
                ) : (
                  <Select
                    options={formationList}
                    closeMenuOnSelect={false}
                    value={formationCenter}
                    isMulti
                    onChange={handleFormationCenter}
                    disabled={isTableNotLoading ? '' : 'disabled'}
                    components={{ ClearIndicator }}
                    placeholder="Seleccionar..."
                  />
                )}
              </Col>
              <Col sm="2" className="mt-1 mt-md-0">
                {searchContext !== '' && searchContext.newSearch === false ? (
                  <>
                    <label htmlFor="dni" className="form-label">
                      Móvil
                    </label>
                    <Input
                      type="text"
                      name="movil"
                      id="movil"
                      placeholder="Buscar por móvil"
                      onChange={handlePhoneOnChange}
                      onInput={handlePhoneOnChange}
                      className={phone.error !== '' ? 'border border-danger error' : ''}
                      defaultValue={phone.data}
                      disabled={isTableNotLoading ? '' : 'disabled'}
                    />
                  </>
                ) : (
                  <>
                    <label htmlFor="dni" className="form-label">
                      Móvil
                    </label>
                    <Input
                      type="text"
                      name="movil"
                      id="movil"
                      placeholder="Buscar por móvil"
                      onChange={handlePhoneOnChange}
                      onInput={handlePhoneOnChange}
                      className={phone.error !== '' ? 'border border-danger error' : ''}
                      disabled={isTableNotLoading ? '' : 'disabled'}
                    />
                  </>
                )}
                {phone.error === 'format' && (
                  <span className="text-danger tiny">Formato incorrecto</span>
                )}
              </Col>
              <Col sm="3">
                <label htmlFor="deudores" className="form-label">
                  Deudores
                </label>
                <Input
                  type="checkbox"
                  id="deudores"
                  name="deudores"
                  className="ms-4"
                  onChange={handleDeudor}
                />
              </Col>
            </Row>

            <Row
              className="mt-auto mt-md-2 d-flex flex-column flex-md-row"
              style={{ alignItems: 'flex-end' }}
            >
              <Col sm="1" className="mt-1">
                <Button
                  type="submit"
                  className={!isFormSubmittable || !isTableNotLoading ? 'mt-0 disabled' : 'mt-0'}
                >
                  {!isTableNotLoading ? (
                    <>
                      <Spinner color="primary" style={{ height: '15px', width: '15px' }} />{' '}
                    </>
                  ) : (
                    'Buscar'
                  )}
                </Button>
              </Col>
              <Col sm="1" className="mt-1">
                <Button
                  type="reset"
                  color="warning"
                  onClick={handleReset}
                  className={!isTableNotLoading ? 'disabled' : ''}
                >
                  Reset
                </Button>
              </Col>
              <Col sm="1">
                <Button
                  color="success"
                  type="button"
                  className={!isTableNotLoading ? 'disabled' : ''}
                  onClick={handleOnClick}
                >
                  Añadir
                </Button>
              </Col>
            </Row>
          </Form>

          {data === null ? (
            ''
          ) : (
            <ReactTable
              {...translations}
              columns={[
                {
                  Header: 'ID',
                  accessor: 'id',
                },
                {
                  Header: 'Empresa',
                  accessor: 'empresaId',
                },
                {
                  Header: 'Centro Estudio',
                  accessor: 'centroEstudio',
                },
                {
                  Header: 'Nombre',
                  accessor: 'nombre',
                },
                {
                  Header: 'Apellidos',
                  accessor: 'apellidos',
                },
                {
                  Header: 'Email',
                  accessor: 'email',
                },
                {
                  Header: 'DNI',
                  accessor: 'dni',
                },
                {
                  Header: 'Móvil',
                  accessor: 'phone',
                },
                {
                  Header: 'Acciones',
                  accessor: 'actions',
                  sortable: false,
                  filterable: false,
                },
              ]}
              data={data}
              defaultPageSize={10}
              showPaginationBottom
              className="-striped -highlight mt-5 text-center"
            />
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default FormularioUsuarios;
