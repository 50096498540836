/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import React, { Suspense, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useRoutes } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Themeroutes from './routes/Router';
import Loader from './layouts/loader/Loader';
import Login from './views/login/Login';
import FullLayout from './layouts/FullLayout';
import { AuthContext } from './context/authContext';
import 'react-toastify/dist/ReactToastify.css';

const ToastWrapperMessage = ({ url = '', errorMessage = '' }) => {
  return (
    <div className="flex flex-col gap-2">
      <div style={{ fontSize: '12px', fontWeight: 'bold' }}>{url}</div>
      <div>{errorMessage}</div>
    </div>
  );
};

const App = () => {
  const { logoutGoogle, loadingUserOU, loadingUser } = useContext(AuthContext);
  const routing = useRoutes(Themeroutes);
  const userSession = JSON.parse(localStorage.getItem('user'));
  const [isVerified, setIsVerified] = useState(1);
  const [check, setCheck] = useState(0);

  let render = null;

  const handleCatchError = (event) => {
    const errorMessage = event.detail.messageError || 'Algo ha salido mal';
    const url = event.detail.url || '';

    toast.error(<ToastWrapperMessage url={url} errorMessage={errorMessage} />, {
      position: 'bottom-right',
      autoClose: 3000,
    });
  };

  useEffect(() => {
    document.addEventListener('generalerror', handleCatchError);

    return () => {
      document.removeEventListener('generalerror', handleCatchError);
    };
  }, []);

  const onLoad = (verified) => {
    // Guardamos la URL a acceder en caso de requerir un login antes
    localStorage.setItem('lastPath', window.location.pathname);

    if (localStorage.getItem('user') === null) {
      if (sessionStorage.getItem('apiError') !== null) {
        render = <Login sesionExpired="true" />;
        sessionStorage.removeItem('apiError');
      } else if (check === 0) {
        render = <Login />;
      } else if (check === 1) {
        render = <Login sesionExpired="true" />;
      }
    } else if (userSession !== null) {
      if (verified === 0) {
        logoutGoogle();
        render = <Login sesionExpired="true" />;
        setIsVerified(1);
        setCheck(1);
      } else {
        render = <FullLayout>{routing}</FullLayout>;
      }
    }

    return render;
  };

  return (
    //<>{routing}</>
    <Suspense fallback={<Loader />}>
      <div className="ltr" dir="ltr">
        {loadingUserOU || loadingUser ? (
          <div
            style={{
              display: 'flex',
              height: '100vh',
              width: '100vw',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Loader sizeScale={2} />
          </div>
        ) : (
          onLoad(isVerified)
        )}
      </div>
      <ToastContainer />
    </Suspense>
  );
};

ToastWrapperMessage.propTypes = {
  url: PropTypes.string,
  errorMessage: PropTypes.string,
};

export default App;
