import { Row, Col, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import ComponentCard from '../../../layouts/containers/ComponentCard';
import HeadquarterInput from './HeadquarterInput';
import cedesca from '../../../assets/images/logos/cedesca.jfif';
import cntec from '../../../assets/images/logos/cntec.jfif';

const ListadoEmpresas = (props) => {
  const onChangeRadioValue = (e) => {
    props.setEmpresaId(e.target.value);
  };

  return (
    <Col md="12">
      <ComponentCard title="Seleccionar empresa" className="clearfix">
        <Row className="mb-5">
          <Col md="6 text-center">
            <HeadquarterInput
              headquarter="CEDESCA"
              imgUrl={cedesca}
              onChange={onChangeRadioValue}
            />
          </Col>
          <Col md="6 text-center">
            <HeadquarterInput headquarter="CNTEC" imgUrl={cntec} onChange={onChangeRadioValue} />
          </Col>
        </Row>
        {props.beginning !== undefined ? (
          <Button className="float-left" onClick={props.beginning}>
            Volver fichar alumno
          </Button>
        ) : (
          ''
        )}

        <Button className="float-end" onClick={props.next}>
          Siguiente
        </Button>
      </ComponentCard>
    </Col>
  );
};

ListadoEmpresas.propTypes = {
  setEmpresaId: PropTypes.any,
  next: PropTypes.any,
  beginning: PropTypes.any,
};

export default ListadoEmpresas;
