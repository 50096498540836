import { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import ReactTable from 'react-table-v6';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import ComponentCard from '../../layouts/containers/ComponentCard';
import gruposService from '../../services/http/gruposService';
import ModalClickable from '../../layouts/modals/ModalClickable';
import Loader from '../../layouts/loader/Loader';

const MostrarGrupos = ({ empresaId, ret }) => {
  const [grupoDataa, setGrupoDataa] = useState([]);
  const [isSaved, setIsSaved] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGrupoData = async () => {
      const grupoData = await gruposService.consultarGrupos({
        headquarter: empresaId,
      });

      const handleClonar = async (grupo) => {
        try {
          await gruposService.clonarGrupo({
            headquarter: empresaId,
            data: grupo,
          });

          // Mostrar modal y actualizar la tabla
          setIsSaved(true);
          await fetchGrupoData(); // Actualiza la tabla después de clonar
        } catch (error) {
          console.error('Error al clonar el grupo:', error);
        }
      };
      // Añadir la columna "actions" con un botón a cada grupo
      const grupoDataWithActions = grupoData.map((grupo) => ({
        ...grupo,
        actions: (
          <Button color="primary" size="sm" onClick={() => handleClonar(grupo)}>
            Clonar
          </Button>
        ),
      }));

      setGrupoDataa(grupoDataWithActions);
    };
    fetchGrupoData();
  }, [isSaved]);

  const handleRowDoubleClick = (row) => {
    const grupoId = row.original.codigo; // Accede al valor de la celda "codigo"
    navigate(`/grupos/detalles/${empresaId}/${grupoId}`); // Navega a la ruta deseada
  };

  const translations = {
    previousText: '<',
    nextText: '>',
    loadingText: 'Cargando..',
    ofText: 'de',
    rowsText: 'líneas',
    noDataText: 'Datos no encontrados',
    pageText: 'Página',
  };

  return (
    <>
      {isSaved && <ModalClickable header="Grupo clonado correctamente." function={setIsSaved} />}
      <ComponentCard title="Listado de grupos" hasButton={false}>
        {grupoDataa.length > 0 ? (
          <ReactTable
            {...translations}
            columns={[
              {
                Header: 'ID',
                accessor: 'codigo',
              },
              {
                Header: 'Grupo',
                accessor: 'Grupo',
              },
              {
                Header: 'Horas',
                accessor: 'horas',
              },
              {
                Header: 'Area academica',
                accessor: 'areaAcademica',
              },
              {
                Header: 'Ley educativa',
                accessor: 'leyeducativa',
              },
              {
                Header: 'Máximo alumnos',
                accessor: 'maxAlumnos',
              },
              {
                Header: 'Disponible',
                accessor: 'Disponible',
              },
              {
                Header: 'Altas',
                accessor: 'altas',
              },
              {
                Header: 'Bajas',
                accessor: 'bajas',
              },
              {
                Header: 'Estado',
                accessor: 'Estado',
              },
              {
                Header: 'Acciones',
                accessor: 'actions',
                sortable: false,
                filterable: false,
              },
            ]}
            data={grupoDataa}
            defaultPageSize={10}
            showPaginationBottom
            className="-striped -highlight mt-5 text-center"
            showPageSizeOptions={false} // Deshabilita las opciones de cambio de tamaño
            getTrProps={(state, rowInfo) => ({
              onDoubleClick: () => handleRowDoubleClick(rowInfo),
            })}
          />
        ) : (
          <Loader />
        )}
        <Button className="float-left mt-4" onClick={ret}>
          Atrás
        </Button>
      </ComponentCard>
    </>
  );
};

MostrarGrupos.propTypes = {
  empresaId: PropTypes.any,
  ret: PropTypes.any,
};
export default MostrarGrupos;
