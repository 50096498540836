import HttpService from './httpService';

class GruposService extends HttpService {
  constructor(baseUrl, generalCatch) {
    super(baseUrl, generalCatch);
  }

  async consultarGrupos({ headquarter }) {
    return this.get('consultarGrupos', { params: { headquarter } });
  }

  async consultarDetallesGrupo({ headquarter, id }) {
    return this.get('consultarDetallesGrupo', { params: { headquarter, id } });
  }

  async consultarGruposAnos({ headquarter }) {
    return this.get('consultarGruposAnos', { params: { headquarter } });
  }

  async consultarGruposAreaAcademica({ headquarter }) {
    return this.get('consultarGruposAreasAcademicas', { params: { headquarter } });
  }

  async consultarGruposEstado({ headquarter }) {
    return this.get('consultarGruposEstados', { params: { headquarter } });
  }

  async consultarActividadesGrupo({ headquarter, id }) {
    return this.get('consultarActividadesGrupo', { params: { headquarter, id } });
  }

  async consultarProfesores({ headquarter }) {
    return this.get('consultarProfesores', { params: { headquarter } });
  }

  async guardarDatosGrupos(data) {
    return this.post('guardarDatosGrupos', data);
  }

  async guardarDatosProfesores(data) {
    return this.post('guardarDatosProfesores', data);
  }

  async clonarGrupo(data) {
    return this.post('clonarGrupo', data);
  }
}

const gruposService = new GruposService(process.env.REACT_APP_NODE_API);
export default gruposService;
