import React from 'react';
import './loader.scss';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';

const Loader = ({ sizeScale = 1 }) => (
  <div
    style={{
      height: '100px',
      width: '100%',
      display: 'flex',
      position: 'sticky',
      textAlign: 'center',
    }}
  >
    <div style={{ transform: `scale(${sizeScale})` }} className="loading">
      <Spinner color="primary" />
    </div>
  </div>
);

Loader.propTypes = {
  sizeScale: PropTypes.number,
};

export default Loader;
