import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Badge, Button, Input } from 'reactstrap';
import * as Icon from 'react-feather';
import InputModulos from './InputModulos';
import ModalConvocatorias from '../../../layouts/modals/ModalConvocatorias';
import ModalOptions from '../../../layouts/modals/ModalOptions';
import { AuthContext } from '../../../context/authContext';
import matriculacionApiService from '../../../services/http/matriculacionApiService';

const Actividad = ({
  idMatricula,
  obj,
  activityStateList,
  convoStateList,
  hoursCounter,
  setHoursCounter,
  total,
  setTotal,
  modulos,
  setModulos,
  estado,
  dataApi,
  act,
  setActivity,
}) => {
  const localEnrollment = JSON.parse(localStorage.getItem('enrollment'));
  const noMatriculado = obj.matriculal_id === null;
  const { userOU } = useContext(AuthContext);
  const { idCedesca, idCntec } = userOU;
  const [dataChanged, setDataChanged] = useState(false);
  console.log(dataChanged);
  const [modalConvoAbierta, setModalConvoAbierta] = useState(false);
  const [selectedState, setSelectedState] = useState(obj.idEstado);
  const [convoList, setConvoList] = useState([]);
  const [convoVisible, setConvoVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [raw2, setRaw2] = useState('');

  // Peticiones a la API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataConvocatorias = await matriculacionApiService.obtenerConvocatorias({
          headquarter: localEnrollment.headquarter,
          idMatricula,
          idActividad: obj.codigo,
        });
        setConvoList(dataConvocatorias);
      } catch (error) {
        setConvoList([]);
      }
    };

    fetchData();
  }, []);

  const handleTogleConvo = () => {
    if (obj.convocatorias > 0) {
      if (idCedesca <= 0 && idCntec <= 0) {
        setConvoVisible(!convoVisible);
      }
    }
  };

  const handleActivityState = (e) => {
    if (parseInt(e.target.value, 10) === obj.idEstado) {
      setDataChanged(false);
    } else {
      setDataChanged(true);

      // Obtenemos el ID del estado
      setSelectedState(parseInt(e.target.value, 10));
      const activityState = act[obj.modulo].filter((item) => item.codigo === obj.codigo);
      const filteredActivityState = activityState[0];
      filteredActivityState.idEstado = parseInt(e.target.value, 10);
      setActivity(act);
    }
  };

  const handleAddConvo = () => {
    setModalConvoAbierta(true);
  };
  const handleDelete = (id) => {
    setRaw2({
      idConvocatoria: id,
      headquarter: localEnrollment.headquarter,
      numMatricula: idMatricula,
      descripcion: obj.descripcion,
      nombre: obj.nombre,
    });

    setShowModal(true);
  };

  return (
    <>
      {showModal && (
        <ModalOptions
          header="¿Desea eliminar la convocatoria?"
          url={`${process.env.REACT_APP_NODE_API}eliminarConvocatoria`}
          raw={raw2}
          load={setShowModal}
          text1="Cancelar"
          color1="danger"
          text2="Aceptar"
          color2="primary"
        />
      )}
      <ModalConvocatorias
        state={modalConvoAbierta}
        setState={setModalConvoAbierta}
        convoStateList={convoStateList}
        idActividad={obj.matriculal_id}
        idGrupo={obj.CodigoGrupo}
        numMatricula={idMatricula}
      />
      <tr>
        <td
          style={{ width: '20%' }}
          className={parseInt(obj.convocatorias, 10) >= 5 || obj.Nota === 1 ? 'text-black-50' : ''}
          id={obj.codigo}
        >
          <InputModulos
            hoursCounter={hoursCounter}
            setHoursCounter={setHoursCounter}
            total={total}
            setTotal={setTotal}
            obj={obj}
            modulos={modulos}
            setModulos={setModulos}
            estado={estado}
            dataApi={dataApi}
          />{' '}
          {obj.codigo}
          {obj.Nota === 1 ? ' - Completado' : ''}
          {parseInt(obj.convocatorias, 10) >= 5 ? ' - Superado el máximo de convocatorias' : ''}
        </td>
        <td
          style={{ width: '40%' }}
          className={parseInt(obj.convocatorias, 10) >= 5 || obj.Nota === 1 ? 'text-black-50' : ''}
        >
          {obj.descripcion}
        </td>
        <td
          style={{ width: '10%' }}
          className={parseInt(obj.convocatorias, 10) >= 5 || obj.Nota === 1 ? 'text-black-50' : ''}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {(idCedesca > 0 && idCntec > 0) || userOU.centro === 'ORIENTACIÓN' ? (
              <Badge
                color="primary"
                pill
                style={{
                  cursor:
                    obj.convocatorias > 0 && idCedesca <= 0 && idCntec <= 0 ? 'pointer' : 'cursor',
                  marginLeft: '40%',
                  display: 'inline-flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={handleTogleConvo}
              >
                {obj.convocatorias}
              </Badge>
            ) : (
              <Badge
                color="primary"
                pill
                style={{
                  cursor:
                    obj.convocatorias > 0 && idCedesca <= 0 && idCntec <= 0 ? 'pointer' : 'cursor',
                  marginRight: '8px',
                  display: 'inline-flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={handleTogleConvo}
              >
                {obj.convocatorias}
              </Badge>
            )}

            {(idCedesca > 0 && idCntec > 0) || userOU.centro === 'ORIENTACIÓN' ? (
              ''
            ) : (
              <Button size="sm" onClick={handleAddConvo} disabled={noMatriculado}>
                <Icon.Plus />
              </Button>
            )}
          </div>
        </td>
        <td
          style={{ width: '5%', verticalAlign: 'middle' }}
          className={parseInt(obj.convocatorias, 10) >= 5 || obj.Nota === 1 ? 'text-black-50' : ''}
        >
          {obj.horas}
        </td>
        <td
          style={{ width: '7%', verticalAlign: 'middle' }}
          className={parseInt(obj.convocatorias, 10) >= 5 || obj.Nota === 1 ? 'text-black-50' : ''}
        >
          {obj.precio}€
        </td>
        <td
          style={{ width: '18%' }}
          className={parseInt(obj.convocatorias, 10) >= 5 || obj.Nota === 1 ? 'text-black-50' : ''}
        >
          <div style={{ display: 'flex', gap: '8px' }}>
            <Input
              size="sm"
              type="select"
              onChange={handleActivityState}
              disabled={(idCedesca > 0 && idCntec > 0) || userOU.centro === 'ORIENTACIÓN'}
              value={selectedState}
            >
              {activityStateList.map((activity) => (
                <option key={activity.Id} value={activity.Id}>
                  {activity.Valor}
                </option>
              ))}
            </Input>
          </div>
        </td>
      </tr>
      {convoVisible && (
        <tr>
          <td colSpan="6">
            <Alert color="primary">
              <table style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th>Descripción</th>
                    <th>Nota</th>
                    <th>Estado</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {convoList.map((c) => (
                    <tr>
                      <td>{c.descripcion}</td>
                      <td>{c.nota}</td>
                      <td>{c.Estado}</td>
                      <td>
                        <Button
                          color="link"
                          onClick={() => handleDelete(c.id)}
                          disabled={
                            noMatriculado ||
                            (idCedesca > 0 && idCntec > 0) ||
                            userOU.centro === 'ORIENTACIÓN'
                          }
                        >
                          <Icon.Trash />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Alert>
          </td>
        </tr>
      )}
    </>
  );
};

Actividad.propTypes = {
  idMatricula: PropTypes.any,
  obj: PropTypes.any,
  activityStateList: PropTypes.any,
  convoStateList: PropTypes.any,
  hoursCounter: PropTypes.any,
  setHoursCounter: PropTypes.any,
  total: PropTypes.any,
  setTotal: PropTypes.any,
  modulos: PropTypes.any,
  setModulos: PropTypes.any,
  estado: PropTypes.any,
  dataApi: PropTypes.any,
  act: PropTypes.any,
  setActivity: PropTypes.any,
};

export default Actividad;
