import { Col } from 'reactstrap';
import ComponentCard from '../../layouts/containers/ComponentCard';
import PanelDeControl from '../../components/ajustes/PanelDeControl';

const Actas = () => {
  return (
    <>
      <Col md="12">
        <ComponentCard title="Administrar aplicación" hasButton={false}>
          <PanelDeControl />
        </ComponentCard>
      </Col>
    </>
  );
};
export default Actas;
