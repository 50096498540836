import { useState, useEffect } from 'react';
import ReactTable from 'react-table-v6';
import Loader from '../../layouts/loader/Loader';
import otrosApiService from '../../services/http/otrosApiService';

const TablaLogs = () => {
  const translations = {
    previousText: '<',
    nextText: '>',
    loadingText: 'Cargando..',
    ofText: 'de',
    rowsText: 'líneas',
    noDataText: 'Datos no encontrados',
    pageText: 'Página',
  };
  const [data, setData] = useState('');

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const dataLogs = await otrosApiService.mostrarLogs();
        setData(dataLogs);
      } catch (error) {
        setData('');
      }
    };

    fetchLogs();
  }, []);

  return (
    <>
      {data.length === 0 ? (
        <Loader />
      ) : (
        <ReactTable
          {...translations}
          columns={[
            {
              Header: 'ID',
              accessor: 'id',
              sortable: false,
              filterable: false,
            },
            {
              Header: 'requestDate',
              accessor: 'requestDate',
            },
            {
              Header: 'remoteAddress',
              accessor: 'remoteAddress',
            },
            {
              Header: 'userEmail',
              accessor: 'userEmail',
            },
            {
              Header: 'empresaId',
              accessor: 'empresaId',
            },
            {
              Header: 'clientId',
              accessor: 'clientId',
            },
            {
              Header: 'website',
              accessor: 'website',
            },
            {
              Header: 'Action',
              accessor: 'action',
            },
            {
              Header: 'Request Params',
              accessor: 'requestParams',
            },
            {
              Header: 'Response Code',
              accessor: 'responseCode',
            },
            {
              Header: 'Response Status',
              accessor: 'responseStatus',
            },
            {
              Header: 'Response Date',
              accessor: 'responseDate',
            },
            {
              Header: 'Elapsed Time',
              accessor: 'elapsedTime',
            },
            {
              Header: 'Request Headers',
              accessor: 'requestHeaders',
            },
            {
              Header: 'Request Body',
              accessor: 'requestBody',
            },
            {
              Header: 'Response Headers',
              accessor: 'responseHeaders',
            },
            {
              Header: 'Response Body',
              accessor: 'responseBody',
            },
          ]}
          data={data}
          defaultPageSize={10}
          showPaginationBottom
          className="-striped -highlight mt-5 text-center"
        />
      )}
    </>
  );
};

export default TablaLogs;
